import React, { useState, useCallback } from 'react';
import { RadioFilterComponent } from '../../filter/options/RadioFilterOption';
import {
  statusFromApi,
  statusToApi,
  statusLabel,
  applicationStatuses,
} from '../../../utilities/ApplicationStatus';

const applicationStatusOptions = Object.keys(applicationStatuses).map(
  (status: any) => {
    return { label: statusLabel(status), value: status };
  }
);

type Props = {
  application: ApplicationEntity;
  patchApplications: Function;
  onComplete?: Function;
};

const CandidateStatusForm = ({
  application,
  patchApplications,
  onComplete,
}: Props) => {
  const applicationStatus = statusFromApi(application.status);
  const [status, setStatus] = useState(applicationStatus);

  const onStatusChange = useCallback(
    (name: string, value?: any) => {
      if (value) {
        setStatus(value);
      }
    },
    [setStatus]
  );

  const onApply = useCallback(() => {
    const data = {
      [application.id]: {
        status: statusToApi(status),
        position: null, // reset the position
      },
    };
    patchApplications(data);
    if (onComplete) onComplete();
  }, [application.id, onComplete, patchApplications, status]);

  return (
    <div className="relative flex flex-col justify-between p-8">
      <RadioFilterComponent
        multiple={false}
        allowClear={false}
        options={applicationStatusOptions}
        onChange={onStatusChange}
        sectionTitle="Move candidate to:"
        name="status"
        value={status}
      />

      <div className="filter-apply">
        <button
          type="submit"
          onClick={onApply}
          className="btn btn--primary btn--full"
        >
          Apply
        </button>
      </div>
    </div>
  );
};

export default CandidateStatusForm;
