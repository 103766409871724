import React from 'react';
import useAnalytics from '../../hooks/useAnalytics';
import useNavigate from '../../hooks/useNavigate';
import Button from '../core/Button';

const ViewApplicantsButton = ({
  workId,
  trackingEventName,
  applicantCount,
  isExternalJob,
  width = 'md:w-auto',
}: {
  workId: string;
  trackingEventName: string;
  applicantCount: number;
  isExternalJob: boolean;
  width?: string;
}) => {
  const navigate = useNavigate();
  const { track } = useAnalytics();

  if (isExternalJob) {
    return <div />;
  }
  return (
    <div className="flex flex-col text-center md:ml-auto whitespace-nowrap">
      <Button
        className={`btn btn--sm btn--primary btn--outline ${width}`}
        onClick={async () => {
          track(trackingEventName);
          navigate(`/work/${workId}/candidates/list`);
        }}
      >
        {`${`${applicantCount}`} ${
          applicantCount === 1 ? 'Applicant' : 'Applicants'
        }`}
      </Button>
    </div>
  );
};

export default ViewApplicantsButton;
