import React from 'react';
import { LoadingRing } from '../core/Loading';

type CardEmptyProps = {
  isLoading: boolean;
  onRefresh?: Function;
  onResetDiscarded?: Function;
};
const CardEmpty = ({
  isLoading,
  onRefresh,
  onResetDiscarded,
}: CardEmptyProps) => (
  <div className="generosity-card">
    {isLoading ? (
      <div className="flex flex-col items-center m-auto">
        <LoadingRing isActive type="secondary" size="2xl" />
      </div>
    ) : (
      <div className="flex flex-col justify-between h-full">
        <div>
          <h5 className="text-teal-1100">You’ve reached the end</h5>
          <p className="my-8 flex-1">
            You’ve reviewed all available channels. You can refresh to see new
            channel updates or restart to return to the beginning of the
            channels list.
          </p>
        </div>
        <div>
          {onRefresh && (
            <button
              type="button"
              className="btn btn--sm btn--full btn--primary mb-4"
              onClick={() => onRefresh()}
            >
              Refresh
            </button>
          )}
          {onResetDiscarded && (
            <button
              type="button"
              className="btn btn--sm btn--full btn--primary btn--outline"
              onClick={() => onResetDiscarded()}
            >
              Restart
            </button>
          )}
        </div>
      </div>
    )}
  </div>
);

export default CardEmpty;
