import useChatChannelMembers from './useChatChannelMembers';
import useCurrentUser from '../../../hooks/useCurrentUser';
import { select } from '../instances/UserInstances';

/**
 * useChatOtherMember
 *
 * Returns the other chat member of a given direct chat channel
 */
const useChatOtherMember = (channelSid: string, identity?: string) => {
  const { user: currentUser } = useCurrentUser();
  const { channelMembers } = useChatChannelMembers(channelSid);

  let member = null;

  if (!currentUser || !channelMembers) return { member };

  if (identity) member = channelMembers[identity];
  else {
    // Determine which is not the logged in user
    member = Object.values(channelMembers).find(m => {
      const user = select(m.identity);
      return m.identity !== currentUser.id && !user?.attributes?.legacy;
    });
  }

  return { member };
};

export default useChatOtherMember;
