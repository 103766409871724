import React from 'react';
import OnboardingLayout from '../onboarding/OnboardingLayout';
import { Link } from '../core/router';
import useCurrentMembership from '../../hooks/useCurrentMembership';
import useClientBrandingStore from '../../hooks/stores/useClientBrandingStore';

const WelcomeCompany = () => {
  const { membership } = useCurrentMembership();
  const brandColor = useClientBrandingStore(
    state => state.clientBranding?.brandColor
  );

  return (
    <OnboardingLayout organization="communoAgency" theme="dark">
      <>
        <div className="content text-white text-center pt-24">
          <h1 className="h2">Welcome to Communo, {membership?.name}!</h1>
          <p>That’s it, you’re in.</p>
          <p>
            But adding more information to your profile greatly increases your
            chances of being noticed.
          </p>
        </div>
        <Link
          to={`/members/membership/${membership?.id}#/edit-page/basic`}
          className="btn btn--sm btn--primary btn--rev w-full mt-8"
          style={{
            color: brandColor ?? 'var(--com-color-red-900)',
          }}
        >
          Complete profile
        </Link>
        <Link
          to="/feed"
          className="btn btn--sm btn--primary btn--rev btn--outline w-full mt-4"
        >
          Continue
        </Link>
      </>
    </OnboardingLayout>
  );
};
export default WelcomeCompany;
