import { IonAlert } from '@ionic/react';
import { useQueryClient } from '@tanstack/react-query';
import React, { useState } from 'react';
import * as Sentry from '@sentry/react';
import { ChannelInteraction } from '../../@types/chat.d';
import { addError, addSuccess } from '../../services/Messaging';
import Avatar from '../avatar/Avatar';
import useChatChannelInstance from '../chat/hooks/useChatChannelInstance';
import Modal from '../core/modal/Modal';
import favorApi from './Api';
import Share from './Share';
import ChannelParticipantCount from './ChannelParticipantCount';

type ChannelCardProps = {
  currentTab: 'all' | 'mine';
  details: ChannelInteraction;
  onViewChannel: () => void;
};

/**
 * The Card component representing each channel
 *
 * @param param0.details - represents the information object to be presented in the card
 * @param param0.onViewChannel - callback that triggers viewing a channel
 * @param param0.onLeaveChannel - callback that triggers leaving a channel
 */
const ChannelCard = ({
  currentTab,
  details,
  onViewChannel,
}: ChannelCardProps): JSX.Element => {
  const { channelInstance } = useChatChannelInstance(details.conversationId);

  const avatarUrl = channelInstance
    ? channelInstance.attributes.avatarURL
    : 'https://res.cloudinary.com/communo/image/upload/c_crop,h_400,w_400/v1637287002/appcues/channels/cropped/channels.jpg';

  const [isSharing, setIsSharing] = useState<boolean>(false);
  const [isShowingLeavePrompt, setIsShowingLeavePrompt] = useState<boolean>(
    false
  );
  const queryClient = useQueryClient();

  const openShareModal = () => {
    setIsSharing(true);
  };

  const closeShareModal = () => {
    setIsSharing(false);
  };

  const handleLeaveChannel = async () => {
    try {
      await favorApi.interact(details.id, 'passed');
      addSuccess('Successfully left channel');
      queryClient.invalidateQueries(['channels']);
    } catch (error) {
      Sentry.captureException(error);
      addError('An error occurred while leaving the channel');
    }
  };

  return (
    <div className="bg-white justify-between w-96 h-96 flex flex-col gap-2 rounded-md p-8 m-2.5">
      {isSharing && (
        <Modal
          isOpen={isSharing}
          title={details.title}
          onClose={closeShareModal}
          paddingX="8"
          paddingY="8"
          width="2xl"
        >
          <Share channel={details} onComplete={closeShareModal} />
        </Modal>
      )}
      <div className="flex flex-row gap-4 items-center">
        <Avatar
          className="generosity-card__avatar"
          avatarName={`${details.user.firstName} ${details.user.lastName}`}
          avatarUrl={avatarUrl}
          size="lg"
          draggable={false}
        />
        <div className="col-start-3 col-span-6">
          <p className="font-bold">{details.title}</p>
        </div>
      </div>
      <div className="flex flex-col gap-1">
        <div className="text-grey-500 text-xs font-semibold">
          Created by {details.user.firstName}
        </div>
        <div className="col-start-1 lg:col-start-4 col-end-11 font-medium text-left flex flex-col items-start gap-1 min-h-full">
          <p className="text-sm w-full clamp-multi">{details.description}</p>
        </div>
      </div>
      <ChannelParticipantCount participantCount={details.offersCount} />
      <div className="flex flex-col space-y-2 self-end w-full">
        <button
          className="btn btn--full btn--sm btn--tertiary"
          type="button"
          onClick={onViewChannel}
        >
          {`${currentTab === 'all' ? 'Join' : 'View'} Channel`}
        </button>

        <div className="flex flex-row justify-between gap-2 items-end">
          <button
            className="btn btn--full btn--sm btn--tirtiary btn--outline"
            type="button"
            onClick={openShareModal}
            aria-labelledby="share-channel-label"
          >
            Share
          </button>
          {currentTab === 'mine' && (
            <button
              className="btn btn--full btn--sm btn--tirtiary btn--outline"
              type="button"
              onClick={() => setIsShowingLeavePrompt(true)}
            >
              Leave
            </button>
          )}
        </div>
      </div>

      <IonAlert
        isOpen={isShowingLeavePrompt}
        onDidDismiss={() => {
          setIsShowingLeavePrompt(false);
        }}
        header="Leave channel?"
        message="Please confirm you want to leave this chat."
        buttons={[
          {
            text: 'Cancel',
            role: 'cancel',
            handler: () => {},
          },
          {
            text: 'Leave',
            handler: handleLeaveChannel,
          },
        ]}
      />
    </div>
  );
};

export default ChannelCard;
