import React from 'react';
import { Link } from '../components/core/router';
import Page from '../components/core/Page';
import useAnalytics from '../hooks/useAnalytics';
import ContentHeader from '../components/core/ContentHeader';
import SignupLayout from '../components/user/signup/SignupLayout';

export default () => {
  useAnalytics('Invite Pending');

  return (
    <Page
      layout="basic"
      className="page-bg--white"
      pageTitle="Invite Pending"
      mobileHeader={() => null}
      darkStatusBar
      hideHeader
    >
      <SignupLayout headerImg="https://res.cloudinary.com/communo/image/upload/v1589576439/onboarding/onboarding-login-email.jpg">
        <>
          <ContentHeader />
          <div className="text-center">
            <h2 className="mb-4">You&apos;ve been invited.</h2>
            <div className="text-lg font-light mb-4">
              It looks like you&apos;ve already been invited to join a team.
            </div>
            <div className="text-lg font-light mb-4">
              Check your email for the confirmation link.
            </div>
            <Link to="/login" className="text-link font-medium">
              Try another email
            </Link>
          </div>
        </>
      </SignupLayout>
    </Page>
  );
};
