import { Api, BaseAPI } from '../../Api';

const customApi = {
  newPage: async (data: { slug: string }) =>
    Api.post('/scheduler/newPage', data),
  fetchSchedulePage: async (data: { slug: string }) =>
    Api.post('/scheduler/findSchedule', data),
};

const schedulerApi = { ...BaseAPI('scheduler'), ...customApi };

export default schedulerApi;
