import environment from '../environment';

export const appUrl = (path: string) => {
  return `${environment.appUrl}${path}`;
};

export const ensureHttp = (url: string) => {
  if (!/^(?:f|ht)tps?:\/\//.test(url)) {
    return `http://${url}`;
  }

  return url;
};

export const forceHttps = (url: string): string => {
  return ensureHttp(url).replace(/^http:\/\//i, 'https://');
};

export function stripTrailingSlash(str: string) {
  if (str.substr(-1) === '/') {
    return str.substr(0, str.length - 1);
  }

  return str;
}
