import React, { useContext, useEffect } from 'react';
import { useHistory } from 'react-router';
// import { useCookie } from 'react-use';
import { FREELANCER_MEMBER_PLAN_ID } from '../../../config';
import { addResponseError } from '../../../services/Messaging';
// import { SOURCE_COOKIE } from '../../cookie/SourceCookie';
import MembershipApi from '../../membership/Api';
import OnboardingLayout from '../../onboarding/OnboardingLayout';
import { StepType } from '../../stepper/Step';
import Stepper from '../../stepper/Stepper';
import UserContext from '../Context';
import LocomotusEmailStep from '../signupSteps/locomotus/LocomotusEmailStep';
import TermsStep from '../signupSteps/TermsStep';

const steps: StepType[] = [
  {
    title: 'Join the Locomotus network',
    description: 'We’ll get you set up and doing the work you want to do.',
    component: () => <LocomotusEmailStep />,
  },
  {
    title: 'House Rules',
    description: 'Making sure we get along and respect one another.',
    component: () => <TermsStep />,
  },
];

const SignupLocomotus = () => {
  const {
    authDetails,
    register: registerUser,
    refresh,
    isAuthenticated,
  } = useContext(UserContext);
  // const [sourceCookie] = useCookie(SOURCE_COOKIE);
  const { replace } = useHistory();

  useEffect(() => {
    if (isAuthenticated) {
      replace('/feed');
    }
    // eslint-disable-next-line
  }, []);

  const createMembership = () => {
    MembershipApi.create({
      planId: FREELANCER_MEMBER_PLAN_ID,
    })
      .then((response: MembershipEntity) => {
        refresh().then(() => {
          replace('/getting-to-know-you');
        });
        return response;
      })
      .catch((error: ResponseError) => {
        addResponseError(error);
      });
  };

  const onFinish = () => {
    registerUser({ email: authDetails.email, source: 'locomotus' })
      .then(() => {
        createMembership();
      })
      .catch((error: ResponseError) => {
        addResponseError(error);
      });
  };

  return (
    <OnboardingLayout organization="locomotus">
      <Stepper
        heading="Account Setup"
        steps={steps}
        onFinish={onFinish}
        hideDivider
      />
    </OnboardingLayout>
  );
};
export default SignupLocomotus;
