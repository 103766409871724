import React from 'react';
import { addError } from '../../services/Messaging';
import { LoadingBalls } from '../core/Loading';
import CardEmpty from './CardEmpty';
import ChannelCard from './ChannelCard';
import ChannelQueries from './queries';

type JoinedChannelsCardsProps = {
  onShare?: (id: TCardId) => void;
  onView: (id: TCardId) => void;
  setJoinedFlow?: React.Dispatch<React.SetStateAction<boolean>>;
  currentTab: 'all' | 'mine';
  handleTabChange?: React.Dispatch<React.SetStateAction<'mine' | 'all'>>;
};

const JoinedChannelsCards = ({
  onView,
  currentTab,
  handleTabChange,
}: JoinedChannelsCardsProps) => {
  const joinedChannels = ChannelQueries.useGetJoinedChannels();

  if (joinedChannels.status === 'loading') {
    return <LoadingBalls isActive />;
  }

  if (joinedChannels.status === 'error') {
    addError('An error occurred while fetching user cards');
    return null;
  }

  if (joinedChannels.data.length === 0) {
    return (
      <div className="generosity__empty">
        <img
          className="mt-6 mb-4 mx-auto"
          alt=""
          width="144"
          height="144"
          src="/assets/communo/types/megaphone.svg"
        />
        <div>
          <h3 className="generosity__empty-inside h3 font-normal text-black mb-2 text-center">
            You haven’t joined any channel yet.
          </h3>
          <p className="generosity__empty-inside font-normal text-black mb-2 text-center">
            This is where you will find them when you do.
          </p>
        </div>
        <div className="text-center mb-10">
          <button
            className="btn btn--full btn--sm btn--tertiary"
            type="button"
            onClick={() => (handleTabChange ? handleTabChange('all') : null)}
          >
            Browse channels
          </button>
        </div>
      </div>
    );
  }

  return (
    <>
      {/*
        Workaround for bug: https://github.com/ionic-team/ionic/issues/18782
        IonSlides causes React to throw a "Failed to execute 'removeChild' on 'Node'" error
        when a slide is removed. Using a key on IonSlides causes it to be re-created as a new instance
      */}
      <div className="w-fit items-center">
        {joinedChannels.data.length > 0 ? (
          <div
            className={`${
              currentTab === 'mine'
                ? 'grid sm:grid-col-1 md:grid-cols-2 2xl:grid-cols-3 gap-4 justify-items-center place-content-center'
                : 'hidden'
            } `}
          >
            {joinedChannels.data.map((card, i) => (
              <ChannelCard
                currentTab={currentTab}
                key={`${card.id}-${i.toString()}`}
                details={card}
                onViewChannel={() => onView(card.id)}
              />
            ))}
          </div>
        ) : (
          <div className="card-empty">
            <CardEmpty isLoading={joinedChannels.isFetching} />
          </div>
        )}{' '}
      </div>
    </>
  );
};

export default JoinedChannelsCards;
