import { keyBy, orderBy } from 'lodash';
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useReducer,
} from 'react';
import {
  DragDropContext,
  Droppable,
  /* eslint-disable no-unused-vars */
  DroppableProvided,
  DroppableStateSnapshot,
  DropResult,
} from 'react-beautiful-dnd';
import { addResponseError } from '../../services/Messaging';
import {
  applicationStatuses,
  groupApplicationsByStatus,
  statusToApi,
} from '../../utilities/ApplicationStatus';
import UserContext from '../user/Context';
import WorkCandidatesColumn from './WorkCandidatesColumn';

type ReducerAction = {
  type: string;
  payload: any[];
};
type ReducerState = {
  [id: string]: ApplicationEntity;
};
type Props = {
  applications: KeyedApplications;
  patchApplications: Function;
};
interface BatchData {
  [key: string]: {
    status: ApplicationStatus | null;
    position: number;
  };
}

export default ({ applications, patchApplications }: Props) => {
  const { isPublic } = useContext(UserContext);
  const [state, dispatch] = useReducer(
    (reducerState: ReducerState, action: ReducerAction) => {
      if (action.type === 'MOVE_APPLICATIONS') {
        const newState = { ...reducerState };

        action.payload.forEach(delta => {
          newState[delta.id] = {
            ...newState[delta.id],
            ...delta,
          };
        });
        return newState;
      }

      if (action.type === 'SYNC_APPLICATIONS') {
        return keyBy(action.payload, 'id');
      }

      return reducerState;
    },
    applications
  );

  const applicationsByStatus: {
    [status: string]: ApplicationEntity[];
  } = useMemo(() => {
    const orderedApplications = orderBy(
      Object.values(state),
      ['position', 'score', 'updatedAt'],
      ['asc', 'desc', 'asc']
    );
    return groupApplicationsByStatus(orderedApplications);
  }, [state]);

  useEffect(() => {
    dispatch({
      type: 'SYNC_APPLICATIONS',
      payload: Object.values(applications),
    });
  }, [applications]);

  const moveApplications = useCallback(
    (deltas, previous) => {
      dispatch({
        type: 'MOVE_APPLICATIONS',
        payload: deltas,
      });

      const data: BatchData = {};
      deltas.forEach((delta: any) => {
        data[delta.id] = {
          status: statusToApi(delta.status),
          // null status (i.e. 'interested) should never have a position (it's ordered by score instead)
          position: delta.status === null ? null : delta.position,
        };
      });
      patchApplications(data).catch((err: any) => {
        addResponseError(err, 'There was an error moving the application.');
        dispatch({
          type: 'MOVE_APPLICATIONS',
          payload: previous,
        });
      });
    },
    [patchApplications]
  );

  const reorderSourceApplications = useCallback(
    (sourceApplications, result) => {
      return sourceApplications.reduce((carry: any, app: any, i: number) => {
        const changedColumns =
          result.source.droppableId !== result.destination.droppableId;

        if (app.id === result.draggableId || !changedColumns) {
          // Already added the delta for the moved application
          return carry;
        }

        if (i >= result.source.index) {
          // Moved to a new column
          carry.push({
            id: app.id,
            status: app.status,
            position: app.status === null ? null : Math.max(0, i - 1),
          });
        }

        return carry;
      }, []);
    },
    []
  );

  const reorderDestinationApplications = useCallback(
    (destinationApplications, result) => {
      return destinationApplications.reduce(
        (carry: any, app: any, i: number) => {
          // Already added the delta for the moved application
          if (app.id === result.draggableId) return carry;

          const { source, destination } = result;
          const changedColumns = source.droppableId !== destination.droppableId;
          let position = i;

          if (changedColumns) {
            // Moving BETWEEN columns is simpler: increase all indexes >= than the dropped index
            if (i >= destination.index) position += 1;
          } else {
            // Moving WITHIN a column needs a direction (+ or -), lower and upper limit
            // If increased: decrease all indexes between the lower (source) and upper limit (destination)
            // If decreased: increase all indexes between the lower (destination) and upper limit (source)
            const increased = destination.index > source.index;
            const decreased = destination.index < source.index;

            const lower = Math.min(source.index, destination.index);
            const upper = Math.max(source.index, destination.index);

            if (increased && i > lower && i <= upper) position -= 1;
            if (decreased && i >= lower && i < upper) position += 1;
          }

          // Any applications' position that does not match the index should be updated
          if (app.position !== position) {
            carry.push({
              id: app.id,
              status: app.status,
              position,
            });
          }

          return carry;
        },
        []
      );
    },
    []
  );

  const onDragEnd = useCallback(
    (result: DropResult) => {
      if (!result.destination) {
        return;
      }

      const sourceStatus: any = result.source.droppableId;
      const destinationStatus: any = result.destination.droppableId;

      let deltas = [
        {
          id: result.draggableId,
          status: statusToApi(destinationStatus),
          position: result.destination.index,
        },
      ];

      const sourceApplications = applicationsByStatus[sourceStatus];
      deltas = [
        ...deltas,
        ...reorderSourceApplications(sourceApplications, result),
      ];

      const destinationApplications = applicationsByStatus[destinationStatus];
      deltas = [
        ...deltas,
        ...reorderDestinationApplications(destinationApplications, result),
      ];

      const previous = [...sourceApplications, ...destinationApplications].map(
        app => {
          return {
            id: app.id,
            status: app.status,
            position: app.position,
          };
        }
      );

      moveApplications(deltas, previous);
    },
    [
      applicationsByStatus,
      reorderSourceApplications,
      reorderDestinationApplications,
      moveApplications,
    ]
  );

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <div
        className={`grid grid-cols-2 ${
          isPublic ? 'lg:grid-cols-2' : '2xl:grid-cols-4'
        } gap-4 w-full`}
      >
        {Object.keys(applicationStatuses).map((status: any) => {
          const applicationStatus: any =
            applicationStatuses[status as ApplicationStatus];

          return (
            applicationStatus.display &&
            (isPublic ? !applicationStatus.internalOnly : true) && (
              <Droppable key={status} droppableId={status} type="application">
                {(
                  dropProvided: DroppableProvided,
                  dropSnapshot: DroppableStateSnapshot
                ) => (
                  <WorkCandidatesColumn
                    status={status}
                    applications={applicationsByStatus[status]}
                    dropProvided={dropProvided}
                    dropSnapshot={dropSnapshot}
                  />
                )}
              </Droppable>
            )
          );
        })}
      </div>
    </DragDropContext>
  );
};
