import React from 'react';
import { HashRoute } from '../../Routes';
import MyPlanForm from '../../forms/MyPlanForm';
import NotificationSettingsForm from '../../forms/NotificationSettingsForm';
import PasswordChangeForm from '../../forms/PasswordChangeForm';
import VerificationsForm from '../../forms/VerificationsForm';
import { Link, NavLink } from '../../../core/router';
import VisibilityForm from '../../forms/VisibilityForm';
import useCurrentPlan from '../../../../hooks/useCurrentPlan';
import useCurrentUser from '../../../../hooks/useCurrentUser';
import AccountStatusForm from '../../forms/AccountStatusForm';

type Link = {
  to: string;
  label: string;
};

type Props = {
  match: {
    params: { form: 'password' | 'notifications' | 'plan' | 'verifications' };
  };
};

const Settings = ({
  match: {
    params: { form },
  },
}: Props) => {
  const plan = useCurrentPlan();
  const user = useCurrentUser();
  const isAdmin = user.membershipAccess === 'admin';

  const links = [
    {
      to: '#/settings/plan',
      label: 'Plan',
      component: MyPlanForm,
      canAccess: () => isAdmin,
    },
    {
      to: '#/settings/verifications',
      label: 'Vetting Status',
      component: VerificationsForm,
      canAccess: () => (isAdmin && plan?.isEligibleForVetting) || user.isRoot,
    },
    {
      to: '#/settings/password',
      label: 'Change Password',
      component: PasswordChangeForm,
    },
    {
      to: '#/settings/notifications',
      label: 'Notification Settings',
      component: NotificationSettingsForm,
    },
    {
      to: '#/settings/visibility',
      label: 'Visibility',
      component: VisibilityForm,
      canAccess: () => (isAdmin && plan?.canHideFromSearch) || user.isRoot,
    },
    {
      to: '#/settings/status',
      label: 'Account Status',
      component: AccountStatusForm,
    },
  ];

  const accessibleLinks = links.filter(
    link => !link.canAccess || link.canAccess()
  );

  return (
    <div className="">
      <div className="row flex">
        <div
          className={`${
            form ? 'hidden' : ''
          } md:block col w-full md:w-1/3 self-start sticky top-0`}
        >
          <ul className="nav-links">
            {accessibleLinks.map(({ to, label }: Link) => (
              <li className="nav-links__item" key={to}>
                <NavLink className="nav-links__link" to={to}>
                  {label}
                  <i className="i-caret-right md:hidden" />
                </NavLink>
              </li>
            ))}
          </ul>
        </div>

        <div className={`${form ? '' : 'hidden'} md:block col w-full md:w-2/3`}>
          {accessibleLinks.map(link => (
            <HashRoute
              key={link.to}
              hash={link.to}
              component={link.component}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default Settings;
