import { get } from 'lodash';
import React, { useMemo } from 'react';
import { DEFAULT_ONBOARDING_LOGO_URL } from '../../asset-config';
import useClientBrandingStore, {
  ClientBranding,
} from '../../hooks/stores/useClientBrandingStore';
import SplitPanels from '../core/panels/SplitPanels';

type PartialClientBranding = Omit<
  ClientBranding,
  'ownerName' | 'ownerImage'
> & {
  mobileImageHeightClass?: string;
  // containerStyle?: CSSProperties;
};

type Themes = 'light' | 'dark';

type PartialRecord<Keys extends keyof any, Value> = {
  [P in Keys]?: Value;
};

type Theme = PartialRecord<Themes, Partial<PartialClientBranding>>;

interface OrganizationAssets extends PartialClientBranding {
  theme?: Theme;
}

const communo: OrganizationAssets = {
  brandCoverPhotoURL:
    'https://res.cloudinary.com/communo/image/upload/v1611861734/onboarding/Marta-pexels-andrea-piacquadio-3769021.jpg',
  brandLogoURL: DEFAULT_ONBOARDING_LOGO_URL,
  brandColor: 'var(--com-color-white)',
};

const organizations = new Map<string, OrganizationAssets>([
  [
    'communo',
    {
      ...communo,
      theme: {
        dark: {
          brandColor: 'var(--com-color-red-900)',
        },
      },
    },
  ],
  [
    'communoAgency',
    {
      ...communo,
      brandCoverPhotoURL:
        'https://res.cloudinary.com/communo/image/upload/v1613609057/assets/meta/agency.jpg',
      theme: {
        dark: {
          brandColor: 'var(--com-color-red-900)',
        },
      },
    },
  ],
  [
    'hbcu',
    {
      brandCoverPhotoURL:
        'https://res.cloudinary.com/communo/image/upload/v1628547689/brands/beats/naomi.jpg',
      brandLogoURL:
        'https://res.cloudinary.com/communo/image/upload/v1628547689/brands/beats/logo-lockup.png',
      brandColor: 'var(--com-color-white)',
      theme: {
        dark: {
          brandCoverPhotoURL:
            'https://res.cloudinary.com/communo/image/upload/v1628547689/brands/beats/g1000.jpg',
          brandColor: 'var(--com-color-true-black)',
          mobileImageHeightClass: 'h-screen45',
        },
      },
    },
  ],
  [
    'locomotus',
    {
      brandCoverPhotoURL:
        'https://res.cloudinary.com/communo/image/upload/v1613690803/brands/locomotus/Onboarding---1440x1080.jpg',
      brandLogoURL:
        'https://res.cloudinary.com/communo/image/upload/v1613690794/brands/locomotus/Locomotus_Logo400x400.jpg',
      brandColor: 'var(--com-color-white)',
      theme: {
        dark: {
          brandCoverPhotoURL:
            'https://res.cloudinary.com/communo/image/upload/v1613690803/brands/locomotus/Onboarding2---1440x1080.jpg',
          brandColor: 'var(--com-color-true-black)',
        },
      },
    },
  ],
]);

interface Props {
  organization?: string;
  theme?: Themes;
  children: JSX.Element | JSX.Element[];
}

const OnboardingLayout = ({
  organization = 'communo',
  theme = 'light',
  children,
}: Props) => {
  const clientBrandingColor = useClientBrandingStore(
    state => state.clientBranding?.brandColor
  );
  const clientCoverPhotoURL = useClientBrandingStore(
    state => state.clientBranding?.brandCoverPhotoURL
  );
  const clientLogoURL = useClientBrandingStore(
    state => state.clientBranding?.brandLogoURL
  );

  const orgAssets = organizations.get(organization);

  const themeAssets = useMemo((): OrganizationAssets => {
    const themeOverides = get(orgAssets, `theme.${theme}`);

    if (themeOverides) {
      return {
        ...orgAssets,
        ...themeOverides,
      };
    }

    return orgAssets ?? communo;
  }, [orgAssets, theme]);

  const { brandColor, brandCoverPhotoURL, brandLogoURL } = themeAssets;

  const bgColor = useMemo(() => {
    if (theme === 'light') {
      return 'var(--com-color-white)';
    }

    return clientBrandingColor ?? brandColor ?? 'var(--com-color-red-900)';
  }, [clientBrandingColor, theme, brandColor]);

  const headerImgUrl = useMemo(() => {
    if (clientCoverPhotoURL) {
      return clientCoverPhotoURL;
    }

    return (
      brandCoverPhotoURL ??
      'https://res.cloudinary.com/communo/image/upload/v1611861734/onboarding/Marta-pexels-andrea-piacquadio-3769021.jpg'
    );
  }, [brandCoverPhotoURL, clientCoverPhotoURL]);

  const logoImgUrl = useMemo(() => {
    if (clientLogoURL) {
      return clientLogoURL;
    }

    return brandLogoURL ?? DEFAULT_ONBOARDING_LOGO_URL;
  }, [brandLogoURL, clientLogoURL]);

  return (
    <SplitPanels
      headerImg={headerImgUrl}
      logoImg={logoImgUrl}
      // backgroundClass={backgroundClass}
      // bodyClass={backgroundClass}
      mobileImageHeightClass={orgAssets?.mobileImageHeightClass}
      containerStyle={{
        backgroundColor: bgColor,
      }}
      contentContainerStyle={{
        backgroundColor: bgColor,
      }}
    >
      {children}
    </SplitPanels>
  );
};
export default OnboardingLayout;
