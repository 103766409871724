import React, { useState, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { Perk, PerkBundle, PerkCategory } from '@communo-corp/shared-types';
import { useHistory } from 'react-router';
import {
  FormActions,
  FormInput,
  FormTextarea,
  FormImage,
  FormGroup,
  FormRadio,
  FormCheckbox,
} from '../../core/form';
import { SubmitButton } from '../../core/Button';
import Membership from '../formElements/Membership';
import PerkCategories from '../formElements/PerkCategories';
import Expiry from '../formElements/Expiry';
import PerkPicker from '../formElements/PerkPicker';
import perkApi from '../Api';
import { addResponseError, addSuccess } from '../../../services/Messaging';
import Alert from '../../alert/Alert';

interface FormFields {
  name: string;
  tagline: string;
  // descriptionSummary: string;
  description: string;
  // offerSummary: string;
  // offer: string;
  logoUrl: string | null;
  squareImageUrl: string;
  horizontalHeaderImageUrl: string;
  verticalHeaderImageUrl: string;
  featuredLogoImageUrl: string;
  order: number;
  usesLightTheme: string;
  audience: string[];
  isPremium: boolean;
  isFeatured: boolean;
  isActive: boolean;
  isNew: boolean;
  expiresAt: number | null;
  membershipId: string | null;
  perkCategoryIds: string[] | null;
  perkIds: string[];
}

interface Props {
  bundle?: PerkBundle;
  isEdit?: boolean;
}

const BundleForm = ({ bundle, isEdit = false }: Props) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [showDeleteAlert, setShowDeleteAlert] = useState<boolean>(false);
  const { push } = useHistory();

  const defaultAudience = [];

  if (bundle?.visibleToAgencies) {
    defaultAudience.push('agency');
  }

  if (bundle?.visibleToFreelancers) {
    defaultAudience.push('freelancer');
  }

  const defaultMembership = useMemo(() => {
    if (!bundle || bundle.membership?.name == null) {
      return undefined;
    }

    return {
      label: bundle.membership.name,
      value: {
        membershipId: bundle.membership.id,
        name: bundle.membership.name,
        logoURL: bundle.membership.logoURL,
      },
    };
  }, [bundle]);

  const defaultCategories = useMemo(() => {
    if (bundle?.categories?.length) {
      return bundle.categories.map((category: PerkCategory) => {
        return {
          label: category.name,
          value: category.id,
        };
      });
    }

    return undefined;
  }, [bundle]);

  const defaultPerks = useMemo(() => {
    if (bundle?.perks?.length) {
      return bundle.perks.map((perk: Perk) => {
        return {
          label: perk.descriptionSummary,
          value: perk.id,
        };
      });
    }

    return undefined;
  }, [bundle]);

  const form = useForm<FormFields>({
    mode: 'onBlur',
    shouldFocusError: true,
    defaultValues: {
      name: bundle?.name || '',
      tagline: bundle?.tagline || '',
      // descriptionSummary: '',
      description: bundle?.description || '',
      // offerSummary: '',
      // offer: bundle?.offer || '',
      logoUrl: null,
      squareImageUrl: bundle?.squareImageUrl || '',
      horizontalHeaderImageUrl: bundle?.horizontalHeaderImageUrl || '',
      verticalHeaderImageUrl: bundle?.verticalHeaderImageUrl || '',
      featuredLogoImageUrl: bundle?.featuredLogoImageUrl || '',
      order: bundle?.order || 1.1,
      usesLightTheme: bundle?.usesLightTheme ? 'true' : 'false',
      audience: defaultAudience,
      isPremium: !!bundle?.isPremium,
      isFeatured: !!bundle?.isFeatured,
      isActive: !!bundle?.isActive,
      isNew: !!bundle?.isNew,
      expiresAt: bundle?.expiresAt || null,
      // membershipId: '',
      // perkCategoryIds: null,
      // perkIds: [],
    },
  });
  const { handleSubmit, register, errors, setValue, watch } = form;
  const watchMembershipId = watch(
    'membershipId',
    defaultMembership?.value.membershipId
  );
  const watchIsFeatured = watch('isFeatured', bundle?.isFeatured || false);

  const deleteHandler = () => {
    setShowDeleteAlert(true);
  };

  const onSubmit = (values: any) => {
    if (loading) return;
    setLoading(true);

    const { audience, order, expiresAt, usesLightTheme } = values;

    const data = {
      ...values,
      visibleToAgencies: audience.includes('agency'),
      visibleToFreelancers: audience.includes('freelancer'),
      order: parseFloat(order),
      expiresAt: parseInt(expiresAt, 10),
      usesLightTheme: usesLightTheme === 'true',
    };

    const apiCall =
      isEdit && bundle
        ? perkApi.updateBundle(bundle.id, data)
        : perkApi.createBundle(data);

    apiCall
      .then(response => {
        addSuccess(`Bundle ${isEdit ? 'Updated' : 'Created'}`);
        return response;
      })
      .catch(addResponseError)
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)} noValidate>
        <FormGroup name="isActive" label="Active" errors={errors}>
          <FormCheckbox
            label="Active"
            name="isActive"
            errors={errors}
            ref={register}
          />
        </FormGroup>

        <Membership
          form={form}
          errors={errors}
          name="membershipId"
          defaultValue={defaultMembership}
        />

        {watchMembershipId && (
          <FormImage
            aspectRatio={[1, 1]}
            name="featuredLogoImageUrl"
            label="Reversed Logo"
            defaultValue={bundle?.featuredLogoImageUrl}
            form={form}
            errors={errors}
            ref={register({
              required: 'Reversed logo is required',
            })}
            onChange={(url: string) => {
              setValue('featuredLogoImageUrl', url);
            }}
          />
        )}

        <FormGroup name="isNew" label="New (for future use)" errors={errors}>
          <FormCheckbox
            label="New"
            name="isNew"
            errors={errors}
            ref={register}
          />
        </FormGroup>

        {/* {!watchMembershipId && (
        <FormImage
          aspectRatio={[1, 1]}
          name="logoUrl"
          label="Logo"
          defaultValue=""
          form={form}
          errors={errors}
          ref={register({
            required: 'Logo is required',
          })}
          onChange={(url: string) => {
            setValue('logoUrl', url);
          }}
        />
      )} */}

        {!watchMembershipId && (
          <FormInput
            name="name"
            label="Title"
            defaultValue=""
            maxLength={25}
            hint="Max length of 25 characters"
            placeholder="Some title name here"
            errors={errors}
            ref={register({
              required: 'Title is required',
              maxLength: {
                value: 25,
                message: 'Max length of 25 characters',
              },
            })}
          />
        )}

        <FormInput
          name="order"
          label="Order"
          type="number"
          step="0.01"
          defaultValue=""
          placeholder="0.00"
          min="0"
          errors={errors}
          ref={register({
            required: 'Order is required',
          })}
        />

        <FormGroup
          name="audience"
          errors={errors}
          label="Audience (select all that apply)"
        >
          <FormCheckbox
            label="Freelancer"
            name="audience"
            value="freelancer"
            ref={register({
              required: 'Audience is required',
            })}
          />
          <FormCheckbox
            label="Agency"
            name="audience"
            value="agency"
            ref={register({
              required: 'Audience is required',
            })}
          />
        </FormGroup>

        <FormGroup
          name="isPremium"
          label="Premium (for future use)"
          errors={errors}
        >
          <FormCheckbox
            label="Premium Perk"
            name="isPremium"
            errors={errors}
            ref={register}
          />
        </FormGroup>

        <FormInput
          name="tagline"
          label="Tagline"
          // defaultValue=""
          maxLength={25}
          hint="Max length of 25 characters"
          placeholder="Some title name here"
          errors={errors}
          ref={register({
            required: 'Tagline is required',
            maxLength: {
              value: 25,
              message: 'Max length of 25 characters',
            },
          })}
        />

        {/* <FormTextarea
        name="descriptionSummary"
        label="Description Short"
        maxLength={75}
        hint="Max length of 75 characters"
        placeholder=""
        errors={errors}
        rows="3"
        ref={register({
          required: 'Description is required',
          maxLength: {
            value: 75,
            message: 'Max length of 75 characters',
          },
        })}
      /> */}

        <FormTextarea
          name="description"
          label="Description"
          maxLength={125}
          hint="Max length of 125 characters"
          placeholder=""
          errors={errors}
          ref={register({
            required: 'Description is required',
            maxLength: {
              value: 125,
              message: 'Max length of 125 characters',
            },
          })}
        />

        {/* <FormTextarea
        name="offerSummary"
        label="Offer Short"
        maxLength={25}
        hint="Max length of 25 characters"
        placeholder=""
        errors={errors}
        rows="3"
        ref={register({
          required: 'Offer is required',
          maxLength: {
            value: 25,
            message: 'Max length of 25 characters',
          },
        })}
      /> */}

        {/* <FormTextarea
          name="offer"
          label="Offer"
          maxLength={75}
          hint="Max length of 75 characters"
          placeholder=""
          errors={errors}
          ref={register({
            required: 'Offer is required',
            maxLength: {
              value: 75,
              message: 'Max length of 75 characters',
            },
          })}
        /> */}

        <FormGroup name="usesLightTheme" errors={errors} label="Text Color">
          <FormRadio
            label="White"
            name="usesLightTheme"
            value="true"
            ref={register}
          />
          <FormRadio
            label="Black"
            name="usesLightTheme"
            value="false"
            ref={register}
          />
        </FormGroup>

        <div className="row-flex">
          <div className="col w-full md:w-1/2">
            <FormImage
              aspectRatio={[2, 1]}
              name="horizontalHeaderImageUrl"
              label="Header (horizontal)"
              defaultValue={bundle?.horizontalHeaderImageUrl}
              form={form}
              errors={errors}
              ref={register({
                required: 'Horizontal image is required',
              })}
              onChange={(url: string) => {
                setValue('horizontalHeaderImageUrl', url);
              }}
            />
          </div>
          <div className="col w-full md:w-1/2">
            <FormImage
              aspectRatio={[1, 1]}
              name="squareImageUrl"
              label="Square Image"
              squarePreview
              defaultValue={bundle?.squareImageUrl}
              form={form}
              errors={errors}
              ref={register({
                required: 'Square image is required',
              })}
              onChange={(url: string) => {
                setValue('squareImageUrl', url);
              }}
            />
          </div>
        </div>

        <FormGroup name="isFeatured" label="Featured" errors={errors}>
          <FormCheckbox
            label="Featured Perk"
            name="isFeatured"
            errors={errors}
            ref={register}
          />
        </FormGroup>

        {watchIsFeatured && (
          <div className="row-flex">
            <div className="col w-full md:w-1/3">
              <FormImage
                aspectRatio={[9, 16]}
                name="verticalHeaderImageUrl"
                label="Header (vertical)"
                defaultValue={bundle?.verticalHeaderImageUrl}
                form={form}
                errors={errors}
                ref={register({
                  required: 'Vertical image is required',
                })}
                onChange={(url: string) => {
                  setValue('verticalHeaderImageUrl', url);
                }}
              />
            </div>
          </div>
        )}

        <PerkCategories
          name="perkCategoryIds"
          label="Categories"
          errors={errors}
          isMulti
          form={form}
          defaultValue={defaultCategories}
        />

        <PerkPicker
          name="perkIds"
          label="Perks"
          errors={errors}
          form={form}
          defaultValues={defaultPerks}
        />

        <Expiry
          errors={errors}
          ref={register}
          onChange={milliseconds => {
            setValue('expiresAt', milliseconds);
          }}
          defaultValue={bundle?.expiresAt}
        />

        <FormActions className="mt-auto justify-center">
          <div className="flex justify-end w-full">
            {isEdit && (
              <button
                type="button"
                className="btn btn--outline mr-2"
                onClick={deleteHandler}
              >
                Delete
              </button>
            )}
            <SubmitButton
              text={isEdit ? 'Update' : 'Create'}
              loading={loading}
            />
          </div>
        </FormActions>
      </form>
      <Alert
        isOpen={showDeleteAlert}
        onDidDismiss={() => {
          setShowDeleteAlert(false);
        }}
        header="Are sure?"
        message="This will delete the bundle forever."
        buttons={[
          {
            text: 'Cancel',
            role: 'cancel',
            cssClass: 'alert-secondary-btn',
            handler: () => {},
          },
          {
            text: 'Confirm',
            cssClass: 'alert-primary-btn',
            handler: () => {
              if (bundle) {
                perkApi
                  .deleteBundle(bundle.id)
                  // eslint-disable-next-line
                  .then((response: Perk) => {
                    addSuccess(`Bundle Deleted`);
                  })
                  .catch(addResponseError)
                  .finally(() => {
                    push('/perks/bundles');
                  });
              }
            },
          },
        ]}
      />
    </>
  );
};
export default BundleForm;
