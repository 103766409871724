import React, { useState } from 'react';
import useApplicationNotes from '../../../hooks/useApplicationNotes';
import Button from '../../core/Button';
import { LoadingBalls } from '../../core/Loading';
import ApplicationNoteForm from '../forms/ApplicationNoteForm';
import ApplicationNote from './ApplicationNote';

type Props = {
  work: WorkEntity;
  application: ApplicationEntity;
};

const ApplicationNotes = ({ work, application }: Props) => {
  const [showForm, setShowForm] = useState(false);

  const { data, loading, createApplicationNote } = useApplicationNotes({
    workId: work.id,
    applicationId: application.id,
  });

  return (
    <>
      {showForm ? (
        <ApplicationNoteForm
          application={application}
          onComplete={() => setShowForm(false)}
          createApplicationNote={createApplicationNote}
        />
      ) : (
        <div className="flex flex-col gap-8">
          {loading ? (
            <LoadingBalls isActive />
          ) : (
            data.map(note => (
              <div key={note.id} className="flex flex-col gap-2">
                <ApplicationNote applicationNote={note} />
                <hr />
              </div>
            ))
          )}
          <div className="text-xs text-subdued flex mb-2 w-full">
            <Button
              type="button"
              onClick={() => {
                setShowForm(true);
              }}
              color="primary"
              icon="add"
              text="New note"
              className="w-full"
            />
          </div>
        </div>
      )}
    </>
  );
};

export default ApplicationNotes;
