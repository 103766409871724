import moment from 'moment';
import React, { useState } from 'react';
import { useHistory } from 'react-router';
import { addResponseError } from '../../../services/Messaging';
import Alert from '../../alert/Alert';
import { Link } from '../../core/router';
import SideBar from '../../menu/SideBar';
import Api from '../Api';

const Actions = ({
  work,
  isClosed,
}: {
  work: WorkEntity;
  isClosed: boolean;
}) => {
  const [open, setOpen] = useState(false);
  const [showDeleteAlert, setShowDeleteAlert] = useState(false);
  const history = useHistory();
  const navClass =
    'hover:bg-teal-300 block px-3 h-12 flex items-center md:text-base cursor-pointer';
  const iconClass = 'w-10 h-10 flex items-center justify-center mr-4';
  const toggleOpen = () => setOpen(!open);
  const handleClose = () => setOpen(false);

  return (
    <>
      <button type="button" className="flex items-center" onClick={toggleOpen}>
        <div className="text-sm uppercase mr-1">Menu</div>
        <div className="text-lg">
          <i className="i-more-vert" />
        </div>
      </button>
      <SideBar open={open} setOpen={setOpen}>
        <div className="py-3 sm:w-48">
          <div className="pt-8 pb-4 px-3 flex items-center sm:hidden">
            <div className={iconClass} />
            <h4>Work Menu</h4>
          </div>

          <Link
            to={`/work/${work.id}/candidates/list`}
            onClick={handleClose}
            className={navClass}
          >
            <div className={iconClass}>
              <i className="i-users" />
            </div>
            Candidates
          </Link>

          {!isClosed ? (
            <>
              <Link
                to={`/work/${work.id}/edit`}
                onClick={handleClose}
                className={navClass}
              >
                <div className={iconClass}>
                  <i className="i-edit" />
                </div>
                Edit Work
              </Link>
              <div
                role="none"
                className={navClass}
                onClick={() => {
                  handleClose();
                  setShowDeleteAlert(true);
                }}
              >
                <div className={iconClass}>
                  <i className="i-lock" />
                </div>
                Close Work
              </div>
            </>
          ) : null}
          <Alert
            isOpen={showDeleteAlert}
            onDidDismiss={() => {
              setShowDeleteAlert(false);
            }}
            header="Are sure you want to close this post?"
            message="This action will close the post to new applicants and remove it from the Work Board. The post and its applicants will still be accessible on your profile page, under the Work tab."
            buttons={[
              {
                text: 'Cancel',
                role: 'cancel',
                cssClass: 'alert-secondary-btn',
                handler: () => {},
              },
              {
                text: 'Confirm',
                cssClass: 'alert-primary-btn',
                handler: () => {
                  // Workaround for now, set work expiresAt to now until we have a proper delete route
                  const now = moment()
                    .utc()
                    .format();

                  const workId = work.id || work.objectID || null;

                  if (workId) {
                    Api.update(workId, { expiresAt: now })
                      // eslint-disable-next-line
                      .then((response: WorkEntity) => {
                        history.push(`/work`);
                      })
                      .catch(addResponseError);
                  }
                },
              },
            ]}
          />
        </div>
      </SideBar>
    </>
  );
};

export default Actions;
