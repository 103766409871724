import { Api } from '../../Api';

export const getNylasAuthUrl = async (
  workId: string,
  applicationId: string
) => {
  const { authUrl } = await Api.post(`/scheduler`, {
    workId,
    applicationId,
  });
  return authUrl;
};

export const createSlug = (workId: string, applicantId: string) =>
  `${workId}__workapp__${applicantId}__v001`;
