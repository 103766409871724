import React from 'react';
import cn from 'classnames';
import { Link } from '../core/router';

type Props = {
  crumbs: Breadcrumb[];
};

export type Breadcrumb = {
  title: string;
  linkTo: string;
};

const CandidateBreadcrumbs = ({ crumbs }: Props) => {
  const textClasses = 'text-base uppercase';

  return (
    <div className="flex flex-row gap-2 text-xl uppercase font-semibold">
      {crumbs.map((crumb, i) => {
        if (crumbs.length === i + 1) {
          return (
            <h2
              key={`${crumb.title}`}
              className={cn(textClasses, 'font-normal')}
            >
              <Link to={crumb.linkTo}>{crumb.title}</Link>
            </h2>
          );
        }
        return (
          <h2
            key={`${crumb.title}`}
            className={cn(
              textClasses,
              'gap-2 flex justify-between font-semibold'
            )}
          >
            <Link
              className="underline max-w-130 whitespace-nowrap overflow-hidden overflow-ellipsis"
              to={crumb.linkTo}
            >
              {crumb.title}
            </Link>
            <span>&gt;</span>
          </h2>
        );
      })}
    </div>
  );
};

export default CandidateBreadcrumbs;
