import React, { useMemo } from 'react';
import { orderBy } from 'lodash';
import capitalize from '../../utilities/Capitalize';
import {
  applicationStatuses,
  statusLabel,
  statusColor,
  groupApplicationsByStatus,
} from '../../utilities/ApplicationStatus';
import { Link } from '../core/router';
import WorkCandidateCard from './WorkCandidateCard';
import UnreadCandidateMessages from './UnreadCandidateMessages';
import UnreadIndicator from './UnreadIndicator';
import ExportCandidatesToList from './ExportCandidatesToList';

type Props = {
  applications: KeyedApplications;
  work: WorkEntity;
  status: ApplicationStatus;
};

export default ({ applications, work, status }: Props) => {
  const label = statusLabel(status);
  const groupedApplications: {
    [status: string]: ApplicationEntity[];
  } = useMemo(() => {
    const orderedApplications = orderBy(
      Object.values(applications),
      ['position', 'score', 'updatedAt'],
      ['asc', 'desc', 'asc']
    );
    return groupApplicationsByStatus(orderedApplications);
  }, [applications]);

  const filteredApplications: any = groupedApplications[status] || [];
  const userIds = filteredApplications.map(
    (application: ApplicationEntity) => application.user.id
  );

  return (
    <div className="flex flex-col h-full">
      <div className="flex-1 overflow-y px-4 pt-4 pb-20 md:py-8">
        <div className="flex mb-3">
          <div className="w-full flex flex-1 justify-between">
            <h5>{capitalize(label)}</h5>

            <div>
              <ExportCandidatesToList userIds={userIds} />
            </div>
          </div>
        </div>

        <div className="mb-3">
          <UnreadCandidateMessages applications={filteredApplications} />
        </div>

        {filteredApplications.length === 0 ? (
          <p className="text-center py-10">
            There are no {label} candidates for this work posting.
          </p>
        ) : (
          <div
            className={`grid gap-4 md:gap-8 grid-cols-2 md:grid-cols-3 xl:grid-cols-4 ${
              status === 'passed' ? 'grayscale' : ''
            }`}
          >
            {filteredApplications.map((application: any) => (
              <WorkCandidateCard
                key={application.id}
                application={application}
                to={`?=applicationId=${application.id}`}
              />
            ))}
          </div>
        )}
      </div>

      <div className="fixed px-safe pb-safe bottom-0 w-full bg-white shadow-top">
        <div className="flex justify-between w-full h-16 items-center px-4">
          {Object.keys(applicationStatuses).map((key: any) => {
            const isActive = key === status;
            const applicationCount = groupedApplications[key]?.length || 0;
            const applicationChannelSids = Object.values(
              groupedApplications[key]
            ).map(applicant => applicant.chatChannelSid);

            const background = isActive
              ? {
                  background: statusColor(key),
                }
              : {};
            const textColor = isActive
              ? {
                  color: statusColor(key),
                }
              : {};

            return (
              <Link
                key={key}
                replace
                to={`/work/${work.id}/candidates/list/${key}`}
                className="relative text-sm text-center"
              >
                <div
                  className="relative rounded-md w-6 h-6 mx-auto text-white bg-grey-300 flex justify-center items-center"
                  style={background}
                >
                  <UnreadIndicator
                    className="-mr-2.5 -mt-1"
                    channelSids={applicationChannelSids}
                  />
                  {applicationCount}
                </div>
                <div
                  className="mt-1 font-normal text-grey-300"
                  style={textColor}
                >
                  {statusLabel(key)}
                </div>
              </Link>
            );
          })}
        </div>
      </div>
    </div>
  );
};
