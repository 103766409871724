import React, { useState, useContext } from 'react';
import { useForm } from 'react-hook-form';
import { FormActions, FormInput } from '../../core/form';
import { SubmitButton } from '../../core/Button';
import { addResponseError, addSuccess } from '../../../services/Messaging';
import MembershipContext from '../../membership/Context';

const RecurlyAccountCodeForm = () => {
  const [loading, setLoading] = useState(false);
  const { update, membership } = useContext(MembershipContext);
  const form = useForm({
    mode: 'onBlur',
    shouldFocusError: true,
    defaultValues: {
      recurlyAccountCode: membership?.recurlyAccountCode ?? null,
    },
  });
  const { handleSubmit, register, errors } = form;

  const onSubmit = (values: any) => {
    if (loading) return;
    setLoading(true);

    const data = values;

    update(data)
      .then(() => {
        addSuccess('Recurly account code updated.');
      })
      .catch((error: ResponseError) => {
        addResponseError(error);
      });
  };

  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      noValidate
      className="mt-8 pt-8 border-t border-grey-100"
    >
      <FormInput
        name="recurlyAccountCode"
        label="Recurly Account Code"
        errors={errors}
        ref={register()}
      />
      <FormActions className="mt-auto justify-center">
        <div className="md:ml-auto">
          <SubmitButton text="Save Changes" loading={loading} />
        </div>
      </FormActions>
    </form>
  );
};

export default RecurlyAccountCodeForm;
