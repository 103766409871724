import React from 'react';

import ProfileTimeDifference from '../profile/ProfileTimeDifference';
import ProfileRate from '../profile/ProfileRate';
import ButtonLink from '../core/ButtonLink';
import ScoreAvatar from '../candidateScore/ScoreAvatar';
import ProfileRelations from '../profile/ProfileRelations';
import { LoadingBalls } from '../core/Loading';
import ApplicationMetrics from './ApplicationMetrics';

type Props = {
  user: UserEntity;
  application: ApplicationEntity;
  showRelations?: boolean;
  work: WorkEntity;
};

const CandidatesProfile = ({
  user,
  application,
  showRelations = true,
  work,
}: Props) => {
  const membership = user?.primaryMembershipUser?.membership;
  const userName = `${user?.firstName ?? ''} ${user?.lastName ?? ''}`;
  const roles = user?.roles.map(assoc => assoc.role.name);
  const skills = user?.skills.map(assoc => assoc.skill.name);
  const industries = user?.industries.map(assoc => assoc.industry.name);
  const { scoreMetrics } = application;

  if (!membership || !user) {
    return <LoadingBalls isActive={!user} />;
  }

  return (
    <div className="flex flex-col p-4 items-center justify-center w-full relative">
      <ScoreAvatar
        name={userName}
        imageUrl={user.avatarURL}
        percentage={application.score}
        size="full"
      />

      <div className="flex flex-col gap-4 w-full">
        <div className="flex flex-col mt-6 gap-px items-center">
          <h4>{userName}</h4>
          <p>{user.title}</p>
          {user.location?.friendlyName ? (
            <p>{user.location?.friendlyName}</p>
          ) : null}
        </div>
        <div className="border border-grey-200 flex mt-4 p-4 self-center gap-6 items-center justify-around">
          <ProfileTimeDifference user={user} />
          <ProfileRate user={user} />
        </div>
        <ButtonLink
          color="primary"
          size="sm"
          href={`/members/user/${user.id}`}
          text="View Full Profile"
          className="mt-6 w-full"
        />
        {showRelations ? (
          <div>
            <ProfileRelations
              showing
              title="Roles"
              entities={roles}
              name={userName}
            />
            <ProfileRelations
              showing
              title="Skills"
              entities={skills}
              name={userName}
            />
            <ProfileRelations
              showing
              title="Industries"
              entities={industries}
              name={userName}
            />
          </div>
        ) : null}
        {membership ? (
          <ApplicationMetrics metrics={scoreMetrics.criteria} work={work} />
        ) : null}
      </div>
    </div>
  );
};
export default CandidatesProfile;
