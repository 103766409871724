import React, { useMemo } from 'react';
import { useCookie } from 'react-use';
import useAnalytics from '../../hooks/useAnalytics';
import useCurrentUser from '../../hooks/useCurrentUser';
import useNavigate from '../../hooks/useNavigate';
import useBrandConfig from '../../hooks/useBrandConfig';
import MembershipProfileCompletionHeader from '../profileCompleteness/MembershipProfileCompletionHeader';
import ProfileCompletionHeader from '../profileCompleteness/ProfileCompletionHeader';
import {
  getMembershipProfileRequirements,
  getUserProfileRequirements,
  isUserProfileComplete,
  isMembershipProfileComplete,
} from '../profileCompleteness/utils/profile';

const PROFILE_COMPLETION_COOKIE = 'communo-profileCompletion';

const ProfileCompletion = () => {
  const { showProfileCompletion } = useBrandConfig().styles.feed;
  const { membership, membershipAccess, planType } = useCurrentUser();
  const navigate = useNavigate();
  const { track } = useAnalytics();
  const [
    profileCompletionVisibility,
    setProfileCompletionVisibility,
  ] = useCookie(PROFILE_COMPLETION_COOKIE);
  const isAdminUser = membershipAccess === 'admin';
  const isIndividualPlan = membership?.plan.isIndividualPlan ?? false;
  const membershipRequirements = getMembershipProfileRequirements(planType);
  const userRequirements = getUserProfileRequirements(isIndividualPlan);
  const isUserComplete = isUserProfileComplete(userRequirements);
  const isMembershipComplete = isMembershipProfileComplete(
    membershipRequirements
  );

  const canHideProfileCompletion = useMemo(() => {
    // Freelancer
    if (isIndividualPlan) {
      return isUserComplete;
    }

    // Staff
    if (!isAdminUser) {
      return isUserComplete;
    }

    // Admin
    return isUserComplete && isMembershipComplete;
  }, [isAdminUser, isIndividualPlan, isMembershipComplete, isUserComplete]);

  const handleHideProfileCompletion = () => {
    setProfileCompletionVisibility('hidden', {
      expires: 86400 * 365, // 365 days
      path: '/',
    });
  };

  if (profileCompletionVisibility === 'hidden' && canHideProfileCompletion) {
    return null;
  }

  const handleClickMembershipProfile = () => {
    track('Feed - MembershipProfileCompletion Clicked');
    navigate('#/edit-page/completion');
  };

  const handleClickProfile = () => {
    track('Feed - ProfileCompletion Clicked');
    navigate('#/edit-profile/completion');
  };

  if (!showProfileCompletion) return null;

  return (
    <div className="flex flex-col gap-4">
      <div className="flex items-center">
        <h4 className="flex-auto">Profile Completion</h4>
        {canHideProfileCompletion ? (
          <button
            type="button"
            onClick={handleHideProfileCompletion}
            className="text-link font-semibold"
          >
            Hide
          </button>
        ) : null}
      </div>

      {!isIndividualPlan && isAdminUser ? (
        <div
          role="link"
          className="block cursor-pointer"
          tabIndex={0}
          onClick={handleClickMembershipProfile}
          onKeyDown={e => {
            if (e.keyCode === 13) {
              handleClickMembershipProfile();
            }
          }}
        >
          <MembershipProfileCompletionHeader
            requirements={membershipRequirements}
          />
        </div>
      ) : null}

      <div
        role="link"
        className="block cursor-pointer"
        tabIndex={0}
        onClick={handleClickProfile}
        onKeyDown={e => {
          if (e.keyCode === 13) {
            handleClickProfile();
          }
        }}
      >
        <ProfileCompletionHeader requirements={userRequirements} />
      </div>
    </div>
  );
};
export default ProfileCompletion;
