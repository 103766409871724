// eslint-disable-next-line no-unused-vars
import { SearchResponse } from '@algolia/client-search';
// eslint-disable-next-line no-unused-vars
import { URLFilterOption } from '../components/filter/options';
import { ALGOLIA_PREFIX } from '../config';
// eslint-disable-next-line no-unused-vars
import { SearchIndexes } from '../components/search/index.d';

function getSearchIndex(indexName: SearchIndexes) {
  return `${ALGOLIA_PREFIX}-${indexName}`;
}

function getFacetValuesFromResponse(
  results: SearchResponse,
  facetKey: string
): { [facetValue: string]: number } {
  if (!results) {
    return {};
  }

  const facets = results.facets || {};
  return facets[facetKey] || {};
}

function buildAlgoliaParams({
  filterOptions,
  filterValues,
  page = 0,
  facets = [],
  hitsPerPage = 96,
  defaultFilters = [],
  excludeFilter = null,
}: {
  filterOptions: URLFilterOption[];
  page: number;
  facets: string[];
  filterValues: any;
  hitsPerPage?: number;
  defaultFilters?: string[];
  excludeFilter?: string | null;
}) {
  const algoliaParams: any = {
    hitsPerPage,
    facets,
    query: filterValues.search || '',
    page,
    maxValuesPerFacet: 250,
  };

  if (excludeFilter) {
    // Don't return hits for sub-queries
    algoliaParams.attributesToRetrieve = [];
    algoliaParams.attributesToHighlight = [];
  }

  const filtersArr: any = [...defaultFilters];
  const facetFilters: any[] = [];

  filterOptions.forEach(filterOption => {
    if (excludeFilter && excludeFilter === filterOption.name) {
      return;
    }

    // Add optional algolia filter
    if (filterOption.toAlgoliaFilter) {
      const algoliaString = filterOption.toAlgoliaFilter(filterValues);
      if (algoliaString) {
        filtersArr.push(algoliaString);
      }
    }

    // Add optional facet filter
    if (filterOption.toFacetFilter) {
      const algoliaFacetFilter = filterOption.toFacetFilter(filterValues);
      if (algoliaFacetFilter.length) {
        facetFilters.push(...algoliaFacetFilter);
      }
    }

    // Add optional custom filter
    if (filterOption.addAlgoliaParam) {
      filterOption.addAlgoliaParam(algoliaParams, filterValues);
    }
  });

  algoliaParams.filters = filtersArr.join(' AND ');
  algoliaParams.facetFilters = facetFilters;

  return algoliaParams;
}

function buildSearchQueries({
  filterOptions,
  filterValues,
  page,
  facets,
  defaultFilters = [],
}: {
  filterOptions: URLFilterOption[];
  filterValues: any;
  page: number;
  facets: string[];
  defaultFilters: string[];
}) {
  return filterOptions
    .filter(filterOption => {
      return filterOption.toFacetFilter;
    })
    .reduce(
      (carry: any, filterOption: URLFilterOption) => {
        // eslint-disable-next-line no-param-reassign
        carry[filterOption.name] = buildAlgoliaParams({
          filterOptions,
          filterValues,
          page,
          facets,
          defaultFilters,
          excludeFilter: filterOption.name,
        });

        return carry;
      },
      {
        search: buildAlgoliaParams({
          filterOptions,
          filterValues,
          page,
          facets,
          defaultFilters,
          excludeFilter: null,
        }),
      }
    );
}

export {
  getSearchIndex,
  getFacetValuesFromResponse,
  buildAlgoliaParams,
  buildSearchQueries,
};
