import development from './development';
import { Environment } from '.';

const demoEnvironment: Environment = {
  ...development,
  appUrl: 'https://demo.communo.com',
  apiUrl: 'https://demo-api.communo.com',
  algoliaPrefix: 'demo',
  headerColor: 'red-1100',
  segmentKey: null,
  chatRoles: {
    RLba8bc199d1164946b0c28f7c4fcf5d04: {
      type: 'channelAdmin',
      label: 'Admin',
    },
    RL18fe17de597643cb980b3796db69a0d8: {
      type: 'channelMember',
      label: 'Member',
    },
    RL2b2dd8d0d7ec4c8185b311318d0bd329: {
      type: 'serviceAdmin',
      label: 'Service Admin',
    },
    RLcb542f7e13af494cbbf8d1aebdd35160: {
      type: 'serviceUser',
      label: 'User',
    },
  },
};

export default demoEnvironment;
