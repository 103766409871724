/* eslint-disable no-console */
import { ErrorMessage } from '@hookform/error-message';
import * as Sentry from '@sentry/react';
import { useQueryClient } from '@tanstack/react-query';
import { JSONValue } from '@twilio/conversations';
import React, { forwardRef, useImperativeHandle } from 'react';
import { useForm } from 'react-hook-form';
import { IChannel } from '../../../@types/chat.d';
import { ImperativeHandleRef } from '../../../@types/conversation.d';
import useCurrentUser from '../../../hooks/useCurrentUser';
import { addError, addSuccess } from '../../../services/Messaging';
import { Input } from '../../core/form';
import favorApi from '../../generosity/Api';
import ChannelQueries from '../../generosity/queries';
import { ListActions } from '../../listActions';
import BlockedMembers from '../BlockedMembers';
import AvatarUploader from '../components/AvatarUploader';
import ChannelMembersList from '../components/ChannelMembersList';
import MemberLeaveChannel from '../components/MemberLeaveChannel';
import MemberMuteChannel from '../components/MemberMuteChannel';
import ToggleChannelState from '../components/ToggleChannelState';
import useChannelRole from '../hooks/useChannelRole';
import useChatChannelInstance from '../hooks/useChatChannelInstance';
import useChatChannelMembers from '../hooks/useChatChannelMembers';
import channelMemberRole from '../utilities/channelMemberRole';

type FormFields = {
  groupName: string;
};

const ConversationSettings = forwardRef(
  ({ channel }: { channel: IChannel }, ref: React.Ref<ImperativeHandleRef>) => {
    const { sid, friendlyName, attributes } = channel;
    const mappedAttributes = attributes;
    const currentUserRole = useChannelRole(channel);
    const queryClient = useQueryClient();

    const addChannelModerator = ChannelQueries.useAddChannelModerator();
    const removeChannelModerator = ChannelQueries.useRemoveChannelModerator();
    const toggleChannelState = ChannelQueries.useToggleChannelState();
    const blockMember = ChannelQueries.useBlockMember();
    const removeChannelParticipant = ChannelQueries.useRemoveChannelParticipant();

    const { channelInstance } = useChatChannelInstance(sid);
    const { user: currentUser } = useCurrentUser();
    const { channelMembers } = useChatChannelMembers(sid);
    const { handleSubmit, errors, register } = useForm<FormFields>({
      mode: 'onBlur',
      shouldFocusError: true,
      defaultValues: {
        groupName: friendlyName,
      },
    });

    const currentMember = channelMembers[currentUser?.id || 0];
    const { isChannelAdmin } = channelMemberRole(currentMember?.roleSid);

    const onSubmit = async (values: FormFields) => {
      try {
        const { groupName } = values;
        const channelAttributes = channelInstance.attributes;
        if (channelAttributes.favor) channelAttributes.favor.title = groupName;

        await channelInstance.updateAttributes(
          JSON.parse(JSON.stringify(channelAttributes))
        );
        await channelInstance.updateFriendlyName(groupName);
        await favorApi.update(channel.sid, { title: groupName });
        queryClient.invalidateQueries(['channels']);
        addSuccess('Group settings successfully saved.');
      } catch (error) {
        Sentry.captureException(error, {
          contexts: {
            action: 'update channel details',
          },
          user: {
            email: currentUser?.email,
          },
        });
        addError('There was an issue while saving the group settings.');
      }
    };

    const handleAvatarChange = (imageURL: string) =>
      channelInstance.updateAttributes({
        ...mappedAttributes,
        avatarURL: imageURL,
      } as JSONValue);

    const handleRemoveMember = async (identity: string) => {
      await channelInstance
        .removeParticipant(identity)
        .then(() => {
          addSuccess('Member successfully removed.');
          removeChannelParticipant.mutate(
            {
              channelId: channelInstance.sid,
              userId: identity,
            },
            {
              onSuccess: () => {
                queryClient.invalidateQueries([
                  'channels',
                  { filter: 'joined' },
                ]);
              },
              onError: error => {
                Sentry.captureException(error, {
                  contexts: {
                    action: 'remove member mutation',
                  },
                  user: {
                    email: currentUser?.email,
                  },
                });
              },
            }
          );
        })
        .catch(() => {
          addError('Could not remove member.');
        });
    };

    const handleAddAdmin = (identity: string, userFriendlyName: string) => {
      addChannelModerator.mutate(
        {
          channelId: channelInstance.sid,
          userId: identity,
        },
        {
          onSuccess: () => {
            queryClient.invalidateQueries(['members', sid]);
            addSuccess(`Successfully added ${userFriendlyName} as an admin`);
          },
          onError: error => {
            Sentry.captureException(error, {
              contexts: {
                action: 'add channel mutation',
              },
              user: {
                email: currentUser?.email,
              },
            });
            addError(
              `An error occurred while adding ${userFriendlyName} as an admin`
            );
          },
        }
      );
    };

    const handleRemoveChannelModerator = (
      identity: string,
      userFriendlyName: string
    ) => {
      removeChannelModerator.mutate(
        {
          channelId: channelInstance.sid,
          userId: identity,
        },
        {
          onSuccess: () => {
            queryClient.invalidateQueries(['members', sid]);
            addSuccess(`Successfully removed ${userFriendlyName} as an admin`);
          },
          onError: error => {
            Sentry.captureException(error, {
              contexts: {
                action: 'remove channel moderation mutation',
              },
              user: {
                email: currentUser?.email,
              },
            });
            addError(
              `An error occurred while removing ${userFriendlyName} as admin`
            );
          },
        }
      );
    };

    const handleToggleChannelState = () => {
      toggleChannelState.mutate(
        { channelId: channelInstance.sid },
        {
          onSuccess: () => {
            addSuccess(`Channel successfully toggled`);
          },
          onError: error => {
            Sentry.captureException(error, {
              contexts: {
                action: 'toggle channel mutation',
              },
              user: {
                email: currentUser?.email,
              },
            });
            addError(`An error occurred while toggling the channel state`);
          },
        }
      );
    };

    const handleBlockMember = async (userId: string) => {
      await channelInstance.removeParticipant(userId);

      blockMember.mutate(
        {
          channelId: channelInstance.sid,
          userId,
        },
        {
          onSuccess: () => {
            queryClient.invalidateQueries(['members']);
            queryClient.invalidateQueries(['channels', { filter: 'joined' }]);
            addSuccess(`Successfully blocked member`);
          },
          onError: error => {
            Sentry.captureException(error, {
              contexts: {
                action: 'block member mutation',
              },
              user: {
                email: currentUser?.email,
              },
            });
            addError(`An error occurred while blocking a member`);
          },
        }
      );
    };

    const members = Object.values(channelMembers);

    useImperativeHandle(ref, () => ({
      submit: handleSubmit(onSubmit),
      isChannelAdmin,
      currentUserRole,
    }));

    return (
      <>
        {attributes.type !== 'work' ? (
          <div className="form-group flex items-start">
            <AvatarUploader
              onChange={handleAvatarChange}
              defaultValue={attributes.avatarURL}
              hasPermission={isChannelAdmin}
            />
            <div className="w-full mt-4 ml-2">
              <Input
                name="groupName"
                type="text"
                placeholder="Group name..."
                readOnly={currentUserRole.isParticipant}
                ref={register({
                  required: 'A group name is required',
                })}
              />
              <div className="form-error">
                <ErrorMessage name="groupName" errors={errors} />
              </div>
            </div>
          </div>
        ) : null}

        <div className="mb-8">
          <div className="text-lg mb-2">Actions</div>

          <ListActions>
            <MemberLeaveChannel channel={channel} />
            <MemberMuteChannel member={currentMember} />
            {isChannelAdmin && (
              <ToggleChannelState
                channelState={channel.attributes.channelState}
                onToggle={handleToggleChannelState}
              />
            )}
          </ListActions>
        </div>

        <ChannelMembersList
          currentUserRole={currentUserRole}
          members={members}
          userIsAdmin={isChannelAdmin}
          onRemoveMember={handleRemoveMember}
          onBlock={handleBlockMember}
          onMakeAdmin={handleAddAdmin}
          onRemoveChannelModerator={handleRemoveChannelModerator}
          channelOwnerId={attributes.createdBy ?? ''}
          isCurrentUserOwner={currentUser?.id === attributes.createdBy}
        >
          {}
        </ChannelMembersList>
        {isChannelAdmin && <BlockedMembers channelId={channelInstance.sid} />}
      </>
    );
  }
);

export default ConversationSettings;
