/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/no-access-state-in-setstate */
import React from 'react';

type CountdownProps = { countdownTime: any };

type CountdownState = {
  number: any | null;
};

export default class Countdown extends React.Component<
  CountdownProps,
  CountdownState
> {
  timeout: any;

  constructor(props: CountdownProps) {
    super(props);
    this.state = {
      number: props.countdownTime / 1000,
    };
  }

  componentDidMount() {
    this.timeout = setTimeout(this.updateNumber, 1000);
  }

  componentWillUnmount() {
    clearInterval(this.timeout);
  }

  updateNumber = () => {
    // eslint-disable-next-line react/destructuring-assignment
    const nextNumber = this.state.number - 1;
    this.setState({
      number: nextNumber,
    });
    if (nextNumber !== 0) {
      this.timeout = setTimeout(this.updateNumber, 1000);
    }
  };

  render() {
    return (
      <div className="countdown">
        {this.state.number !== 0 ? this.state.number : null}
      </div>
    );
  }
}
