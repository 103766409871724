import environment from './environment';

export const APP_ENVIRONMENT = environment.appEnvironment;
export const API_VERSION = 'v1';
export const APP_DOMAIN = environment.appUrl;
export const API_DOMAIN = environment.apiUrl;
export const ALGOLIA_APP_ID = environment.algoliaAppId;
export const ALGOLIA_PREFIX = environment.algoliaPrefix;
export const API_ROOT = `${API_DOMAIN}/${API_VERSION}`;
export const CLOUDINARY_API_KEY = environment.cloudinaryApiKey;
export const CLOUDINARY_CLOUD_NAME = environment.cloudinaryCloudName;
export const LOGROCKET_APP_ID = environment.logRocketAppId;
export const GOOGLE_MAPS_API_KEY = environment.googleMapsApiKey;
export const FIREBASE_CONFIG = environment.firebaseConfig;
export const SEGMENT_KEY = environment.segmentKey;
export const SENTRY_DSN = environment.sentryDsn;
export const YOUTUBE_CHANNEL_ID = environment.youtubeChannelId;
export const FEED_VIDEO_PLAYLIST_ID = environment.feedVideoPlaylistId;
export const CHAT_MESSAGES_GROUPING_INTERVAL =
  environment.chatMessagesGroupingInterval;
export const CHAT_MESSAGES_PER_PAGE = environment.chatMessagesPerPage;
export const CHAT_CHANNELS_PER_PAGE = environment.chatChannelsPerPage;
export const CHAT_ROLES = environment.chatRoles;
export const OPENGRAPH_IO_KEY = environment.opengraphKey;
export const DISCOVER_LIST_OPTIONS = {
  slidesPerView: 5,
  freeMode: true,
  spaceBetween: 2,
};
export const DO_DEPLOY_UPDATE = environment.doDeployUpdate || false;
export const HEADER_COLOR = environment.headerColor || 'black';
export const HBCU_PLAN_ID = '071f81c5e9b84078b319b5b71a14df9a';
export const FREELANCER_MEMBER_PLAN_ID = 'f76c7a3b-bda3-4394-883c-fe8badeea6cb';
export const FREELANCER_MEMBER_SLUG = 'freelance-member';
export const GUEST_AGENCY_PLAN_SLUG = 'agency-guest';
export const GUEST_BUSINESS_PLAN_SLUG = 'business-guest';
export const BEATS_MEMBERSHIP_ID = environment.beatsMembershipId;
export const ZENDESK_KEY = environment.zendeskKey;
export const AGENCY_P2P_PLAN_SLUG = 'agency-p2p';
export const BUSINESS_P2P_PLAN_SLUG = 'business-p2p';

const {
  enableProjectTypes = false,
  enablePublicCandidatePage = false,
  supportCompanyOnJobPost = false,
  showCompanyOnJobPost = false,
  disableRateOnSignup = false,
  enableExperienceLevel = false,
  enableInviteToApply = false,
  enableRecruiterAssignment = false,
  showApplicantNotes = false,
  showScheduler = false,
} = environment.featureFlags;

export const ENABLE_PROJECT_TYPES = enableProjectTypes;
export const ENABLE_PUBLIC_CANDIDATE_PAGE = enablePublicCandidatePage;
export const SUPPORT_COMPANY_ON_JOB_POST = supportCompanyOnJobPost;
export const SHOW_COMPANY_ON_JOB_POST = showCompanyOnJobPost;
export const DISABLE_RATE_ON_SIGNUP = disableRateOnSignup;
export const ENABLE_EXPERIENCE_LEVEL = enableExperienceLevel;
export const ENABLE_INVITE_TO_APPLY = enableInviteToApply;
export const ENABLE_RECRUITER_ASSIGNMENT = enableRecruiterAssignment;
export const SHOW_APPLICANT_NOTES = showApplicantNotes;
export const SHOW_SCHEDULER = showScheduler;
