import { useReducer, useEffect, useState, useCallback, useMemo } from 'react';
import querystringify from 'querystringify';
import { pickBy } from 'lodash';
// eslint-disable-next-line no-unused-vars
import { BaseFilterOption, URLFilterOption } from './options';
import {
  // eslint-disable-next-line no-unused-vars
  FilterAction,
  // eslint-disable-next-line no-unused-vars
  FilterOptionDependencyOperator,
  // eslint-disable-next-line no-unused-vars
  FilterState,
  // eslint-disable-next-line no-unused-vars
  FilterValues,
  // eslint-disable-next-line no-unused-vars
  ImperativeActions,
} from './index.d';

function reducer(state: FilterState, action: FilterAction) {
  switch (action.type) {
    case 'UPDATE_VALUE':
      return {
        ...state,
        values: {
          ...state.values,
          [action.payload.name]: action.payload.value,
        },
      };
    case 'UPDATE_VALUES':
      return {
        ...state,
        values: action.payload,
      };
    case 'CLEAR_VALUES':
      return {
        ...state,
        values: {},
      };
    default:
      return state;
  }
}

function initializeFromFilterOptions(
  options: BaseFilterOption[]
): FilterValues {
  const initial: FilterValues = {};
  return options.reduce((acc, option) => {
    acc[option.name] = option.initialValue;
    return acc;
  }, initial);
}

export const filterDependencies = (
  values: FilterValues,
  name: string,
  operator: FilterOptionDependencyOperator,
  value?: any
): boolean => {
  switch (operator) {
    case 'EQUALS':
      return values[name] === value;
    case 'EXISTS':
      return !!values[name];
    case 'INCLUDES':
      return Array.isArray(value) && value.includes(values[name]);
    case 'EXCLUDES':
      return !(Array.isArray(value) && value.includes(values[name]));
    default:
      return true;
  }
};

type Props = {
  options: URLFilterOption[];
};

const useFilter = ({ options }: Props) => {
  const [state, dispatch] = useReducer<
    React.Reducer<FilterState, FilterAction>
  >(reducer, {
    values: { ...initializeFromFilterOptions(options), search: '' },
  });

  const filterOptions = (
    options: URLFilterOption[],
    values: FilterValues
  ): URLFilterOption[] => {
    return options.filter(
      option =>
        !option.dependsOn ||
        filterDependencies(
          values,
          option.dependsOn.targetName,
          option.dependsOn.operator,
          option.dependsOn.value
        )
    );
  };

  const [paramsLoaded, setParamsLoaded] = useState(false);
  const filteredOptions = useMemo(() => filterOptions(options, state.values), [
    state.values,
    options,
  ]);

  const updateFilterValue = useCallback((name: string, value?: any) => {
    dispatch({
      type: 'UPDATE_VALUE',
      payload: {
        name,
        value,
      },
    });
  }, []);

  const updateFilters = useCallback((values: FilterValues) => {
    dispatch({
      type: 'UPDATE_VALUES',
      payload: values,
    });
  }, []);

  const updateFiltersByQueryParams = useCallback(() => {
    const urlParams = querystringify.parse(window.location.search);
    const paramsToValues = options.reduce(
      (acc: FilterValues, option: URLFilterOption) => {
        Object.assign(acc, option.fromURL(urlParams));
        return acc;
      },
      {}
    );

    paramsToValues.search = (urlParams as any).search ?? '';
    const paramsMergedWithState = pickBy({
      ...state.values,
      ...pickBy(paramsToValues),
    });

    const newFilteredOptions = filterOptions(options, paramsMergedWithState);

    const filteredValues = newFilteredOptions.reduce(
      (acc: { [key: string]: string | undefined }, option: URLFilterOption) => {
        Object.assign(acc, option.toURL(paramsMergedWithState[option.name]));
        return acc;
      },
      {}
    );

    updateFilters(filteredValues);
    setParamsLoaded(true);
  }, [options, setParamsLoaded, state.values, updateFilters]);

  const clearFilters = useCallback(() => {
    dispatch({ type: 'CLEAR_VALUES' });
  }, []);

  const getFilterValue = useCallback(
    (name: string): any => {
      return state.values[name];
    },
    [state.values]
  );

  const actions: ImperativeActions = useMemo(
    () => ({
      clearFilters,
      getFilterValue,
      updateFilterValue,
      updateFilters,
      updateFiltersByQueryParams,
    }),
    [
      clearFilters,
      getFilterValue,
      updateFilterValue,
      updateFilters,
      updateFiltersByQueryParams,
    ]
  );

  // Load params from URL only once on "mount"
  useEffect(() => {
    if (!paramsLoaded) {
      updateFiltersByQueryParams();
    }
  }, [
    options,
    paramsLoaded,
    setParamsLoaded,
    state.values,
    updateFilters,
    updateFiltersByQueryParams,
  ]);

  // Update URL Params whenever filters are changed
  useEffect(() => {
    const valuesToURLParams = filteredOptions.reduce(
      (acc: { [key: string]: string | undefined }, option: URLFilterOption) => {
        Object.assign(acc, option.toURL(getFilterValue(option.name)));
        return acc;
      },
      {}
    );
    valuesToURLParams.search = state.values.search;

    const newParams = `?${querystringify.stringify(pickBy(valuesToURLParams))}`;
    if (newParams !== window.location.search) {
      window.history.replaceState(
        '',
        '',
        `${window.location.pathname}${newParams}${window.location.hash}`
      );
    }
  }, [filteredOptions, getFilterValue, state.values]);

  return {
    paramsLoaded,
    actions,
    options,
    filteredOptions,
    values: state.values,
  };
};

export default useFilter;
