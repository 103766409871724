import React from 'react';
import ForgotPassword from '../components/user/views/ForgotPassword';
import Page from '../components/core/Page';
import SignupLayout from '../components/user/signup/SignupLayout';

export default () => {
  return (
    <Page
      layout="basic"
      className="page-bg--white"
      pageTitle="Forgot Password"
      mobileHeader={() => null}
      hideHeader
    >
      <SignupLayout headerImg="https://res.cloudinary.com/communo/image/upload/v1589576439/onboarding/onboarding-login-forgot.jpg">
        <div>
          <ForgotPassword />
        </div>
      </SignupLayout>
    </Page>
  );
};
