import { useCallback, useEffect, useMemo, useState } from 'react';
import Api from '../components/work/Api';
import { addResponseError, addSuccess } from '../services/Messaging';

type Props = {
  workId: string;
  applicationId: string;
};

type AggregateRating = {
  average: number;
  count: number;
};

export default function useApplicationNotes({ workId, applicationId }: Props) {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([] as ApplicationNoteEntity[]);

  const aggregateRating: AggregateRating = useMemo(() => {
    const ratings = data.map(note => note.rating);
    const sum: number = ratings.reduce(
      (acc: number, curr: number | null) => acc + (curr || 0),
      0
    );
    return { average: sum / ratings.length, count: ratings.length };
  }, [data]);

  const refresh = useCallback(() => {
    if (loading) return;
    setLoading(true);

    Api.getApplicationNotes(workId, applicationId)
      .then(response => {
        setData(response);
      })
      .catch((error: ResponseError) => {
        setData([]);
        addResponseError(error);
      })
      .finally(() => {
        setLoading(false);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [workId, applicationId]);

  const createApplicationNote = useCallback(
    (data: {
      userId: string;
      note: string;
      rating: number;
      name: string;
      commenterUserId: string | null;
    }) => {
      Api.createApplicationNote(workId, applicationId, data)
        .then(response => {
          addSuccess('Note has been saved!');
          setData(data => [...data, response]);
        })
        .catch((error: ResponseError) => {
          addResponseError(error);
        });
    },
    [workId, applicationId]
  );

  useEffect(() => {
    refresh();
  }, [refresh]);

  return {
    data,
    aggregateRating,
    loading,
    refresh,
    createApplicationNote,
  };
}
