import React, { useState, useEffect } from 'react';
/* eslint-disable-next-line no-unused-vars */
import { FilterOptionComponentProps } from '../index.d';
import URLFilterOption from './URLFilterOption';

interface OptionType {
  label: string;
  activeLabel?: string;
  value: any;
}

interface RadioFilterOptionProps {
  multiple?: boolean;
  options?: OptionType[];
  allowClear?: boolean;
}

type FilterValueType = string | null;

type RadioFilterComponentProps = FilterOptionComponentProps<FilterValueType> &
  RadioFilterOptionProps;

export const RadioFilterComponent: React.FC<RadioFilterComponentProps> = (
  props: RadioFilterComponentProps
) => {
  const {
    multiple = false,
    name,
    sectionTitle,
    showTitle,
    options = [],
    onChange,
    value,
    allowClear = true,
  } = props;
  const [checkedItems, setCheckedItems] = useState(new Set([value]));

  useEffect(() => {
    setCheckedItems(new Set([value]));
  }, [value]);

  const handleChange = (val: string) => {
    if (checkedItems.has(val) && allowClear) {
      checkedItems.delete(val);
    } else if (checkedItems.size > 0 && !multiple) {
      checkedItems.clear();
      checkedItems.add(val);
    } else {
      checkedItems.add(val);
    }

    setCheckedItems(new Set(checkedItems));
    const result = multiple
      ? Array.from(checkedItems)
      : Array.from(checkedItems)[0] ?? null;
    onChange(name, result);
  };

  return (
    <section className="filter-group">
      {showTitle ? (
        <h5 className="filter-group__title">{sectionTitle}</h5>
      ) : null}
      {options.map(option => (
        <div className="filter-group__item pb-0" key={option.label}>
          <label className="checkbox-stylized w-full">
            <input
              checked={checkedItems.has(option.value)}
              name={`${name}[]`}
              onChange={e => handleChange(e.target.value)}
              type="checkbox"
              value={option.value}
            />
            <span className="ml-2">{option.label}</span>
          </label>
        </div>
      ))}
    </section>
  );
};

class RadioFilterOption extends URLFilterOption<FilterValueType>
  implements RadioFilterOptionProps {
  multiple?: boolean;

  options?: OptionType[];

  constructor(
    props: Omit<
      URLFilterOption<FilterValueType>,
      'component' | 'fromURL' | 'toURL'
    > &
      RadioFilterOptionProps
  ) {
    super({ ...props, component: RadioFilterComponent });
    this.options = props.options;
    this.multiple = props.multiple;
  }
}

export default RadioFilterOption;
