import React from 'react';
import { FormGroup, FormRadio } from '../../core/form';

const Type = ({ errors, register }: any) => {
  const ref = register({ required: 'Work type is required' });
  return (
    <FormGroup required name="type" label="Choose work type" errors={errors}>
      <FormRadio name="type" value="Full-time" label="Employee" ref={ref} />
      <FormRadio
        name="type"
        value="Project Hire"
        label="Freelancer (for a single project)"
        ref={ref}
      />
      {/* <FormRadio
        name="type"
        value="Pitch Partner"
        label="Pitch Partner - I need to find a partner for a joint client pitch"
        ref={ref}
      /> */}
      <FormRadio
        name="type"
        value="Ongoing Need"
        label="Freelancer (for an ongoing need)"
        ref={ref}
      />
    </FormGroup>
  );
};
export default Type;
