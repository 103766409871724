import { SearchResponse } from '@algolia/client-search';
import sortBy from 'lodash/sortBy';
import { getFacetValuesFromResponse } from '../../../utilities/Algolia';

enum WorkTypes {
  FULL_TIME = 'Full-time',
  ONGOING_NEED = 'Ongoing Need',
  PROJECT_HIRE = 'Project Hire',
}

export const getWorkTypeLabel = (facet: string | null) => {
  switch (facet) {
    case WorkTypes.FULL_TIME:
      return 'Employee';
    case WorkTypes.ONGOING_NEED:
      return 'Freelancer (for an ongoing need)';
    case WorkTypes.PROJECT_HIRE:
      return 'Freelancer (for a single project)';
    default:
      return facet;
  }
};

export default function getTypeOptions(results: {
  [key: string]: SearchResponse;
}) {
  const getOrder = (facet: string) => {
    switch (facet) {
      case WorkTypes.FULL_TIME:
        return 0;
      case WorkTypes.ONGOING_NEED:
        return 1;
      case WorkTypes.PROJECT_HIRE:
        return 2;
      default:
        return 10;
    }
  };

  const typeFacetOptions = getFacetValuesFromResponse(
    results?.type || results?.search,
    'type'
  );

  return sortBy(
    Object.keys(typeFacetOptions).map(facet => {
      return {
        label: `${getWorkTypeLabel(facet)} (${typeFacetOptions[facet]})`,
        activeLabel: facet,
        value: facet,
      };
    }),
    [type => getOrder(type.value)]
  );
}
