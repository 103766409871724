import React, { useCallback, useState } from 'react';
import ReactVideoRecorder from 'react-video-recorder';
import Button from '../core/Button';
import { Api } from '../../Api';
import { addResponseError, addError } from '../../services/Messaging';
import Modal from '../core/modal/Modal';
import useModal from '../core/modal/hooks/useModal';
import Countdown from './videoRecorderComponents/countdown';
import Timer from './videoRecorderComponents/timer';
import { LoadingBalls } from '../core/Loading';
import { isMobile } from '../../utilities/MatchMedia';

const Actions = ({
  // t,
  isVideoInputSupported,
  isInlineRecordingSupported,
  // thereWasAnError,
  isRecording,
  isCameraOn,
  streamIsReady,
  // isConnecting,
  isRunningCountdown,
  isReplayingVideo,
  // isReplayVideoMuted,
  countdownTime,
  timeLimit,
  // showReplayControls,
  // replayVideoAutoplayAndLoopOff,
  useVideoInput,
  // onTurnOnCamera,
  // onSwitchCamera,
  // onTurnOffCamera,
  onOpenVideoInput,
  onStartRecording,
  onStopRecording,
  // onPauseRecording,
  // onResumeRecording,
  onStopReplaying,
  upload,
  isLoading,
}: any) => {
  const renderContent = () => {
    const shouldUseVideoInput =
      !isInlineRecordingSupported && isVideoInputSupported;

    // if (
    //   (!isInlineRecordingSupported && !isVideoInputSupported) ||
    //   thereWasAnError ||
    //   isConnecting ||
    //   isRunningCountdown
    // ) {
    //   return null;
    // }

    if (isRunningCountdown) {
      return <Countdown countdownTime={countdownTime} />;
    }

    if (isReplayingVideo) {
      return (
        <>
          {isLoading ? (
            <div>
              <button
                type="button"
                onClick={onStopReplaying}
                data-qa="start-replaying"
                className="retake-button btn btn--secondary"
                disabled
              >
                Retake
                <i className="ml-2 i-video-camera" />
              </button>
              <button
                type="button"
                className="upload-button btn btn--primary"
                disabled
              >
                Loading
                <div>
                  <div className="absolute top-0 left-0 w-full h-full flex justify-center items-center">
                    <LoadingBalls isActive />
                  </div>
                </div>
              </button>
            </div>
          ) : (
            <div>
              <button
                type="button"
                onClick={onStopReplaying}
                data-qa="start-replaying"
                className="retake-button btn btn--secondary"
              >
                Retake
                <i className="ml-2 i-video-camera" />
              </button>
              <button
                type="button"
                onClick={upload}
                className="upload-button btn btn--primary"
              >
                Send
                <i className="ml-2 i-arrow-right" />
              </button>
            </div>
          )}
        </>
      );
    }

    if (isRecording) {
      return (
        <div>
          {/* <div className="w-full flex justify-center"> */}
          {/* <div className="rec-icon" /> */}
          <div className="timer-background">
            <div className="text-right flex flex-row justify-center mb-6">
              <Timer timeLimit={timeLimit} />
            </div>
          </div>
          <div className="rec-button-stop-boarder">
            <button
              type="button"
              onClick={onStopRecording}
              data-qa="stop-recording"
              className="rec-button-stop"
            >
              {/* STOP */}
            </button>
          </div>
        </div>
      );
    }

    if (isCameraOn && streamIsReady) {
      return (
        <div>
          <div className="instructions">
            PRESS&nbsp;
            <p className="text-red-900"> REC </p>
            &nbsp;WHEN READY
          </div>
          <div className="button-container">
            <div className="rec-button-boarder">
              <button
                type="button"
                onClick={onStartRecording}
                data-qa="start-recording"
                className="rec-button-record"
              >
                {/* <i className="i-video-camera" /> */}
              </button>
            </div>
          </div>
        </div>
      );
    }

    if (useVideoInput) {
      return (
        <Button
          color="primary"
          type="button"
          onClick={onOpenVideoInput}
          data-qa="open-input"
          text="Upload a video"
          className=""
        />
      );
    }

    return shouldUseVideoInput ? (
      <Button
        type="button"
        color="primary"
        onClick={onOpenVideoInput}
        data-qa="open-input"
        text="Record a video"
        className=""
      />
    ) : null;
    // ) : (
    //   <button
    //     type="button"
    //     onClick={onTurnOnCamera}
    //     data-qa="turn-on-camera"
    //     className="w-24 h-24 mr-2 text-2xl rounded-full bg-red-900"
    //   >
    //     <i className="i-video-camera-off" />
    //   </button>
    // );
  };

  return isRunningCountdown ? (
    <div className="relative bottom-0 h-full mb-24 flex items-center justify-center">
      {renderContent()}
    </div>
  ) : (
    <div className="absolute bottom-0 left-0 w-full mb-24 flex flex-wrap items-center justify-center">
      {renderContent()}
    </div>
  );
};

const VideoRecorder = ({
  modalTitle = 'Video Recorder',
  onVideoUploaded = undefined,
  onVideoChanged = undefined,
  className = '',
  clearOnUpload = true,
  closeOnUpload = true,
  transcribe = false,
  recordLimit = 60 * 1000, // Default time limit of 60 seconds (in ms)
  uploadLimit = 90 * 1000, // Default upload limit of 90 seconds (in sec)
}: {
  modalTitle?: string;
  className?: string;
  onVideoUploaded?: (video: any) => any;
  onVideoChanged?: (video: any) => any;
  clearOnUpload?: boolean;
  closeOnUpload?: boolean;
  transcribe?: boolean;
  recordLimit?: number;
  uploadLimit?: number;
}) => {
  const [loading, setLoading] = useState(false);
  const [showRecorder, setShowRecorder] = useState(false);
  const [videoBlob, setVideoBlob] = useState<Blob>(null!);
  const [videoDuration, setVideoDuration] = useState(0);

  const upload = () => {
    if (!videoBlob || loading) return;

    if (videoDuration > uploadLimit) {
      addError(`Error, cannot upload videos longer than ${uploadLimit} sec.`);
      return;
    }

    setLoading(true);

    const formData = new FormData();
    formData.append('video', videoBlob);
    formData.append('transcribe', JSON.stringify(transcribe));

    Api.post('/util/upload-video', formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
      .then((res: any) => {
        if (onVideoUploaded) {
          onVideoUploaded(res);
        }

        if (onVideoChanged) {
          onVideoChanged(res);
        }

        if (clearOnUpload) {
          setVideoBlob(null!);
        }

        if (closeOnUpload) {
          setShowRecorder(false);
        }
      })
      .catch(addResponseError)
      .finally(() => {
        setLoading(false);
      });
  };

  const ModalCloseButton = () => {
    const { closeModal } = useModal();

    const handleClick = useCallback(() => {
      closeModal();
    }, [closeModal]);

    return (
      <div className="absolute top-0 right-0">
        <button
          type="button"
          className="text-grey-400 p-4"
          onClick={handleClick}
        >
          <i className="i-close" />
        </button>
      </div>
    );
  };

  const recordingComplete = (
    blob: Blob,
    startedAt: number,
    thumbnailBlob: Blob,
    duration: number
  ) => {
    setVideoBlob(blob);
    setVideoDuration(duration);
  };

  const recordingDeleted = () => {
    setVideoBlob(null!);
  };

  return (
    <>
      <div className={className}>
        <Button className="chat-submit" onClick={() => setShowRecorder(true)}>
          <i className="i-video-camera" />
        </Button>
      </div>
      {showRecorder && (
        <Modal
          title={modalTitle}
          isOpen={showRecorder}
          onClose={() => {
            setVideoBlob(null!);
            setShowRecorder(false);
          }}
          width={isMobile ? '2xl' : '6xl'}
          fullHeight
          showHeader={false}
        >
          <>
            <div className="video-recorder-container">
              <ReactVideoRecorder
                showReplayControls
                isOnInitially
                timeLimit={recordLimit}
                constraints={{
                  audio: true,
                  video: true,
                }}
                onRecordingComplete={recordingComplete}
                onStopReplaying={recordingDeleted}
                renderActions={(props: any) => {
                  return (
                    <Actions upload={upload} isLoading={loading} {...props} />
                  );
                }}
              />
            </div>
            <ModalCloseButton />
          </>
        </Modal>
      )}
    </>
  );
};

export default VideoRecorder;
