import React, { useState, useMemo, useContext } from 'react';
import { useForm } from 'react-hook-form';
import deepEqual from 'fast-deep-equal';
import { LoadingBalls } from '../../core/Loading';
import { Api } from '../../../Api';
import { FormActions } from '../../core/form';
import { Roles, Skills, Industries } from '../formElements';
import { SubmitButton } from '../../core/Button';
import { addSuccess } from '../../../services/Messaging';
import UserContext from '../../user/Context';

interface Props {
  membership: MembershipEntity;
  refresh: Function;
}

const RolesForm = ({ membership, refresh }: Props) => {
  const [loading, setLoading] = useState(false);
  const { isRoot } = useContext(UserContext);
  const form = useForm({
    mode: 'onBlur',
    shouldFocusError: true,
    defaultValues: {},
  });
  const { handleSubmit } = form;
  const { roles, skills, industries, plan } = membership;
  const planType = membership.plan.type;

  const defaultRoles = useMemo(() => {
    return roles.map(({ role }: MembershipRole) => ({
      value: role.id,
      label: role.name,
    }));
  }, [roles]);

  const defaultSkills = useMemo(() => {
    return skills.map(({ skill }: MembershipSkill) => ({
      value: skill.id,
      label: skill.name,
    }));
  }, [skills]);

  const defaultIndustries = useMemo(() => {
    return industries.map(({ industry }: MembershipIndustry) => ({
      value: industry.id,
      label: industry.name,
    }));
  }, [industries]);

  const onSubmit = async (values: any) => {
    if (loading) return;
    setLoading(true);

    const relations: {
      relationship: 'roles' | 'skills' | 'industries';
      entity: any;
    }[] = [
      {
        relationship: 'roles',
        entity: 'role',
      },
      {
        relationship: 'skills',
        entity: 'skill',
      },
      {
        relationship: 'industries',
        entity: 'industry',
      },
    ];

    const requests: any[] = [];
    relations.forEach(relation => {
      // Dont send roles for brands
      if (relation.relationship === 'roles' && planType === 'brand') {
        return;
      }

      const { relationship, entity } = relation;
      const entities: any[] = membership[relationship] ?? [];

      const existingIds = entities.map((assoc: any) => assoc[entity].id);
      const ids = Array.isArray(values[relationship])
        ? values[relationship].map(({ value }: { value: string }) => value)
        : [values[relationship]?.value || null];

      if (!deepEqual(existingIds, ids)) {
        requests.push(
          Api.put(`/membership/${membership.id}/${entity}`, { items: ids })
        );
      }
    });
    await Promise.all(requests);

    refresh();
    addSuccess('Membership updated!');
    setLoading(false);
  };

  const numMembershipIndustries = plan ? plan.numMembershipIndustries : 0;

  return (
    <>
      <LoadingBalls isActive={loading} fullscreen />
      <form onSubmit={handleSubmit(onSubmit)} noValidate>
        {planType !== 'brand' ? (
          <Roles
            useMembershipNames
            upgradePath="numMembershipRoles"
            form={form}
            defaultValues={defaultRoles}
            max={isRoot ? undefined : plan?.numMembershipRoles || 0}
          />
        ) : null}
        <Skills form={form} defaultValues={defaultSkills} />
        <Industries
          upgradePath="numMembershipIndustries"
          form={form}
          defaultValues={defaultIndustries}
          max={isRoot ? undefined : numMembershipIndustries}
        />
        <FormActions className="mt-auto justify-center">
          <div className="md:ml-auto">
            <SubmitButton text="Save Changes" />
          </div>
        </FormActions>
      </form>
    </>
  );
};

export default RolesForm;
