import useAsyncEffect from 'use-async-effect';
import shallow from 'zustand/shallow';
import { isUser } from '../../../@types/chat.d';
import userStoreCreator, { ChatUsersState } from '../state/ChatUserState';
import useChatClient from './useChatClient';
import { addError } from '../../../services/Messaging';

/**
 * useChatUser
 *
 * Hook to return a Twilio User object for the given `identity`.
 * This hook will load the User if it hasn't been loaded yet.
 */
const useChatUser = (identity: string) => {
  const { clientInstance } = useChatClient();
  const [userStore] = userStoreCreator(identity);

  const [user, loaded, loading, loadingUser, addUser] = userStore(
    (state: ChatUsersState) => [
      state.user,
      state.loaded,
      state.loading,
      state.loadingUser,
      state.addUser,
    ],
    shallow
  );

  useAsyncEffect(async () => {
    if (!clientInstance || loading || loaded) return;

    loadingUser();

    try {
      const twilioUser = await clientInstance.getUser(identity);

      if (isUser(twilioUser)) {
        addUser(twilioUser);
      }
    } catch (error) {
      addError(error.body.message);
    }
  }, [identity, clientInstance, user, loadingUser, addUser]);

  return {
    user,
    loading,
    loaded,
  };
};

export default useChatUser;
