import { ChannelInteraction } from '../../@types/chat.d';
import { Api, BaseAPI } from '../../Api';

const customApi = {
  /**
   * Returns all the favors the user is a part of
   */
  listMine: (): Promise<ChannelInteraction[]> => Api.get(`/favor/mine`),
  interact: (favorId: TCardId, interaction: string, payload?: object) =>
    Api.post(`/favor/${favorId}/interact`, {
      type: interaction,
      ...payload,
    }),
  share: (favorId: TCardId, recipients: string[]) =>
    Api.post(`/favor/${favorId}/share`, {
      recipients,
    }),
  latest: () => Api.get(`/favor/latest`),
  popular: () => Api.get(`/favor/popular`),
  addAdminToChannel: ({ channelId, userId }: TFavorInteractionVariables) => {
    return Api.post(`/favor/${channelId}/admin/add`, { userId });
  },
  revertChannelAdminToParticipant: ({
    channelId,
    userId,
  }: TFavorInteractionVariables) => {
    return Api.post(`/favor/${channelId}/admin/revert`, { userId });
  },
  toggleChannelState: ({ channelId }: { channelId: string }) => {
    return Api.put(`/favor/${channelId}/toggle-state`);
  },
  blockMember: ({ channelId, userId }: TFavorInteractionVariables) => {
    return Api.patch(`/favor/${channelId}/member/${userId}/interaction`, {
      type: 'blocked',
    });
  },
  /**
   * Returns all the blocked members from a channel
   *
   * @param favorId - the channel id
   * @returns an array blocked members
   */
  getBlockedMembers: (favorId: string) => Api.get(`/favor/${favorId}/blocked`),

  /**
   * Updates a user's favor_interaction state to that of an unblocked state
   *
   * @param object.channelId - the channel whose user needs to be unblocked
   * @param object.userId - the user that needs to be unblocked
   */
  unblockMember: ({ channelId, userId }: TFavorInteractionVariables) => {
    return Api.patch(`/favor/${channelId}/member/${userId}/interaction`, {
      action: 'unblock',
    });
  },
  removeChannelParticipant: ({
    channelId,
    userId,
  }: TFavorInteractionVariables) => {
    return Api.delete(`/favor/${channelId}/member/${userId}`);
  },
};

const favorApi = { ...BaseAPI('favor'), ...customApi };

export default favorApi;
