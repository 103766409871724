/* eslint-disable react/jsx-props-no-spreading */
import React, { useContext } from 'react';
import UserContext, { UserProvider } from './Context';
import { ModalRoute, HashRoute } from './Routes';
import { Login, Signup, ResetPassword } from './views';
import EditProfile from './views/EditProfile';
import EditPage from '../membership/EditForm';
import { Become, Settings } from './views/account';
import useAnalytics from '../../hooks/useAnalytics';
import Capitalize from '../../utilities/Capitalize';
import { isMobile } from '../../utilities/Device';
import DirectUpgradeFlow from '../featureGate/DirectUpgradeFlow';
import ForceUpgradeFlow from '../featureGate/ForceUpgradeFlow';

type AppProps = {
  children: any;
};

export default (props: AppProps) => {
  const { children } = props;
  return (
    <UserProvider>
      <>
        {children}
        <UserRoutes />
      </>
    </UserProvider>
  );
};

const RootRoutes = () => {
  const { isRoot } = useContext(UserContext);

  if (!isRoot) return null;

  return (
    <ModalRoute
      hash="#/auth/become"
      title="Become Member"
      component={Become}
      reloadOnDismiss
      height="80vh"
    />
  );
};

export const UserRoutes = () => {
  const { page } = useAnalytics();
  const { isAuthenticated } = useContext(UserContext);

  return (
    <>
      <ModalRoute
        hash="#/(login|signup)"
        title=""
        component={React.memo(() => (
          <>
            <HashRoute hash="#/login" component={Login} />
            <HashRoute hash="#/signup" component={Signup} />
          </>
        ))}
      />
      {/* <ModalRoute hash="#/login" title="" component={Login} /> */}
      {/* <ModalRoute hash="#/signup" title="" component={Signup} /> */}
      {/* <ModalRoute hash="#/signup/terms" title="Terms of Use" component={Terms} /> */}
      {/* <ModalRoute hash="#/auth/forgot-password" component={ForgotPassword} /> */}
      <ModalRoute
        hash="#/auth/reset-password/:passwordResetCode"
        title="Reset Password"
        component={ResetPassword}
      />
      {isAuthenticated && (
        <>
          <HashRoute
            hash="#/upgrade-flow/:permission?"
            component={ForceUpgradeFlow}
          />
          <HashRoute
            hash="#/upgrade/:slug?/:coupon?"
            component={DirectUpgradeFlow}
          />
          <ModalRoute
            hash="#/edit-profile/:form?/:action?/:projectId?"
            title="Edit Profile"
            component={EditProfile}
            height="80vh"
            fullHeight={isMobile}
          />
          <ModalRoute
            hash="#/edit-page/:form?/:action?/:projectId?"
            title="Edit Page"
            component={EditPage}
            height="80vh"
            fullHeight={isMobile}
          />
          <ModalRoute
            hash="#/settings/:form?"
            title="My Account"
            component={(props: any) => {
              page(`${Capitalize(props.form)} Settings`);
              return <Settings {...props} />;
            }}
            height="80vh"
            fullHeight={isMobile}
            reloadOnDismiss
          />
          <RootRoutes />
        </>
      )}
    </>
  );
};
