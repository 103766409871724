import { useQueryClient } from '@tanstack/react-query';
import React, { useState } from 'react';
import useAsyncEffect from 'use-async-effect';
import InlineConversation from '../chat/components/InlineConversation';
import { LoadingBalls } from '../core/Loading';
import favorApi from './Api';

type JoinChannelProps = {
  cardId: TCardId;
  onConsent?: React.Dispatch<React.SetStateAction<boolean>>;
  onClosePane?: () => void;
};

const JoinChannel = ({ cardId, onConsent, onClosePane }: JoinChannelProps) => {
  const [isLoading, setIsLoading] = useState(true);
  const [conversationId, setConversationId] = useState('');
  const queryClient = useQueryClient();

  const Loading = () => (
    <div className="py-4">
      <LoadingBalls isActive />
    </div>
  );

  useAsyncEffect(async () => {
    await favorApi
      .interact(cardId, 'offered', {
        visibility: 'public',
      })
      .then(res => {
        setConversationId(res.conversationId);
      })
      .then(() => queryClient.invalidateQueries(['channels']))
      .catch(() => {});
    setIsLoading(false);
  }, []);

  return (
    <>
      {!isLoading && conversationId ? (
        <InlineConversation
          channelsLook
          onMessageSent={onConsent}
          sid={conversationId}
          onClosePane={onClosePane}
        />
      ) : (
        <Loading />
      )}
    </>
  );
};

export default JoinChannel;
