import React from 'react';
import environment from '../../environment';
import { addError, addSuccess } from '../../services/Messaging';
import InAppShareable from './InAppShareable';

type Props = {
  work: WorkEntity;
  user: string | undefined;
  onlyInvite?: boolean;
};

const copyToClipboard = async (str: string) => {
  try {
    await navigator.clipboard.writeText(str);
    addSuccess(`Link copied to clipboard.`);
  } catch (err) {
    addError('Failed to copy: ', err);
  }
};

const inAppSent = async (err: MessageEventData | undefined = undefined) => {
  if (err) {
    addError('Failed to send: ', err);
    return;
  }

  addSuccess(`Link sent to Communo member.`);
};

const ShareableLinks: React.FC<Props> = ({
  work,
  user,
  onlyInvite = false,
}) => {
  const userId = user ?? 'public';
  const workPost = `${environment.appUrl}/work/${work.id}?t=${userId}:lk`;
  const encodedLink = encodeURIComponent(`${work.id}?t=${userId}:li`);
  return (
    <div>
      <InAppShareable
        shareUrl={workPost}
        onComplete={inAppSent}
        work={work}
        user={user}
        onlyInvite={onlyInvite}
      />

      {!onlyInvite && (
        <>
          <hr className="mb-0" />
          <button
            type="button"
            className="flex flex-row items-center space-x-4 py-4 w-full"
            onClick={() => {
              window.open(
                `https://www.linkedin.com/shareArticle?mini=true&url=${environment.appUrl}/work/${encodedLink}`,
                'linkedinwindow',
                'left=20,top=20,width=600,height=700,toolbar=0,resizable=1'
              );
            }}
          >
            <div className="flex items-center justify-center bg-red-900 p-2 rounded-md">
              <i className="i-linkedin text-2xl text-white" />
            </div>
            <p className="text-lg-font-semibold">LinkedIn</p>
          </button>
          <hr className="my-0" />
          <button
            type="button"
            className="flex flex-row items-center space-x-4 py-4 w-full"
            onClick={() => {
              window.open(
                `https://twitter.com/intent/tweet?text=${environment.appUrl}/work/${work.id}?t=${userId}:tw`,
                'twitterwindow',
                'left=20,top=20,width=600,height=700,toolbar=0,resizable=1'
              );
            }}
          >
            <div className="flex items-center justify-center bg-red-900 p-2 rounded-md">
              <i className="i-twitter text-2xl text-white" />
            </div>
            <p className="text-lg-font-semibold ">Twitter</p>
          </button>
          <hr className="my-0" />
          <button
            type="button"
            className="flex flex-row items-center space-x-4 py-4 w-full"
            onClick={() => {
              window.open(
                `https://www.facebook.com/sharer/sharer.php?u=${environment.appUrl}/work/${work.id}?t=${userId}:fb`,
                'facebookwindow',
                'left=20,top=20,width=600,height=700,toolbar=0,resizable=1'
              );
            }}
          >
            <div className="flex items-center justify-center bg-red-900 p-2 rounded-md">
              <i className="i-facebook text-2xl text-white" />
            </div>
            <p className="text-lg-font-semibold">Facebook</p>
          </button>
          <hr className="my-0" />
          <button
            type="button"
            className="flex flex-row items-center space-x-4 py-4 w-full"
            onClick={() => {
              copyToClipboard(
                `${environment.appUrl}/work/${work.id}?t=${userId}:lk`
              );
            }}
          >
            <div className="flex items-center justify-center bg-red-900 p-2 rounded-md">
              <i className="i-link text-2xl text-white" />
            </div>
            <p className="text-lg-font-semibold">Copy Link</p>
          </button>
          <hr className="mt-0" />
        </>
      )}
    </div>
  );
};

export default ShareableLinks;
