import { useQuery } from '@tanstack/react-query';
import * as Sentry from '@sentry/react';
import { keyBy } from 'lodash';
import useChatChannelInstance from './useChatChannelInstance';
import { IMember } from '../../../@types/chat.d';
import { addError } from '../../../services/Messaging';

type Member = {
  [identity: string]: IMember;
};

/**
 * Fetches the particpants of a conversation with a given sid
 *
 * This hook creates a participant object whose key is the participant's identity
 *
 * @example of a returned channel member
 *  {
 *    unique-twilio-participant-id: {
 *      roleSid: "...",
 *      sid: "...",
 *      identity: "...",
 *      ...
 *    }
 *  }
 * @param sid - the channel sid whose members need to be fetched
 */
const useChatChannelMembers = (sid: string) => {
  const { channelInstance } = useChatChannelInstance(sid);

  const participants = useQuery(
    ['members', sid],
    () => channelInstance.getParticipants(),
    {
      onError: err => {
        Sentry.captureException(err);
        addError(`We're having difficulties fetching the channel's members`);
      },
      initialData: [],
    }
  );

  return {
    channelMembers: keyBy(participants.data, 'identity') as Member,
    loaded: participants.isSuccess,
    loading: participants.isLoading,
  };
};

export default useChatChannelMembers;
