import development from './development';
import { Environment } from '.';

const localEnvironment: Environment = {
  ...development,
  appUrl: 'http://localhost:3000',
  apiUrl: 'http://localhost:8080',
  algoliaPrefix: 'dev',
  chatRoles: {
    RL853971dea89d49e1a8f1ad3fbadf21a6: {
      type: 'channelAdmin',
      label: 'Admin',
    },
    RL125ef65c6274479a98bef7740d5705bb: {
      type: 'channelMember',
      label: 'Member',
    },
    RL61b10baba9034f1aa70b4fd1080ca7c7: {
      type: 'serviceAdmin',
      label: 'Service Admin',
    },
    RLab826e36665341f7b6924612f62bd78a: {
      type: 'serviceUser',
      label: 'User',
    },
  },
};

export default localEnvironment;
