/* eslint-disable react/destructuring-assignment */
import React from 'react';

type Props = {
  timeLimit: number;
  defaultText?: string;
};

type State = {
  seconds: any | null;
  human: any | null;
};

class Timer extends React.Component<Props, State> {
  timer: any;

  constructor(props: Props) {
    super(props);

    const nextSeconds = props.timeLimit ? props.timeLimit / 1000 : 0;

    this.state = this.getState(nextSeconds);
  }

  componentDidMount() {
    const { timeLimit } = this.props;
    this.timer = setInterval(() => {
      const { seconds } = this.state;
      const nextSeconds = timeLimit ? seconds - 1 : seconds + 1;

      const nextState = this.getState(nextSeconds);
      this.setState(nextState);
    }, 1000);
  }

  componentWillUnmount() {
    clearInterval(this.timer);
  }

  getState(seconds: any) {
    const minutes = Math.floor(seconds / 60);

    const humanTime =
      minutes !== 0
        ? `${minutes}:${this.pad(seconds - minutes * 60)}`
        : `${seconds - minutes * 60}s`;

    return {
      seconds,
      human: humanTime,
    };
  }

  // eslint-disable-next-line class-methods-use-this
  pad(unit: any) {
    const str = `${unit}`;
    const pad = '00';
    return pad.substring(0, pad.length - str.length) + str;
  }

  render() {
    const defaultText = this.props.defaultText || '0:00';
    return (
      <div className="timer" {...this.props}>
        {/* <div className="rec-icon" /> */}
        {this.state.human || defaultText}
      </div>
    );
  }
}

export default Timer;
