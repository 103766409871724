import React from 'react';
import useAnalytics from '../../hooks/useAnalytics';
import useCurrentMembership from '../../hooks/useCurrentMembership';
import useFeatureGate from '../../hooks/useFeatureGate';
import useMyWork from '../../hooks/useMyWork';
import { addResponseError, addSuccess } from '../../services/Messaging';
import Button from '../core/Button';
import Api from './Api';
import { WorkAudience } from './utils/workAudience';

const UpgradeJobButton = ({
  work,
  loading,
  refreshWork,
  trackingEventPrefix,
  width = 'md:w-auto',
}: {
  work: WorkEntity | null;
  loading: boolean;
  refreshWork: Function;
  trackingEventPrefix: string;
  width?: string;
}) => {
  const { openUpgradeFlowFor } = useFeatureGate();
  const { track } = useAnalytics();
  const { checkAccess, refresh: refreshMembership } = useCurrentMembership();
  const { refresh: refreshMyWork } = useMyWork();

  const canPostWork = checkAccess('canPostWorkToCommuno');

  return (
    <>
      <div className="flex flex-col text-center md:ml-auto whitespace-nowrap">
        {!loading && work ? (
          <Button
            className={`btn btn--sm btn--primary btn--outline ${width}`}
            onClick={async () => {
              // Update the audience if allowed
              if (canPostWork) {
                track(`${trackingEventPrefix} - Publish Work Clicked`);
                const newWork = {
                  ...work,
                  audience: WorkAudience.BOTH,
                  collections: [],
                  collectionIds: [],
                  expiresAt: undefined, // Setting to undefined allows the server to extend by the correct amount
                };
                try {
                  // NOTE: The server handler will decrement the number of paid posts available if
                  // necessary.
                  await Api.update(work.id, newWork);
                  await refreshWork();
                  refreshMyWork();

                  // Refresh the membership because our P2P amount could have dropped
                  await refreshMembership();
                  addSuccess('Post is now live!');
                } catch (error) {
                  addResponseError(error as ResponseError);
                }

                return;
              }

              track(`${trackingEventPrefix} - Get Applicants Clicked`);
              // Otherwise start upgrade payment flow
              openUpgradeFlowFor('canPostWorkToCommuno');
            }}
          >
            {canPostWork ? 'Publish' : 'Get Applicants'}
          </Button>
        ) : null}
      </div>
    </>
  );
};

export default UpgradeJobButton;
