/* eslint-disable no-console */
import { useCallback } from 'react';
import useChatClient from './useChatClient';
import useChatChannels from './useChatChannels';
import {
  isMember,
  TwilioConversationAttributes,
  IChannel,
} from '../../../@types/chat.d';
import loadChannelMembersToState from '../services/loadChannelMembersToState';
import { select } from '../instances/ChannelInstances';

type CreateChannelProps = {
  identities: string[];
  friendlyName?: string;
  attributes?: TwilioConversationAttributes;
  message?: string;
  awaitConsumed?: boolean;
};

/**
 * useChatServices
 *
 * Returns chat related functions
 */
const useChatServices = () => {
  const { loaded, clientInstance } = useChatClient();
  const { channels } = useChatChannels();

  const createChannel = useCallback(
    async ({
      identities,
      friendlyName,
      attributes = {
        type: 'direct',
      } as TwilioConversationAttributes,
      message,
      awaitConsumed = false,
    }: CreateChannelProps) => {
      if (!clientInstance) throw new Error('no chat client instance');
      if (!identities) throw new Error('Must provide member identities.');

      const channel = await clientInstance
        .createConversation({
          friendlyName,
          ...attributes,
        })
        .catch(() => {
          throw new Error('An error occurred while creating a channel');
        });

      await channel.join();

      await Promise.allSettled(
        identities.map(identity => channel.add(identity))
      ).catch(() => {
        throw new Error(`Could not add member to chat`);
      });

      if (message) {
        if (!awaitConsumed) {
          channel.sendMessage(message);
          channel.setAllMessagesRead();
        } else {
          await channel.sendMessage(message);
          await channel.setAllMessagesRead();
        }
      }

      return (channel as IChannel) ?? ({} as IChannel);
    },
    [clientInstance]
  );

  const checkForExistingChannel = useCallback(
    async (identity: string) => {
      const directChannels = channels.filter(
        channel => channel.attributes?.type === 'direct'
      );

      if (directChannels.length) {
        // eslint-disable-next-line no-restricted-syntax
        for (const channel of directChannels) {
          const channelInstance = select(channel.sid);
          // eslint-disable-next-line no-await-in-loop
          const members = await loadChannelMembersToState(channelInstance);

          if (members) {
            const mappedMembers = members.filter(x => isMember(x));

            const matchedMember = mappedMembers.find(
              m => m.identity === identity
            );

            if (matchedMember) return channelInstance;
          }
        }
      }

      return false;
    },
    [channels]
  );

  return {
    loaded,
    checkForExistingChannel,
    createChannel,
  };
};

export default useChatServices;
