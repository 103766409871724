import userStoreCreator from '../state/ChatUserState';
import { IMember, isUser } from '../../../@types/chat.d';
import UserInstances from '../instances/UserInstances';

const loadMembersUserToState = (members: IMember[]) => {
  Promise.all(
    members.map(async member => {
      const [, userApi] = userStoreCreator(member.identity);
      const { user: existingUser, addUser, loadingUser } = userApi.getState();

      if (existingUser) {
        const userInstance = UserInstances.select(existingUser.identity);
        return Promise.resolve(userInstance);
      }

      loadingUser();
      const user = await member.getUser();

      if (isUser(user)) {
        addUser(user);
      }

      return user;
    })
  );
};

export default loadMembersUserToState;
