import React from 'react';
import GenerosityView from '../components/generosity/View';
import View, { ViewContent } from '../components/core/View';
import Header from '../components/core/Header';
import { isMobile } from '../utilities/Device';

const Generosity = () => {
  return (
    <View className="" authGuard pageTitle="Channels">
      {!isMobile ? <Header showAuthLinks /> : null}
      <ViewContent layout="generosity" className="page--generosity">
        <GenerosityView />
      </ViewContent>
    </View>
  );
};

export default Generosity;
