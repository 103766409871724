import React, { useCallback, useContext } from 'react';
import {
  Draggable,
  /* eslint-disable no-unused-vars */
  DroppableStateSnapshot,
  DroppableProvided,
  DraggableProvided,
  /* eslint-enable no-unused-vars */
} from 'react-beautiful-dnd';

import capitalize from '../../utilities/Capitalize';
import ColorUtil from '../../utilities/ColorUtil';
import { statusColor, statusLabel } from '../../utilities/ApplicationStatus';
import WorkCandidateCard from './WorkCandidateCard';
import ExportCandidatesToList from './ExportCandidatesToList';
import UserContext from '../user/Context';

type Props = {
  status: ApplicationStatus;
  applications: ApplicationEntity[];
  dropProvided: DroppableProvided;
  dropSnapshot: DroppableStateSnapshot;
};

const Applications = ({
  applications,
  status,
}: {
  applications: ApplicationEntity[];
  status: ApplicationStatus;
}) => {
  const { isPublic, publicMagicKey } = useContext(UserContext);
  const WorkCandidateCardCallback = useCallback(
    application => (
      <WorkCandidateCard
        application={application}
        to={`?=applicationId=${application.id}${
          isPublic && publicMagicKey ? `&=magicKey=${publicMagicKey}` : ''
        }`}
      />
    ),
    [isPublic, publicMagicKey]
  );

  return (
    <>
      {applications.map((application, index) =>
        // Make card non-draggable if application is 'awarded'
        application.status === 'awarded' ? (
          <div
            key={application.id}
            className={`mb-4 ${status === 'passed' ? 'grayscale' : ''}`}
          >
            {WorkCandidateCardCallback(application)}
          </div>
        ) : (
          <Draggable
            key={application.id}
            draggableId={application.id}
            index={index}
          >
            {(dragProvided: DraggableProvided) => {
              return (
                <div
                  ref={dragProvided.innerRef}
                  className={`mb-4 ${status === 'passed' ? 'grayscale' : ''}`}
                  style={dragProvided.draggableProps.style}
                  /* eslint-disable react/jsx-props-no-spreading */
                  {...dragProvided.dragHandleProps}
                  {...dragProvided.draggableProps}
                  /* eslint-enable react/jsx-props-no-spreading */
                >
                  {WorkCandidateCardCallback(application)}
                </div>
              );
            }}
          </Draggable>
        )
      )}
    </>
  );
};

export default ({
  status,
  applications,
  dropProvided,
  dropSnapshot,
}: Props) => {
  const color = statusColor(status);
  const getStyle = useCallback(() => {
    if (!dropSnapshot.isDraggingOver) {
      return {};
    }

    return {
      backgroundColor: ColorUtil.hexToRGB(color, 0.4),
    };
  }, [dropSnapshot.isDraggingOver, color]);

  const userIds = applications.map(application => application.user.id);

  return (
    <div
      className="min-h-[100px] bg-white p-0 overflow-y-auto flex flex-col disable-scrollbars"
      style={getStyle()}
    >
      <div className="bg-white sticky top-0 z-10 p-4 pb-2 mb-2">
        <div className="flex flex-row flex-wrap items-center">
          <div className="font-bold flex-1 text-lg">
            {capitalize(statusLabel(status))}
          </div>

          <div>
            <ExportCandidatesToList userIds={userIds} />
            <span
              className="w-8 h-8 inline-flex items-center justify-center rounded-lg text-white text-sm"
              style={{ background: color }}
            >
              {applications.length}
            </span>
          </div>
        </div>
      </div>

      <div ref={dropProvided.innerRef} className="px-4 grow">
        <Applications status={status} applications={applications} />
        {dropProvided.placeholder}
      </div>
    </div>
  );
};
