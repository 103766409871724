import React from 'react';
import { mediaClasses as mc } from '../../utilities/MatchMedia';
import ChatButton from '../chat/components/ChatButton';
import UserProfilePhoto from './UserProfilePhoto';
import useCurrentUser from '../../hooks/useCurrentUser';
import VettedBadge from './VettedBadge';
import useForceMobile from '../../hooks/useForceMobile';
import ButtonLink from '../core/ButtonLink';
import ProfileRate from './ProfileRate';
import ProfileTimeDifference from './ProfileTimeDifference';
import VerifiedBadge from './VerifiedBadge';
import useShowFullProfile from '../../hooks/useShowFullProfile';

type Props = {
  user: UserEntity;
};

const FreelancerEntityColumn = ({ user }: Props) => {
  const { id: authUserId } = useCurrentUser();
  const forceMobile = useForceMobile();
  const showFullProfile = useShowFullProfile();

  const membership = user.primaryMembershipUser?.membership;
  const userName = `${user.firstName ?? ''} ${user.lastName ?? ''}`;
  let badge = null;

  if (
    membership?.vettingStatus === 'verified' ||
    membership?.vettingStatus === 'pending'
  ) {
    badge = <VerifiedBadge />;
  }

  if (membership?.vettingStatus === 'vetted') {
    badge = <VettedBadge />;
  }

  if (!user || !membership) {
    return null;
  }

  return (
    <div className="flex flex-col">
      <UserProfilePhoto
        userId={user.id}
        url={user.avatarURL}
        altText={userName}
        badge={badge}
      />
      <div className="flex flex-col mt-6 gap-px">
        <h4>{userName}</h4>
        <p>{user.title}</p>
        {user.location?.friendlyName ? (
          <p>{user.location?.friendlyName}</p>
        ) : null}
      </div>

      <div className="border border-grey-200 flex mt-4 p-4 self-center gap-6 items-center justify-around w-full">
        <ProfileTimeDifference user={user} />
        <ProfileRate user={user} />
      </div>

      {user.id !== authUserId && !forceMobile ? (
        <ChatButton
          className={mc('mt-8 btn--sm 2xl:btn--md', forceMobile)}
          identity={user.id}
          upgradeLink="#/upgrade"
        />
      ) : null}
      {forceMobile && showFullProfile ? (
        <ButtonLink
          color="primary"
          size="sm"
          href={`/members/user/${user.id}`}
          text="View Full Profile"
          className="mt-6 w-full"
        />
      ) : null}
    </div>
  );
};
export default FreelancerEntityColumn;
