import pluralize from 'pluralize';
import React from 'react';
import ListItemBullet from '../core/ListItemBullet';

interface Props {
  plan: PlanEntity;
}

// NOTE: null is unlimited
const UNLIMITED = 100;

const PlanFeatureList = ({ plan }: Props) => {
  const {
    canApplyToCommunoWork,
    canPostWorkToCommuno,
    canCreateChannels,
    benchSizeLimit,
    canAddToBench,
    canClickSocialLinks,
    canChatToIndividuals,
    canChatToAgencies,
    canChatToBusinesses,
    numSeats,
    isIndividualPlan,
  } = plan;
  const numRoles = plan.isIndividualPlan
    ? plan.numUserRoles
    : plan.numMembershipRoles;
  const numIndustries = plan.isIndividualPlan
    ? plan.numUserIndustries
    : plan.numMembershipIndustries;
  const unlimitedBench = benchSizeLimit === null;
  let canChatTo = [];

  if (canChatToIndividuals) {
    canChatTo.push('Freelancers');
  }

  if (canChatToAgencies) {
    canChatTo.push('and Agencies');
  }

  if (canChatToBusinesses) {
    canChatTo = ['everyone'];
  }

  return (
    <>
      {!isIndividualPlan ? (
        <ListItemBullet
          text={`${numSeats} team ${pluralize(
            'member',
            numSeats || UNLIMITED
          )} seats`}
        />
      ) : (
        <ListItemBullet text="Verified Badge" />
      )}
      {numRoles > 0 ? (
        <ListItemBullet
          text={`Add ${numRoles} ${pluralize(
            'role',
            numRoles || UNLIMITED
          )} to your profile`}
        />
      ) : null}
      {canApplyToCommunoWork ? (
        <ListItemBullet text="Apply to unlimited jobs through Communo work" />
      ) : null}
      {/* <li>Unlimited work applications</li> */}
      {canPostWorkToCommuno ? (
        <ListItemBullet text="Post jobs to Communo Work" />
      ) : null}
      {unlimitedBench ? <ListItemBullet text="Unlimited bench" /> : null}
      {canAddToBench ? <ListItemBullet text="Add members to bench" /> : null}
      {numIndustries === null ? (
        <ListItemBullet text="Add additional industries to your profile" />
      ) : null}
      {canChatTo.length > 0 ? (
        <ListItemBullet text={`Chat with ${canChatTo.join(' ')}`} />
      ) : null}
      {canCreateChannels ? (
        <ListItemBullet text="Create new ‘channels’ to discuss topics important to you" />
      ) : null}
      {canClickSocialLinks ? (
        <ListItemBullet text="Access social links" />
      ) : null}
    </>
  );
};
export default PlanFeatureList;
