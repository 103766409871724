import moment from 'moment';
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useForm } from 'react-hook-form';
import { Link } from 'react-router-dom';
import { addResponseError, addSuccess } from '../../../services/Messaging';
import { SubmitButton } from '../../core/Button';
import { FormActions, FormGroup } from '../../core/form';
import { LoadingBalls } from '../../core/Loading';
import MembershipApi from '../../membership/Api';
import MembershipContext from '../../membership/Context';
import PlanApi from '../../plan/Api';
import usePlans from '../../plan/hooks/usePlans';
import UserContext from '../Context';
import RecurlyAccountCodeForm from './RecurlyAccountCodeForm';

const ManageSubscription = () => {
  const [selfServeLink, setSelfServeLink] = useState<string | null>(null);

  const getRecurlyLink = useCallback(() => {
    PlanApi.selfServe()
      .then((response: { url: string | null }) => {
        setSelfServeLink(response.url);
      })
      .catch(addResponseError);
  }, []);

  useEffect(() => {
    getRecurlyLink();
  }, [getRecurlyLink]);

  return (
    <>
      {selfServeLink ? (
        <>
          <div className="content mt-8 pt-8 border-t border-grey-100">
            <h6 className="font-semibold text-base mb-4">
              Manage Subscription
            </h6>
            <p className="text-base">
              If you would like to manage your subsciption, payment information
              or view your invoices you can do so through our payment portal.
            </p>
          </div>
          <a
            href={selfServeLink}
            className="btn btn--primary btn--sm mt-4 ml-auto"
            rel="noopener noreferrer"
            target="_blank"
          >
            Manage
          </a>
        </>
      ) : null}
    </>
  );
};

const PlanForm = ({ plan }: { plan: PlanEntity }) => {
  const [items, setItems] = useState<PlanEntity[]>([plan]);
  const [loading, setLoading] = useState(true);
  const { refresh: refreshMembership } = useContext(MembershipContext);
  const { membershipId, refresh } = useContext(UserContext);
  const { handleSubmit, register, errors } = useForm({
    mode: 'onChange',
    shouldFocusError: true,
  });

  const loadItems = useCallback(() => {
    PlanApi.list()
      .then((response: PlanEntity[]) => {
        // filter out duplicate (it will always exist
        // unless the current plan is no longer available)
        const plans = response
          .filter(p => {
            if (p.id === plan.id) {
              return false;
            }

            return p.status === 'active';
          })
          .sort((a, b) => {
            if (a.name < b.name) {
              return -1;
            }
            if (a.name > b.name) {
              return 1;
            }
            return 0;
          });
        setItems([plan, ...plans]);
      })
      .catch(addResponseError)
      .finally(() => {
        setLoading(false);
      });
  }, [plan]);

  useEffect(() => {
    loadItems();
  }, [loadItems]);

  const onSubmit = (values: any) => {
    if (loading) return;
    setLoading(true);

    const { planId } = values;

    MembershipApi.update(membershipId || '', { planId })
      .then(() => {
        addSuccess('Your plan has been updated!');
        refresh().then(() => {
          refreshMembership();
          setLoading(false);
        });
      })
      .catch((error: ResponseError) => {
        setLoading(false);
        addResponseError(error);
      });
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} noValidate>
      <LoadingBalls isActive={loading} fullscreen />

      <FormGroup name="planId" errors={errors}>
        <select
          name="planId"
          id="planId"
          className="form-input"
          defaultValue={plan.id}
          ref={register({
            required: 'Plan is required',
          })}
        >
          <option value="" disabled>
            Select a Plan
          </option>
          {items?.map((item: PlanEntity) => (
            <option key={item.id} value={item.id}>
              {item.name}
            </option>
          ))}
        </select>
      </FormGroup>

      <FormActions className="mb-8">
        <div className="ml-auto">
          <SubmitButton text="Update Plan" size="sm" />
        </div>
      </FormActions>
    </form>
  );
};

const PlanDetails = ({
  plan,
  plans,
}: {
  plan: PlanEntity;
  plans: PlanEntity[];
}) => {
  const { isRoot, membership } = useContext(UserContext);

  if (isRoot) {
    return <PlanForm plan={plan} />;
  }

  let trialExpiresIn;
  const trialExpiresAt = membership?.trialExpiresAt ?? null;
  if (trialExpiresAt) {
    const expiresAtMoment = moment(trialExpiresAt);

    const days = expiresAtMoment.diff(moment(), 'days');

    if (days < 3) {
      trialExpiresIn = expiresAtMoment.fromNow();
    } else {
      trialExpiresIn = `${days} days`;
    }
  }

  const downgradePlan =
    trialExpiresIn && plans?.length
      ? plans.find(p => p.id === plan.downgradePlanId)
      : null;

  return (
    <>
      <FormGroup name="plan" label="My Plan" className="flex flex-col">
        <p>{downgradePlan?.name ?? plan.name}</p>
      </FormGroup>

      {trialExpiresIn ? (
        <FormGroup
          name="trial"
          label={`Trial of ${plan.name}`}
          className="flex flex-col"
        >
          <p>
            Your trial will end in <strong>{trialExpiresIn}</strong>.
          </p>
        </FormGroup>
      ) : null}
    </>
  );
};

const PlanUpgradeInfo = () => (
  <div className="mt-4 text-small">
    Please contact{' '}
    <a
      className="text-link font-semibold"
      href="mailto:concierge@communo.com?subject=Upgrade%20Membership"
    >
      concierge@communo.com
    </a>{' '}
    to change your plan.
  </div>
);

const NoPlan = () => <div>No plan</div>;

export default () => {
  const { plan: currentPlan } = useContext(MembershipContext);
  const { isRoot } = useContext(UserContext);
  const { canUpgrade } = useContext(UserContext);
  const { pathname } = window.location;
  const { plans } = usePlans();
  const currentPlanOrder = currentPlan?.order ?? 0;
  const currentPlanType = currentPlan?.type;

  const availablePlans = useMemo(() => {
    if (!plans) {
      return [];
    }

    return plans
      .filter(
        plan =>
          plan.status === 'active' &&
          plan.type === currentPlanType &&
          plan.order > currentPlanOrder &&
          plan.isPaid
      )
      .sort((a, b) => {
        return a.order - b.order;
      });
  }, [currentPlanOrder, currentPlanType, plans]);

  if (isRoot && currentPlan) {
    return (
      <>
        <h4 className="font-normal mb-4">Plan</h4>
        <PlanDetails plan={currentPlan} plans={plans} />
        <RecurlyAccountCodeForm />
      </>
    );
  }

  return (
    <div>
      <h4 className="font-normal mb-4">Plan</h4>

      {currentPlan ? (
        <PlanDetails plan={currentPlan} plans={plans} />
      ) : (
        <NoPlan />
      )}
      {canUpgrade && availablePlans.length > 0 ? (
        <Link to={`${pathname}#/upgrade`} className="btn btn--primary btn--sm">
          Upgrade plan
        </Link>
      ) : (
        <PlanUpgradeInfo />
      )}
      {currentPlan?.isPaid ? <ManageSubscription /> : null}
    </div>
  );
};
