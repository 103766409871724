import React, { useContext, useMemo, useState } from 'react';
import moment from 'moment';
import { currency } from '../../../utilities/Number';
import { timeAgo } from '../../../utilities/dateTime';
import UserContext from '../../user/Context';
import redactedPriceImage from '../../../assets/work-redacted-price.png';
import WorkIntro from './WorkIntro';
import ApplyNowButton from './ApplyNowButton';
import Button from '../../core/Button';
import ShareableLinks from '../ShareableLinks';
import Modal from '../../core/modal/Modal';
import useCurrentMembership from '../../../hooks/useCurrentMembership';
import UpgradeJobButton from '../UpgradeJobButton';
import { useWorkIsPosted } from '../../../hooks/useWorkIsPosted';
import ViewApplicantsButton from '../ViewApplicantsButton';
import {
  ENABLE_PROJECT_TYPES,
  ENABLE_INVITE_TO_APPLY,
  ENABLE_RECRUITER_ASSIGNMENT,
} from '../../../config';
import { getWorkTypeLabel } from '../search/getTypeOptions';
import { getBudgetTypeLabel, getWorkStartLabel } from './Helpers';
import useWorkApplications from '../../../hooks/useWorkApplications';
import AssignRecruiter from '../AssignRecruiter';

const RedactedPrice = () => (
  <img
    src={redactedPriceImage}
    className="max-w-full"
    alt="Redacted work price"
  />
);

const Meta = ({
  work,
  qualification,
  loading,
  refreshWork,
  canManage,
}: {
  work: WorkEntity;
  qualification: QualificationEntity | null;
  loading: boolean;
  refreshWork: Function;
  canManage: boolean;
}) => {
  const { id: userId, membershipId } = useContext(UserContext);
  const workApplications = useWorkApplications(work.id)[0];
  const { isPosted: workIsPosted } = useWorkIsPosted(work);

  const applicationCountTotal = useMemo(() => {
    if (!workApplications.data) {
      return 0;
    }
    // workApplications applications statuses are nested under the data values
    return Object.values(workApplications.data).filter(
      app => app.status !== 'invited'
    ).length;
  }, [workApplications]);

  const price = useMemo(() => {
    if (!userId && work.price) return <RedactedPrice />;
    return work.price ? currency(work.price) : null;
  }, [userId, work.price]);

  const date = timeAgo(
    new Date(
      moment(work.expiresAt)
        .subtract(30, 'day')
        .toISOString()
    )
  );

  const skills = work.skills.map(skill => skill.name);
  const industries = work.industries.map(industry => industry.name);
  const { audience, requirements, isRemote, company } = work;

  const [shareModal, setShareModal] = useState(false);
  const [inviteModal, setInviteModal] = useState(false);
  const [assignModal, setAssignModal] = useState(false);
  const toggleShareModal = (bool: boolean) => setShareModal(bool);
  const toggleInviteModal = (bool: boolean) => setInviteModal(bool);
  const toggleAssignModal = (bool: boolean) => setAssignModal(bool);

  const showShareButton = work.collections.length === 0;
  const showInviteButton = ENABLE_INVITE_TO_APPLY && canManage;
  const { checkAccess } = useCurrentMembership();
  const showApplyButton = checkAccess('canApplyToCommunoWork');
  const showAssignButton = ENABLE_RECRUITER_ASSIGNMENT && canManage;

  const hourlyRate = work.hourlyRate ? currency(work.hourlyRate) : null;
  const workStart = getWorkStartLabel(work.workStart);
  const budgetType = getBudgetTypeLabel(work.budgetType, work.type);

  // const audienceStr =
  //   audience === WorkAudience.BOTH ? 'Everyone' : (audience as string);

  // const audienceType = capitalize(
  //   audience === 'collection' && collections && collections.length > 0
  //     ? collections[0].name
  //     : audienceStr
  // );

  return (
    <>
      <WorkIntro work={work} isAuthenticated={!!userId} />

      {(showShareButton || showInviteButton || showApplyButton) && (
        <>
          <hr className="mt-8 mb-8" />
          <div className="flex justify-between gap-2">
            {showShareButton && (
              <Button
                className="flex-1"
                fill="outline"
                color="primary"
                onClick={() => toggleShareModal(true)}
              >
                Share
              </Button>
            )}
            {showInviteButton && (
              <Button
                className="flex-1"
                fill="solid"
                color="primary"
                fullWidth
                onClick={() => toggleInviteModal(true)}
              >
                Invite
              </Button>
            )}
            {showApplyButton && (
              <ApplyNowButton work={work} qualification={qualification} />
            )}
          </div>

          {showAssignButton && (
            <>
              <hr className="mt-8 mb-8" />
              <>
                {work.recruiter ? (
                  <div className="mt-8">
                    <p className="text-grey-400 font-medium">
                      Recruiter Assigned
                    </p>
                    <p className="mt-1 text-lg">
                      {work.recruiter?.firstName} {work.recruiter?.lastName}
                    </p>
                  </div>
                ) : null}
                <div className="mt-8">
                  <Button
                    fill="solid"
                    color="primary"
                    fullWidth
                    onClick={() => toggleAssignModal(true)}
                  >
                    Assign
                  </Button>
                </div>
              </>
              <Modal
                isOpen={assignModal}
                onClose={() => toggleAssignModal(false)}
                title="Assign to a recruiter"
                width="xl"
              >
                <div className="min-h-screen50 justify-between flex flex-col">
                  <AssignRecruiter
                    work={work}
                    membershipId={membershipId || ''}
                    refreshWork={refreshWork}
                    onComplete={() => {
                      toggleAssignModal(false);
                    }}
                  />
                  <div className="flex justify-end mt-6">
                    <Button
                      fill="outline"
                      color="tertiary"
                      size="sm"
                      onClick={() => toggleAssignModal(false)}
                      text="Close"
                    />
                  </div>
                </div>
              </Modal>
            </>
          )}
        </>
      )}

      <Modal
        isOpen={inviteModal}
        onClose={() => toggleInviteModal(false)}
        title="Invite a member"
        width="xl"
      >
        <div className="min-h-screen50 justify-between flex flex-col">
          <ShareableLinks work={work} user={userId} onlyInvite />
          <div className="flex justify-end mt-6">
            <Button
              fill="outline"
              color="tertiary"
              size="sm"
              onClick={() => toggleInviteModal(false)}
              text="Close"
            />
          </div>
        </div>
      </Modal>

      <Modal
        isOpen={shareModal}
        onClose={() => toggleShareModal(false)}
        title="Social Sharing"
        width="xl"
      >
        <>
          <ShareableLinks work={work} user={userId} />
          <div className="flex justify-end mt-6">
            <Button
              fill="outline"
              color="tertiary"
              size="sm"
              onClick={() => toggleShareModal(false)}
              text="Close"
            />
          </div>
        </>
      </Modal>

      {canManage ? (
        <>
          <hr className="mt-8" />
          {!workIsPosted ? (
            <div className="mt-4">
              <UpgradeJobButton
                work={work}
                loading={loading}
                refreshWork={refreshWork}
                trackingEventPrefix="Work Page"
              />
            </div>
          ) : (
            <div className="mt-4">
              <ViewApplicantsButton
                workId={work.id}
                trackingEventName="Work Page - Work Applicants Clicked"
                applicantCount={applicationCountTotal}
                isExternalJob={work.isExternal || false}
              />
            </div>
          )}

          {/* <div className="mt-2 -mx-4">
            <div className="notice notice--info my-8">
              <h6 className="text-center">Posted to {audienceType}</h6>
            </div>
          </div> */}
        </>
      ) : null}

      <h5 className="my-8">{work.title}</h5>

      {['freelancers', 'agencies'].includes(audience as string) ? (
        <div className="mt-2 -mx-4">
          <div className="notice notice--warning">
            <p className="text-black text-lg">
              {audience === 'freelancers' ? (
                <>
                  <span className="font-semibold">Freelancers only</span> (no
                  Agencies please)
                </>
              ) : (
                <>
                  <span className="font-semibold">Agency only</span> (no
                  Freelancers please)
                </>
              )}
            </p>
          </div>
        </div>
      ) : null}
      {company && canManage ? (
        <div className="mt-8">
          <p className="text-grey-400 font-medium">Company</p>
          <p className="mt-1 text-lg">{company.name}</p>
        </div>
      ) : null}

      {requirements ? (
        <div className="mt-2 -mx-4">
          <div className="notice notice--warning">
            <p className="text-gold-1100 font-medium">Requirements</p>
            <p className="text-black text-lg">{requirements}</p>
          </div>
        </div>
      ) : null}

      <div className={isRemote ? 'mt-8' : 'mt-2 -mx-4'}>
        <div className={isRemote ? '' : 'notice notice--warning'}>
          <p
            className={`font-medium ${
              isRemote ? 'text-grey-400' : 'text-gold-1100'
            }`}
          >
            Location
          </p>
          <p className="text-black mt-1 text-lg">
            {work.location?.friendlyName ?? '—'}
          </p>
          {isRemote && (
            <p className="text-black font-medium text-lg">Remote OK</p>
          )}
        </div>
      </div>

      <div className="mt-8">
        <p className="text-grey-400 font-medium">Type</p>
        <p className="mt-1 text-lg">{getWorkTypeLabel(work.type) ?? '—'}</p>
      </div>
      {ENABLE_PROJECT_TYPES && work.projectOption?.projectType?.name && (
        <div className="mt-8">
          <p className="text-grey-400 font-medium">Project Type</p>
          <p className="mt-1 text-lg">
            {work.projectOption?.projectType?.name}
          </p>
        </div>
      )}
      {ENABLE_PROJECT_TYPES && work.projectOption?.name && (
        <div className="mt-8">
          <p className="text-grey-400 font-medium">Project Option</p>
          <p className="mt-1 text-lg">{work.projectOption?.name}</p>
        </div>
      )}
      {work.type !== 'Student' && work.role ? (
        <div className="mt-8">
          <p className="text-grey-400 font-medium">Role</p>
          <p className="mt-1 text-lg">{work.role?.name ?? '—'}</p>
        </div>
      ) : null}
      {skills.length ? (
        <div className="mt-8">
          <p className="text-grey-400 font-medium">Skills</p>
          <p className="mt-1 text-lg">{skills.join(', ')}</p>
        </div>
      ) : null}
      {industries.length ? (
        <div className="mt-8">
          <p className="text-grey-400 font-medium">Industry</p>
          <p className="mt-1 text-lg">{industries.join(', ')}</p>
        </div>
      ) : null}
      {workStart ? (
        <div className="mt-8">
          <p className="text-grey-400 font-medium">Work Start</p>
          <p className="mt-1 text-lg">{workStart}</p>
        </div>
      ) : null}
      {budgetType ? (
        <div className="mt-8">
          <p className="text-grey-400 font-medium">Budget Type</p>
          <p className="mt-1 text-lg">{budgetType}</p>
        </div>
      ) : null}
      {work.hoursPerWeek ? (
        <div className="mt-8">
          <p className="text-grey-400 font-medium">Hours per Week</p>
          <p className="mt-1 text-lg">{work.hoursPerWeek}</p>
        </div>
      ) : null}
      {price ? (
        <div className="mt-8">
          <p className="text-grey-400 font-medium">Budget (EST)</p>
          <div className="mt-1 text-lg">{price}</div>
        </div>
      ) : null}
      {hourlyRate ? (
        <div className="mt-8">
          <p className="text-grey-400 font-medium">Hourly Rate</p>
          <p className="mt-1 text-lg">{hourlyRate}</p>
        </div>
      ) : null}
      {work.duration ? (
        <div className="mt-8">
          <p className="text-grey-400 font-medium">
            How long do you need them for?
          </p>
          <p className="mt-1 text-lg">{work.duration} weeks</p>
        </div>
      ) : null}
      <div className="mt-8">
        <p className="text-grey-400 font-medium">Posted</p>
        <p className="mt-1 text-lg">{date}</p>
      </div>
    </>
  );
};

export default Meta;
