import React, { Suspense } from 'react';
import { IonApp, IonRouterOutlet, IonPage, IonContent } from '@ionic/react';
import { IonReactRouter } from '@ionic/react-router';
import { Route } from 'react-router-dom';
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import { QueryClientProvider, QueryClient } from '@tanstack/react-query';

import Keyboard from './services/Keyboard';
import PushServices from './PushServices';
import { UserProvider } from './components/user/Context';
import { UserRoutes } from './components/user/App';
import { SearchProvider } from './components/search/Context';
import { AppContextProvider } from './AppContext';
import { NavigateContextProvider } from './context/NavigateContext';
import { MembershipProvider } from './components/membership/Context';
import { APP_ENVIRONMENT, SEGMENT_KEY, SENTRY_DSN } from './config';
import ErrorBoundary from './components/core/ErrorBoundary';
import AppTabs from './AppTabs';
import AppUrlOpener from './AppUrlOpener';
import { appRoutes, AppModalRoutes } from './AppRoutes';
import { load } from './analytics';
import { OnboardingOverlay } from './components/onboarding/Onboarding';
import { isMobile } from './utilities/Device';

import './utilities/i18n';
import './theme/dist/app.css';
import AppMeta from './AppMeta';
import ChatContextProvider from './components/chat/context/ChatContextProvider';
import { DeployProvider } from './context/DeployContext';
import { ZendeskWidgetProvider } from './context/ZendeskWidgetContext';
import AppInfo from './components/appInfo/AppInfo';
import NetworkStatus from './components/NetworkStatus';
import SourceCookie from './components/cookie/SourceCookie';
import { CollectionsContextProvider } from './components/collections/context/CollectionsContext';
import ModalPortal from './components/core/modal/ModalPortal';
import Preloader from './Preloader';
import FeatureGateProvider from './components/featureGate/Context';
import { MyWorkProvider } from './components/work/Context';
import useBrandConfig from './hooks/useBrandConfig';
import { EducationProvider } from './components/education/Context';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: 3,
      retryDelay: attemptIndex => Math.min(2500 * 2 ** attemptIndex, 30000),
    },
  },
});

// Load analytics
if (SEGMENT_KEY) {
  load(SEGMENT_KEY);
}

// if (APP_ENVIRONMENT === 'production') LogRocket.init(LOGROCKET_APP_ID);

if (APP_ENVIRONMENT === 'production')
  Sentry.init({
    dsn: SENTRY_DSN,
    integrations: [new Integrations.BrowserTracing()],
    tracesSampleRate: 1.0,
    environment: APP_ENVIRONMENT,
  });

// disable page transitions/animations on desktop
const navAnimation = !!isMobile;

// Init keyboard on html element
Keyboard.init(document.getElementsByTagName('html')[0]);

// const MemoRoute = React.memo(Route);

const App: React.FC = () => {
  const { cssClass } = useBrandConfig();

  return (
    <IonApp className={cssClass}>
      <SourceCookie />
      <AppContextProvider>
        <IonReactRouter>
          <NetworkStatus />
          <AppUrlOpener />
          <AppMeta />
          <UserProvider>
            <MembershipProvider>
              <MyWorkProvider>
                <FeatureGateProvider>
                  <SearchProvider>
                    <EducationProvider>
                      <ChatContextProvider>
                        <NavigateContextProvider>
                          <ZendeskWidgetProvider>
                            <CollectionsContextProvider>
                              <PushServices />
                              <OnboardingOverlay />
                              <IonPage>
                                <AppInfo />
                                {/* <Header /> */}
                                <IonContent scrollX={false} scrollY={false}>
                                  <div id="sidebar-menu-portal" />
                                  <Suspense fallback={<div>Loading</div>}>
                                    <AppTabs>
                                      <IonRouterOutlet animated={navAnimation}>
                                        {appRoutes.map(
                                          ({
                                            path,
                                            component: Component,
                                            exact = true,
                                          }: any) => (
                                            <Route
                                              key={path || Component.name}
                                              path={path}
                                              component={Component}
                                              exact={exact}
                                            />
                                          )
                                        )}
                                      </IonRouterOutlet>
                                    </AppTabs>
                                  </Suspense>
                                  <UserRoutes />
                                  <AppModalRoutes />
                                </IonContent>
                              </IonPage>
                            </CollectionsContextProvider>
                          </ZendeskWidgetProvider>
                        </NavigateContextProvider>
                      </ChatContextProvider>
                    </EducationProvider>
                  </SearchProvider>
                </FeatureGateProvider>
              </MyWorkProvider>
            </MembershipProvider>
          </UserProvider>
        </IonReactRouter>
      </AppContextProvider>
      <Preloader />
      <ModalPortal />
      <div
        id="select-dropdown-portal"
        style={{ position: 'relative', zIndex: 210000 }}
      />
    </IonApp>
  );
};

const Root = () => (
  <DeployProvider>
    <QueryClientProvider client={queryClient}>
      <ErrorBoundary message="Aww snap, something went wrong :/" fullscreen>
        <App />
      </ErrorBoundary>
    </QueryClientProvider>
  </DeployProvider>
);

export default Root;
