import React, { useState } from 'react';
import useCurrentMembership from '../../hooks/useCurrentMembership';
import useFeatureGate from '../../hooks/useFeatureGate';
import Button from '../core/Button';
import useModal from '../core/modal/hooks/useModal';
import Modal from '../core/modal/Modal';
import RateDisplay from '../core/RateDisplay';
import useUserAllowList from '../user/hooks/useUserAllowList';

interface Props {
  user: UserEntity;
}

interface ViewProps {
  onClick(): void;
  children: JSX.Element;
}

const View = ({ onClick, children }: ViewProps) => {
  return (
    <button className="text-center" type="button" onClick={onClick}>
      {children}
      <div className="font-semibold text-lg">RATE</div>
    </button>
  );
};

const NeedsUpgrade = () => {
  const { openUpgradeFlowFor } = useFeatureGate();

  return (
    <View
      onClick={() => {
        openUpgradeFlowFor('canViewRates');
      }}
    >
      <svg width="33" height="32" fill="none" className="mx-auto">
        <g clipPath="url(#clip0)" fill="#C1C6CF">
          <path d="M27.564 4.686A15.895 15.895 0 0016.25 0 15.896 15.896 0 004.936 4.686 15.896 15.896 0 00.25 16c0 4.274 1.664 8.292 4.686 11.314A15.895 15.895 0 0016.25 32c4.274 0 8.292-1.664 11.314-4.686A15.895 15.895 0 0032.25 16c0-4.274-1.664-8.292-4.686-11.314zM16.25 30.125C8.461 30.125 2.125 23.789 2.125 16S8.461 1.875 16.25 1.875 30.375 8.211 30.375 16 24.039 30.125 16.25 30.125z" />
          <path d="M16.25 16.938a.937.937 0 00-.938.937v1.875a.937.937 0 101.876 0v-1.875a.937.937 0 00-.938-.938z" />
          <path d="M22.5 13.188h-.313v-.938a5.944 5.944 0 00-5.937-5.938 5.944 5.944 0 00-5.938 5.938v.938H10a.938.938 0 00-.938.937V23.5c0 .518.42.938.938.938h12.5c.518 0 .938-.42.938-.938v-9.375a.938.938 0 00-.938-.938zm-10.313-.938a4.067 4.067 0 014.063-4.063 4.067 4.067 0 014.063 4.063v.938h-8.125v-.938zm9.376 10.313H10.938v-7.5h10.624v7.5z" />
        </g>
        <defs>
          <clipPath id="clip0">
            <path fill="#fff" transform="translate(.25)" d="M0 0h32v32H0z" />
          </clipPath>
        </defs>
      </svg>
    </View>
  );
};

const ModalFooter = () => {
  const { closeModal } = useModal();

  return (
    <div className="w-full text-center">
      <Button
        onClick={() => {
          closeModal();
        }}
        fill="outline"
        className="w-full max-w-72"
      >
        Close
      </Button>
    </div>
  );
};

const ProfileRate = ({ rateLevel }: { rateLevel: number | null }) => {
  const [modalOpen, setModalOpen] = useState(false);

  return (
    <>
      <View
        onClick={() => {
          setModalOpen(true);
        }}
      >
        <RateDisplay rateLevel={rateLevel} />
      </View>

      <Modal
        isOpen={modalOpen}
        title="Rates"
        onClose={() => {
          setModalOpen(false);
        }}
        footer={<ModalFooter />}
      >
        <div>
          <div className="flex space-x-4 items-center py-2">
            <div className="w-1/2 flex justify-end">
              <RateDisplay rateLevel={null} />
            </div>
            <div>Not provided</div>
          </div>
          <div className="flex space-x-4 items-center py-2">
            <div className="w-1/2 flex justify-end">
              <RateDisplay rateLevel={1} />
            </div>
            <div>$20-45/hr</div>
          </div>
          <div className="flex space-x-4 items-center py-2">
            <div className="w-1/2 flex justify-end">
              <RateDisplay rateLevel={2} />
            </div>
            <div>$45-75/hr</div>
          </div>
          <div className="flex space-x-4 items-center py-2">
            <div className="w-1/2 flex justify-end">
              <RateDisplay rateLevel={3} />
            </div>
            <div>$75-100/hr</div>
          </div>
          <div className="flex space-x-4 items-center py-2">
            <div className="w-1/2 flex justify-end">
              <RateDisplay rateLevel={4} />
            </div>
            <div>$100-150/hr</div>
          </div>
          <div className="flex space-x-4 items-center py-2">
            <div className="w-1/2 flex justify-end">
              <RateDisplay rateLevel={5} />
            </div>
            <div>$150+/hr</div>
          </div>
        </div>
      </Modal>
    </>
  );
};

const WrappedProfileRate = ({ user }: Props) => {
  const { checkAccess } = useCurrentMembership();
  const { usersInAllowList } = useUserAllowList();
  const rateLevel = user.rateLevel ?? null;
  const canViewRates = checkAccess('canViewRates');
  const inAllowList = usersInAllowList(user.id);

  return (
    <>
      {canViewRates || inAllowList ? (
        <ProfileRate rateLevel={rateLevel} />
      ) : (
        <NeedsUpgrade />
      )}
    </>
  );
};
export default WrappedProfileRate;
