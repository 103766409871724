/* eslint-disable no-console */
import React, {
  useCallback,
  useRef,
  useEffect,
  useMemo,
  useState,
} from 'react';
import useChatMessages from '../hooks/useChatMessages';
import Messages from '../components/Messages';
import IsTypingMessage from '../components/IsTypingMessage';
import useChatChannelMembersTyping from '../hooks/useChatChannelMembersTyping';
import Compose, { ComposeDirect } from '../components/Compose';
import {
  IChannel,
  TwilioMessageReplyDataAttributes,
} from '../../../@types/chat.d';
import ChannelProfile from '../components/ChannelProfile';
import { Link } from '../../core/router';
import useChatOtherMember from '../hooks/useChatOtherMember';
import IntersectionLoader from '../../core/IntersectionLoader';
import { ViewHeader, ViewContent, ViewFooter } from '../../core/View';
import useChatChannelInstance from '../hooks/useChatChannelInstance';
import MessageReply from '../components/MessageReply';
import { addInfo, removeToast } from '../../../services/Messaging';
import useChannelRole from '../hooks/useChannelRole';

const SCROLL_SPEED = 250;

const unreadNotice = {
  insert: (count: number, el: any) =>
    el.insertAdjacentHTML(
      'afterend',
      `<div class="chat-unread-count">
        <div class="chat-unread-count__body">${count} new messages</div>
      </div>`
    ),
  remove: () => {
    const x = document.querySelector('.chat-unread-count');
    if (x) {
      x.remove();
    }
  },
};

const MembersTyping = ({
  channelSid,
  onMemberTyping,
}: {
  channelSid: string;
  onMemberTyping: () => void;
}) => {
  const { membersIdsTyping } = useChatChannelMembersTyping(channelSid);

  if (membersIdsTyping.length > 0) onMemberTyping();

  return (
    <>
      {membersIdsTyping.map(identity => (
        <IsTypingMessage key={identity} identity={identity} />
      ))}
    </>
  );
};

const ChannelSettingsLink = ({ linkTo }: { linkTo: string }) => {
  return (
    <Link to={linkTo} className="btn btn--circle">
      <i className="i-more-vert" />
    </Link>
  );
};

const ChannelMemberLink = ({ channelSid }: { channelSid: string }) => {
  const { member } = useChatOtherMember(channelSid);

  if (!member) return null;

  const linkTo = `/chat/conversation/${channelSid}/member/${member.identity}`;

  return <ChannelSettingsLink linkTo={linkTo} />;
};

type ConversationProps = {
  channel: IChannel;
  isInline?: boolean;
  onMessageSent?: Function;
  hideHeader?: boolean;
  onBackButtonClick?: () => void;
};
const Conversation = ({
  channel,
  isInline = false,
  onMessageSent = () => {},
  hideHeader = false,
  onBackButtonClick,
}: ConversationProps) => {
  const { isParticipant } = useChannelRole(channel);

  const isDisabledTextArea =
    isParticipant && channel.attributes.channelState === 'one-way';

  const [chatWindow, setChatWindow] = useState(
    (null as unknown) as HTMLIonContentElement
  );
  const [initialLoad, setInitialLoad] = useState(true);
  const [replyData, setReplyData] = useState(
    (null as unknown) as TwilioMessageReplyDataAttributes | null
  );

  const loadingMore = useRef(false);
  const scrollOnNewMessage = useRef(false);

  const { sid, friendlyName, attributes, lastMessage } = channel;
  const { type } = attributes;

  const { channelInstance } = useChatChannelInstance(sid);
  const {
    messages,
    unreadCount,
    lastReadMessage,
    hasMoreMessages,
    loadMessages,
    loadMessagesToSid,
    loaded: messagesLoaded,
  } = useChatMessages(sid);

  const beforeLoaded = useMemo(
    () => messagesLoaded && chatWindow && initialLoad,
    [messagesLoaded, chatWindow, initialLoad]
  );

  const loaded = useMemo(() => messagesLoaded && chatWindow && !initialLoad, [
    messagesLoaded,
    chatWindow,
    initialLoad,
  ]);

  const onRefChange = useCallback(node => setChatWindow(node), []);

  const handleConsumeMessages = useCallback(async () => {
    if (!lastMessage) return;
    if (lastMessage.index === lastReadMessage?.index) return;

    await channelInstance.setAllMessagesRead();
  }, [channelInstance, lastMessage, lastReadMessage]);

  const scrollToBottom = useCallback(
    async (threshold: number = 200, speed: number = SCROLL_SPEED) => {
      if (!chatWindow) return;

      const viewport = await chatWindow.getScrollElement();

      if (threshold > 0) {
        const isAboveThreshold =
          viewport.scrollTop + viewport.clientHeight <
          viewport.scrollHeight - threshold;
        if (isAboveThreshold) return;
      }

      chatWindow.scrollToBottom(speed);
    },
    [chatWindow]
  );

  const scrollToLastReadMessage = useCallback(
    (speed: number = SCROLL_SPEED) => {
      if (!chatWindow) return;

      if (lastReadMessage) {
        const el = document.getElementById(lastReadMessage.sid);

        if (el?.parentElement && unreadCount > 0) {
          unreadNotice.insert(unreadCount, el);
          // Important to call this after the next tick so scroll position is accurate
          setTimeout(() => {
            const unreadEl: HTMLElement | null = document.querySelector(
              '.chat-unread-count'
            );
            const y = unreadEl?.offsetTop;
            chatWindow.scrollToPoint(0, y, speed);
          }, 0);
        }
      } else {
        chatWindow.scrollToTop(speed);
      }

      setInitialLoad(false);
      scrollOnNewMessage.current = true;
      handleConsumeMessages();
    },
    [chatWindow, unreadCount, lastReadMessage, handleConsumeMessages]
  );

  const scrollToMessage = (
    messageSid: string,
    speed: number = SCROLL_SPEED
  ) => {
    const el = document.getElementById(messageSid);
    const y = el?.offsetTop;

    chatWindow.scrollToPoint(0, y, speed);
  };

  const handleLoadMore = useCallback(async () => {
    if (loadingMore.current) return;

    loadingMore.current = true;

    const viewport = await chatWindow.getScrollElement();
    const scrollHeightBefore = viewport.scrollHeight;
    const scrollTopBefore = viewport.scrollTop;

    setTimeout(async () => {
      // Stops scrolling momentum
      viewport.style.overflow = 'hidden';
      await loadMessages();

      const scrollHeightAfter = viewport.scrollHeight;
      const y = scrollHeightAfter - scrollHeightBefore + scrollTopBefore;

      viewport.style.overflow = '';
      chatWindow.scrollToPoint(0, y);

      loadingMore.current = false;
    }, 250);
  }, [loadMessages, chatWindow]);

  const handleSendMessage = useCallback(
    async (message: string) => {
      let messageAttributes;

      if (replyData) messageAttributes = { replyData };

      await channelInstance
        .sendMessage(message, messageAttributes)
        .catch((e: any) => {
          console.error(e);
        });

      onMessageSent(true);
      scrollToBottom(0);
      setReplyData(null);
    },
    [replyData, channelInstance, onMessageSent, scrollToBottom]
  );

  const handleTyping = useCallback(() => {
    channelInstance.typing();
  }, [channelInstance]);

  const handleReply = ({
    recipientId,
    messageSid,
    message,
  }: TwilioMessageReplyDataAttributes) => {
    setReplyData({ recipientId, messageSid, message });
    // A sort of hacky way of focusing textarea so we don't have to
    // forwardRef drill through the Compose components
    const textarea = document.getElementById('message');
    if (textarea) textarea.focus();
  };

  const handleScrollToReply = async (messageSid: string) => {
    const isMessageLoaded = messages.find(el => el.sid === messageSid);

    if (!isMessageLoaded) {
      let toastId = null;

      const cancelTimeout = setTimeout(() => {
        addInfo('Hang on, loading previous messages...', {
          autoDismiss: false,
          callback: id => {
            toastId = id;
          },
        });
      }, 500);

      await loadMessagesToSid(messageSid);

      if (cancelTimeout) clearTimeout(cancelTimeout);
      if (toastId) removeToast(toastId);
    }

    scrollToMessage(messageSid);
  };

  const handleReplyCancel = () => {
    setReplyData(null);
  };

  // Ensures chat window always starts scroll to bottom
  (() => {
    if (!chatWindow || !initialLoad) return;
    chatWindow.scrollToBottom(0);
  })();

  // Runs scroll logic whenever a new message is added
  useEffect(() => {
    if (!messages || !scrollOnNewMessage.current) return;
    scrollToBottom();
  }, [messages, scrollToBottom]);

  useEffect(() => {
    if (beforeLoaded) scrollToLastReadMessage();
  }, [beforeLoaded, scrollToLastReadMessage]);

  const SettingsLink = () => {
    if (type === 'direct') return <ChannelMemberLink channelSid={sid} />;

    return (
      <ChannelSettingsLink linkTo={`/chat/conversation/${sid}/settings`} />
    );
  };

  return (
    <>
      {hideHeader ? null : (
        <ViewHeader
          onBackButtonClick={onBackButtonClick}
          end={<SettingsLink />}
        >
          <ChannelProfile
            channelSid={sid}
            friendlyName={friendlyName}
            attributes={attributes}
            format="compact"
            isInline={isInline}
          />
        </ViewHeader>
      )}

      <ViewContent layout="none" className="chat-window" ref={onRefChange}>
        {hideHeader ? null : (
          <>
            <ChannelProfile
              channelSid={sid}
              friendlyName={friendlyName}
              attributes={attributes}
              isInline={isInline}
            />
            {loaded && hasMoreMessages ? (
              <IntersectionLoader threshold={1} onIntersect={handleLoadMore} />
            ) : null}
          </>
        )}

        <Messages
          channel={channel}
          messages={messages}
          onReply={handleReply}
          onScrollToReply={handleScrollToReply}
        >
          <MembersTyping channelSid={sid} onMemberTyping={scrollToBottom} />
        </Messages>

        {loaded ? (
          <IntersectionLoader
            showLoadingIndicator={false}
            onIntersect={handleConsumeMessages}
          />
        ) : null}
      </ViewContent>

      <ViewFooter className="chat-footer bg-white pb-safe">
        {replyData ? (
          <MessageReply
            recipientId={replyData.recipientId}
            messageSid={replyData.messageSid}
            message={replyData.message}
            onCancel={handleReplyCancel}
          />
        ) : null}

        {type === 'direct' ? (
          <ComposeDirect
            to={sid}
            onSendMessage={handleSendMessage}
            onKeyDown={handleTyping}
          />
        ) : (
          <Compose
            isDisabledTextArea={isDisabledTextArea}
            onSendMessage={handleSendMessage}
            onKeyDown={handleTyping}
          />
        )}
      </ViewFooter>
    </>
  );
};
export default Conversation;
