import React, { useState, useMemo } from 'react';
import validator from 'validator';
import { useForm } from 'react-hook-form';
import { Perk } from '@communo-corp/shared-types';
import { useHistory } from 'react-router';
import {
  FormInput,
  FormActions,
  FormTextarea,
  FormCheckbox,
  FormGroup,
  FormImage,
  FormRadio,
} from '../../core/form';
import { SubmitButton } from '../../core/Button';
import FormSelect from '../../core/form/FormSelect';
import Expiry from '../formElements/Expiry';
import Membership from '../formElements/Membership';
import perksApi from '../Api';
import { addResponseError, addSuccess } from '../../../services/Messaging';
import Contact from '../formElements/Contact';
import PerkCategories from '../formElements/PerkCategories';
import Alert from '../../alert/Alert';

interface FormFields {
  name: string;
  descriptionSummary: string;
  description: string;
  offerSummary: string;
  offer: string;
  about: string;
  // benefits: string;
  redeemUrl: string;
  featuredLogoImageUrl: string;
  horizontalHeaderImageUrl: string;
  verticalHeaderImageUrl: string;
  assetVideoUrls: string | null;
  assetImageUrls: string | null;
  order: number;
  usesLightTheme: string;
  audience: string[];
  isPremium: boolean;
  isFeatured: boolean;
  isActive: boolean;
  isBundleOnly: boolean | null;
  isNew: boolean;
  expiresAt: number | null;
  membershipId: any;
  contactUserId: string | null;
  perkCategoryIds: string;
  countries: string;
  offerDetails: string;
  // terms: string;
}

interface Props {
  perk?: Perk;
  isEdit?: boolean;
}

const Form = ({ perk, isEdit = false }: Props) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [showDeleteAlert, setShowDeleteAlert] = useState<boolean>(false);
  const { push } = useHistory();

  const defaultAudience = [];

  if (perk?.visibleToAgencies) {
    defaultAudience.push('agency');
  }

  if (perk?.visibleToFreelancers) {
    defaultAudience.push('freelancer');
  }

  const defaultMembership: any = useMemo(() => {
    if (!perk) {
      return undefined;
    }

    return {
      label: perk.membership?.name,
      value: {
        membershipId: perk.membership?.id,
        name: perk.membership?.name,
        logoURL: perk.membership?.logoURL,
      },
    };
  }, [perk]);

  const defaultContact: any = useMemo(() => {
    if (perk == null || perk?.contactUser == null) {
      return undefined;
    }

    return {
      value: perk?.contactUser?.id,
      label: `${perk?.contactUser?.firstName} ${perk?.contactUser?.lastName}`,
    };
  }, [perk]);

  const defaultCategory = useMemo(() => {
    if (perk?.categories?.length) {
      return {
        label: perk.categories[0].name,
        value: perk.categories[0].id,
      };
    }

    return undefined;
  }, [perk]);

  const form = useForm<FormFields>({
    mode: 'onBlur',
    shouldFocusError: true,
    defaultValues: {
      name: perk?.name || '',
      descriptionSummary: perk?.descriptionSummary || '',
      description: perk?.description || '',
      offerSummary: perk?.offerSummary || '',
      offer: perk?.offer || '',
      about: perk?.about || '',
      // benefits: perk?.benefits || '',
      redeemUrl: perk?.redeemUrl || '',
      featuredLogoImageUrl: perk?.featuredLogoImageUrl || '',
      horizontalHeaderImageUrl: perk?.horizontalHeaderImageUrl || '',
      verticalHeaderImageUrl: perk?.verticalHeaderImageUrl || '',
      assetVideoUrls: perk?.assetVideoUrls[0] || null,
      assetImageUrls: perk?.assetImageUrls[0] || null,
      order: perk?.order || 1.1,
      usesLightTheme: perk?.usesLightTheme ? 'true' : 'false',
      audience: defaultAudience,
      isPremium: !!perk?.isPremium,
      isFeatured: !!perk?.isFeatured,
      isActive: !!perk?.isActive,
      isBundleOnly: perk?.isBundleOnly || null,
      isNew: !!perk?.isNew,
      expiresAt: perk?.expiresAt || null,
      membershipId: '',
      contactUserId: defaultContact?.value || null,
      perkCategoryIds: perk?.categories?.length ? perk.categories[0].id : '',
      countries: perk?.countries?.length ? perk?.countries[0].isoCode : '',
      offerDetails: perk?.offerDetails || '',
      // terms: perk?.terms || '',
    },
  });
  const { handleSubmit, register, errors, setValue, watch } = form;
  const watchMembershipId = watch(
    'membershipId',
    defaultMembership?.value.membershipId
  );
  const watchIsFeatured = watch('isFeatured', perk?.isFeatured || false);

  const deleteHandler = () => {
    setShowDeleteAlert(true);
  };

  const onSubmit = (values: any) => {
    if (loading) return;
    setLoading(true);

    const {
      audience,
      assetVideoUrls,
      assetImageUrls,
      order,
      expiresAt,
      usesLightTheme,
      countries,
      isBundleOnly,
    } = values;

    // isBundleOnly can be true/false or null. Null means nothing is set and therefore can be changed
    // on the backend if we automate making a perk "bundle only", when its added to a bundle.
    let bundleOnly;
    if (isEdit) {
      // if is Edit, check if perk.isBundleOnly is a Boolean, if not set to null
      bundleOnly =
        typeof perk?.isBundleOnly === 'boolean' || isBundleOnly
          ? isBundleOnly
          : null;
    } else {
      // if is new - check if isBundleOnly is true. If not, set to null
      bundleOnly = isBundleOnly || null;
    }

    const data = {
      ...values,
      visibleToAgencies: audience.includes('agency'),
      visibleToFreelancers: audience.includes('freelancer'),
      assetVideoUrls: assetVideoUrls.length ? [assetVideoUrls] : [],
      assetImageUrls: assetImageUrls.length ? [assetImageUrls] : [],
      order: parseFloat(order),
      expiresAt: parseInt(expiresAt, 10),
      usesLightTheme: usesLightTheme === 'true',
      countries: [countries],
      isBundleOnly: bundleOnly,
    };

    const apiCall =
      isEdit && perk
        ? perksApi.updatePerk(perk.id, data)
        : perksApi.create(data);

    apiCall
      .then((response: Perk) => {
        const { id } = response;

        addSuccess(`Perk ${isEdit ? 'Updated' : 'Created'}`);

        if (!isEdit) {
          // Redirect to edit to avoid multiple creates
          push(`/perks/${id}/edit`);
        }

        return response;
      })
      .catch(addResponseError)
      .finally(() => {
        setLoading(false);
        // Should really set the default form values to what comes back from the api,
        // But there is so much to compute, this is just easier
        window.location.reload();
      });
  };

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)} noValidate>
        <FormGroup name="isActive" label="Active" errors={errors}>
          <FormCheckbox
            label="Active"
            name="isActive"
            errors={errors}
            ref={register}
          />
        </FormGroup>

        <Membership
          form={form}
          errors={errors}
          name="membershipId"
          defaultValue={defaultMembership}
          required="Membership is required"
        />

        {watchMembershipId && (
          <Contact
            name="contactUserId"
            form={form}
            errors={errors}
            defaultValue={defaultContact}
            membershipId={watchMembershipId}
          />
        )}

        <FormGroup name="isNew" label="New" errors={errors}>
          <FormCheckbox
            label="New"
            name="isNew"
            errors={errors}
            ref={register}
          />
        </FormGroup>

        <FormGroup name="isBundleOnly" label="Bundle Only" errors={errors}>
          <FormCheckbox
            label="Bundle Only"
            name="isBundleOnly"
            errors={errors}
            ref={register}
          />
        </FormGroup>

        <FormGroup name="usesLightTheme" errors={errors} label="Text Color">
          <FormRadio
            label="White"
            name="usesLightTheme"
            value="true"
            ref={register}
          />
          <FormRadio
            label="Black"
            name="usesLightTheme"
            value="false"
            ref={register}
          />
        </FormGroup>

        <div className="row-flex">
          <div className="col w-full md:flex-1">
            <FormImage
              aspectRatio={[2, 1]}
              name="horizontalHeaderImageUrl"
              label="Header (horizontal)"
              defaultValue={perk?.horizontalHeaderImageUrl}
              form={form}
              errors={errors}
              ref={register({
                required: 'Horizontal image is required',
              })}
              onChange={(url: string) => {
                setValue('horizontalHeaderImageUrl', url);
              }}
            />
          </div>
        </div>

        <FormInput
          name="name"
          label="Tag"
          defaultValue=""
          maxLength={25}
          hint="Max length of 25 characters"
          placeholder="Tag here"
          errors={errors}
          ref={register({
            required: 'Tag is required',
            maxLength: {
              value: 25,
              message: 'Max length of 25 characters',
            },
          })}
        />

        <FormTextarea
          name="descriptionSummary"
          label="Title"
          maxLength={60}
          hint="Max length of 60 characters"
          placeholder=""
          errors={errors}
          rows={3}
          ref={register({
            required: 'Title is required',
            maxLength: {
              value: 60,
              message: 'Max length of 60 characters',
            },
          })}
        />
        <FormTextarea
          name="description"
          label="Details"
          // maxLength={75}
          // hint="Max length of 75 characters"
          placeholder=""
          errors={errors}
          ref={register({
            required: 'Details are required',
            // maxLength: {
            //   value: 75,
            //   message: 'Max length of 75 characters',
            // },
          })}
        />
        <FormTextarea
          name="offerSummary"
          label="Offer Short"
          maxLength={30}
          hint="Max length of 30 characters"
          placeholder=""
          errors={errors}
          rows={2}
          ref={register({
            required: 'Offer is required',
            maxLength: {
              value: 30,
              message: 'Max length of 30 characters',
            },
          })}
        />
        <FormTextarea
          name="offer"
          label="Offer Long"
          maxLength={100}
          hint="Max length of 100 characters"
          placeholder=""
          errors={errors}
          rows={3}
          ref={register({
            required: 'Offer is required',
            maxLength: {
              value: 100,
              message: 'Max length of 100 characters',
            },
          })}
        />

        {/* <FormTextarea
        name="benefits"
        label="Benefit Statement"
        placeholder=""
        errors={errors}
        ref={register({
          required: 'Benefit Statement is required',
        })}
      /> */}

        <FormTextarea
          name="offerDetails"
          label="Terms & Conditions"
          placeholder=""
          errors={errors}
          ref={register}
        />

        <FormInput
          name="assetVideoUrls"
          label="Video Url (not currently used)"
          type="url"
          placeholder="https://someurl.com"
          errors={errors}
          ref={register({
            validate: (value: string) =>
              validator.isEmpty(value) ||
              validator.isURL(value, {
                require_protocol: true,
              }) ||
              'Invalid url',
          })}
        />

        <FormImage
          aspectRatio={[2, 1]}
          name="assetImageUrls"
          label="Asset Image"
          defaultValue={perk?.assetImageUrls[0]}
          form={form}
          errors={errors}
          ref={register}
          onChange={(url: string) => {
            setValue('assetImageUrls', url);
          }}
        />

        {/* <FormTextarea
        name="terms"
        label="Terms"
        placeholder=""
        errors={errors}
        ref={register({
          required: 'Terms are required',
        })}
      /> */}

        <FormTextarea
          name="about"
          label="About"
          maxLength={700}
          hint="Max length of 700 characters"
          placeholder=""
          errors={errors}
          ref={register({
            required: 'About is required',
            maxLength: {
              value: 700,
              message: 'Max length of 700 characters',
            },
          })}
        />
        <FormInput
          name="redeemUrl"
          label="Redeem Url"
          type="url"
          defaultValue=""
          placeholder="https://someurl.com"
          errors={errors}
          ref={register({
            required: 'Url is required',
            validate: (value: string) =>
              validator.isEmpty(value) ||
              validator.isURL(value, {
                require_protocol: true,
              }) ||
              'Invalid url',
          })}
        />
        <FormInput
          name="order"
          label="Order"
          type="number"
          step="0.01"
          defaultValue=""
          placeholder="0.00"
          min="0"
          errors={errors}
          ref={register({
            required: 'Order is required',
          })}
        />

        <FormGroup
          name="audience"
          errors={errors}
          label="Audience (select all that apply)"
        >
          <FormCheckbox
            label="Freelancer"
            name="audience"
            value="freelancer"
            ref={register({
              required: 'Audience is required',
            })}
          />
          <FormCheckbox
            label="Agency"
            name="audience"
            value="agency"
            ref={register({
              required: 'Audience is required',
            })}
          />
        </FormGroup>

        <FormGroup
          name="isPremium"
          label="Premium (for future use)"
          errors={errors}
        >
          <FormCheckbox
            label="Premium Perk"
            name="isPremium"
            errors={errors}
            ref={register}
          />
        </FormGroup>

        <FormGroup name="isFeatured" label="Featured" errors={errors}>
          <FormCheckbox
            label="Featured Perk"
            name="isFeatured"
            errors={errors}
            ref={register}
          />
        </FormGroup>

        {watchIsFeatured && (
          <div className="md:row-flex">
            <div className="col w-full md:w-1/2">
              <div className="w-screen-xs">
                <FormImage
                  aspectRatio={[1, 2]}
                  name="verticalHeaderImageUrl"
                  label="Header (vertical)"
                  defaultValue={perk?.verticalHeaderImageUrl}
                  form={form}
                  errors={errors}
                  ref={register({
                    required: 'Vertical image is required',
                  })}
                  onChange={(url: string) => {
                    setValue('verticalHeaderImageUrl', url);
                  }}
                />
              </div>
            </div>
            <div className="col w-full md:w-1/2">
              <div className="mx-auto">
                <FormImage
                  aspectRatio={[1, 1]}
                  name="featuredLogoImageUrl"
                  label="Reversed Logo"
                  defaultValue={perk?.featuredLogoImageUrl}
                  form={form}
                  errors={errors}
                  ref={register({
                    required: 'Reversed logo is required',
                  })}
                  onChange={(url: string) => {
                    setValue('featuredLogoImageUrl', url);
                  }}
                />
              </div>
            </div>
          </div>
        )}

        <PerkCategories
          name="perkCategoryIds"
          label="Categories"
          errors={errors}
          form={form}
          defaultValue={defaultCategory}
        />

        <FormSelect
          name="countries"
          label="Country Eligibility"
          placeholder="-- All --"
          errors={errors}
          ref={register}
        >
          <option value="CA">Canada Only</option>
          <option value="US">USA Only</option>
        </FormSelect>

        <Expiry
          errors={errors}
          ref={register}
          onChange={milliseconds => {
            setValue('expiresAt', milliseconds);
          }}
          defaultValue={perk?.expiresAt}
        />

        <FormActions className="mt-auto justify-center">
          <div className="flex justify-end w-full">
            {isEdit && (
              <button
                type="button"
                className="btn btn--outline mr-2"
                onClick={deleteHandler}
              >
                Delete
              </button>
            )}
            <SubmitButton
              text={isEdit ? 'Update' : 'Create'}
              loading={loading}
            />
          </div>
        </FormActions>
      </form>
      <Alert
        isOpen={showDeleteAlert}
        onDidDismiss={() => {
          setShowDeleteAlert(false);
        }}
        header="Are sure?"
        message="This will delete the perk forever."
        buttons={[
          {
            text: 'Cancel',
            role: 'cancel',
            cssClass: 'alert-secondary-btn',
            handler: () => {},
          },
          {
            text: 'Confirm',
            cssClass: 'alert-primary-btn',
            handler: () => {
              if (perk) {
                perksApi
                  .delete(perk.id)
                  // eslint-disable-next-line
                  .then((response: Perk) => {
                    addSuccess(`Perk Deleted`);
                  })
                  .catch(addResponseError)
                  .finally(() => {
                    push('/perks/manage');
                  });
              }
            },
          },
        ]}
      />
    </>
  );
};
export default Form;
