import React, { useContext } from 'react';
import querystringify from 'querystringify';
import Login from '../components/user/views/Login';
import Page from '../components/core/Page';
import useAnalytics from '../hooks/useAnalytics';
import UserContext from '../components/user/Context';
import SignupLayout from '../components/user/signup/SignupLayout';

export default () => {
  const { authDetails } = useContext(UserContext);
  const { redirectUrl } = querystringify.parse(window.location.search) as any;
  useAnalytics('Enter Your Email', { email: authDetails.email });

  return (
    <Page
      layout="basic"
      className="page-bg--white"
      pageTitle="Login"
      mobileHeader={() => null}
      darkStatusBar
      hideHeader
    >
      <SignupLayout headerImg="https://res.cloudinary.com/communo/image/upload/v1589576439/onboarding/onboarding-login-email.jpg">
        <div>
          <Login redirectUrl={redirectUrl} />
        </div>
      </SignupLayout>
    </Page>
  );
};
