/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import {
  Home,
  GetStarted,
  Feed,
  Login,
  Signup,
  Invite,
  Auth,
  AuthInvited,
  AuthUnknown,
  AuthNoPassword,
  ForgotPassword,
  ResetPassword,
  MagicOp,
  MagicSent,
  MagicLogin,
  Logout,
  Onboarding,
  PlanSuccess,
  PlanPayment,
  PlanFrequency,
  Plans,
  Events,
  EventDetails,
  ChatConversationsList,
  ChatDesktop,
  Discover,
  Members,
  MemberMembers,
  ImageUpload,
  MemberDetails,
  Error404,
  Work,
  WorkInternalCreate,
  WorkExternalCreate,
  WorkEdit,
  Perks,
  PerksDetail,
  PerksCreate,
  PerksManage,
  PerksEdit,
  PerkBundlesManage,
  PerkBundlesCreate,
  Generosity,
  Demos,
  PerkBundlesEdit,
  PerkBundlesDetail,
} from './pages';
import WorkDetails from './pages/WorkDetails';
import EventCreate from './components/event/Create';
import EventEdit from './components/event/Edit';
import { ModalRoute } from './components/user/Routes';
import PortfolioView from './components/portfolio/PortfolioView';
import RootMembershipDeactivated from './pages/RootMembershipDeactivated';
import WorkCandidates from './pages/WorkCandidates';
import WorkCandidatesPublic from './pages/WorkCandidatesPublic';
import { page } from './analytics';
import VideoChat from './pages/VideoChat';
import Testing from './pages/Testing';
import environment from './environment';
import LandingAAF from './pages/LandingAAF';
import LandingHBCU from './pages/LandingHBCU';
import CollectionInviteLandingPage from './pages/CollectionInviteLandingPage';
import { isDesktop, isMobile } from './utilities/MatchMedia';
import chatSubRoutes from './components/chat/routes';
import GetVetted from './modals/GetVetted';
import ForgotPasswordSent from './pages/ForgotPasswordSent';
import MyCollectionsPage from './components/collections/pages/MyCollectionsPage';
import ViewCollectionPage from './components/collections/pages/ViewCollectionPage';
import PageMockup from './pages/Mockups';
import SubmitRecommendation from './pages/SubmitRecommendation';
import MyWork from './components/work/MyWork';
import MyApplications from './components/work/MyApplications';
import Welcome from './pages/Welcome';
import GetToKnowYou from './pages/GetToKnowYou';
import GetToKnowYourAgencyPage from './pages/GetToKnowYourAgency';
import redirectRoutes from './AppRedirects';
import { APP_ENVIRONMENT } from './config';
import LandingLocomotus from './pages/LandingLocomotus';
import SignupLocomotus from './pages/SignupLocomotus';
import WelcomeCompany from './pages/WelcomeCompany';
import UpgradeActions from './pages/UpgradeActions';
import Upgrade from './pages/Upgrade';
import BenchRedirectPage from './components/collections/pages/BenchRedirectPage';
import SubmitReference from './pages/SubmitReference';
import PlanConfirm from './pages/PlanConfirm';
// import ProgramHBCU from './pages/ProgramHBCU';
import SignupHBCU from './pages/SignupHBCU';

import Channel from './components/generosity/Channel';
import CreateCompanyForm from './components/work/CreateCompany';

export type Route = {
  path?: string;
  component: any;
  exact?: boolean;
};

// Place ternary isMobile vs isDesktop routes
const chatRoutes = isDesktop
  ? [{ path: '/chat/:page?/:sid?', component: ChatDesktop, exact: false }]
  : [{ path: '/chat', component: ChatConversationsList }, ...chatSubRoutes];

const styleguideRoutes =
  APP_ENVIRONMENT !== 'production'
    ? [
        {
          path: '/styleguide',
          component: React.lazy(() => import('./pages/Styleguide')),
        },
        {
          path: '/styleguide/channels',
          component: React.lazy(() =>
            import('./pages/styleguide/StyleGenerosity')
          ),
        },
        {
          path: '/styleguide/typography',
          component: React.lazy(() =>
            import('./pages/styleguide/StyleTypography')
          ),
        },
        {
          path: '/styleguide/buttons',
          component: React.lazy(() =>
            import('./pages/styleguide/StyleButtons')
          ),
        },
        {
          path: '/styleguide/forms',
          component: React.lazy(() => import('./pages/styleguide/StyleForms')),
        },
        {
          path: '/styleguide/blobs',
          component: React.lazy(() => import('./pages/styleguide/StyleBlobs')),
        },
        {
          path: '/styleguide/breadcrumbs',
          component: React.lazy(() =>
            import('./pages/styleguide/StyleBreadcrumbs')
          ),
        },
        {
          path: '/styleguide/notices',
          component: React.lazy(() =>
            import('./pages/styleguide/StyleNotices')
          ),
        },
        {
          path: '/styleguide/chat',
          component: React.lazy(() => import('./pages/styleguide/StyleChat')),
        },
        {
          path: '/styleguide/chat-conversation',
          component: React.lazy(() =>
            import('./pages/styleguide/StyleChatConversation')
          ),
        },
        {
          path: '/styleguide/discover',
          component: React.lazy(() =>
            import('./pages/styleguide/StyleDiscover')
          ),
        },
        {
          path: '/styleguide/modals',
          component: React.lazy(() => import('./pages/styleguide/StyleModals')),
        },
        {
          path: '/styleguide/member-cards',
          component: React.lazy(() =>
            import('./pages/styleguide/StyleMemberCards')
          ),
        },
        {
          path: '/styleguide/role-picker',
          component: React.lazy(() =>
            import('./pages/styleguide/StyleRolePicker')
          ),
        },
        {
          path: '/styleguide/range',
          component: React.lazy(() => import('./pages/styleguide/StyleRange')),
        },
        {
          path: '/styleguide/recommendation',
          component: React.lazy(() =>
            import('./pages/styleguide/StyleRecommendation')
          ),
        },
        {
          path: '/styleguide/svg-icons',
          component: React.lazy(() =>
            import('./pages/styleguide/StyleSVGIcons')
          ),
        },
      ]
    : [];

const routes: Route[] = [
  ...redirectRoutes,

  { path: '/', component: Home, exact: true },
  { path: '/get-started', component: GetStarted, exact: true },

  // Mockups
  { path: '/mockups', component: PageMockup },

  // User / Auth
  { path: '/magic-op/:action/:code', component: MagicOp },
  { path: '/magic/:code', component: MagicLogin },
  { path: '/login', component: Login },
  { path: '/signup/:planSlug/:coupon?', component: Plans },
  { path: '/signup', component: Signup },
  { path: '/invite/:code/:step?', component: Invite },
  { path: '/auth/sent-magic', component: MagicSent },
  { path: '/auth/sent-forgot-password', component: ForgotPasswordSent },
  { path: '/auth/invited', component: AuthInvited },
  { path: '/auth/no-password', component: AuthNoPassword },
  { path: '/auth/unknown', component: AuthUnknown },
  {
    path: '/auth/:flow(welcome-back|wrong-password)?',
    component: Auth,
  },
  { path: '/forgot-password', component: ForgotPassword },
  { path: '/reset-password/:resetCode', component: ResetPassword },
  { path: '/logout', component: Logout },
  { path: '/onboarding/:step?', component: Onboarding },

  // Recommendations
  {
    path: '/submit-recommendation/token/:submissionToken',
    component: SubmitRecommendation,
  },

  // Reference
  // {
  //   path: '/submit-reference/welcome',
  //   component: SubmitReferenceWelcome,
  // },
  {
    path: '/leave-reference/:userId',
    component: SubmitReference,
  },
  {
    path: '/submit-reference/:referenceToken',
    component: SubmitReference,
  },

  // Chat
  ...chatRoutes,

  // Custom Signup flows
  { path: '/landing-aaf', component: LandingAAF },
  { path: '/beats', component: LandingHBCU },
  // { path: '/hbcu-program', component: ProgramHBCU },
  { path: '/hbcu-signup', component: SignupHBCU },
  // { path: '/locomotus/powered-by', component: PoweredByLocomotus },
  { path: '/locomotus/signup', component: SignupLocomotus },
  { path: '/locomotus', component: LandingLocomotus },
  { path: '/getting-to-know-you', component: GetToKnowYou },
  { path: '/welcome', component: Welcome },
  { path: '/welcome-company', component: WelcomeCompany },
  { path: '/getting-to-know-your-company', component: GetToKnowYourAgencyPage },

  // Collections
  { path: '/lists', component: MyCollectionsPage },
  { path: '/lists/:collectionId/:modal?', component: ViewCollectionPage },
  { path: '/bench/:modal?', component: BenchRedirectPage },

  // Collection invites
  {
    path: '/i/:collectionType(bench|list|group)/:inviteId',
    component: CollectionInviteLandingPage,
  },

  // Plans
  {
    path: '/plans/:type(success|upgrade-success)',
    component: PlanSuccess,
  },
  { path: '/plans/:slug/:recurlyId/:coupon?', component: PlanPayment },
  { path: '/plans/:slug', component: PlanFrequency },
  { path: '/plans', component: Plans },
  { path: '/upgrade-actions', component: UpgradeActions },
  {
    path: '/upgrade/:slug/:coupon?',
    component: Upgrade,
  },
  {
    path: '/change-plan/:slug/:userId',
    component: PlanConfirm,
  },

  // Feed
  { path: '/feed', component: Feed },

  // Member / Profile
  { path: '/discover', component: Discover },
  { path: '/members/:id/members', component: MemberMembers },
  { path: '/members', component: Members },
  { path: '/members/:type/:id', component: MemberDetails },

  // Work
  {
    path: '/work-external/create',
    component: WorkExternalCreate,
  },
  {
    path: '/work/create/:mode(preview)?',
    component: WorkInternalCreate,
    exact: true,
  },
  {
    path: '/work/:id/edit/:mode(preview)?',
    component: WorkEdit,
    exact: false,
  },
  {
    path: '/work/:workId/candidates/list/:status?',
    component: WorkCandidates,
  },
  {
    path: '/work/:workId/candidates/public/list/:status?',
    component: WorkCandidatesPublic,
  },
  { path: '/work/:workId', component: WorkDetails },
  { path: '/work', component: Work },

  // Events
  { path: '/events/:id', component: EventDetails },
  { path: '/events', component: Events },

  // Perks
  { path: '/perks', component: Perks },
  { path: '/perks/create', component: PerksCreate },
  { path: '/perks/manage', component: PerksManage },
  { path: '/perks/bundles', component: PerkBundlesManage },
  { path: '/perks/bundles/create', component: PerkBundlesCreate },
  { path: '/perks/bundles/:id', component: PerkBundlesDetail },
  { path: '/perks/bundles/:id/edit', component: PerkBundlesEdit },
  { path: '/perks/:id', component: PerksDetail },
  { path: '/perks/:id/edit', component: PerksEdit },

  // Root
  // { path: '/root', component: Root },
  {
    path: '/root/membership/deactivated',
    component: RootMembershipDeactivated,
  },

  // Generosity
  { path: '/generosity/:favorId?', component: Generosity },
  { path: '/channels', component: Generosity },
  {
    path: '/channels/:sid',
    component: Channel,
  },

  // Video chat
  { path: '/video-chat/:roomId?', component: VideoChat },

  { path: '/image-upload', component: ImageUpload },
  { path: '/testing', component: Testing },
  ...styleguideRoutes,

  // Error 404
  { component: Error404 },
];

// add development routes
if (environment.appEnvironment === 'development') {
  routes.push({ path: '/demos', component: Demos, exact: false });
}

export const appRoutes = routes;

export const modalRoutes = [
  {
    hash: '#/get-vetted',
    component: GetVetted,
  },
  {
    hash: '#/my-stuff/work',
    title: 'My Work',
    component: MyWork,
    height: isMobile ? '100vh' : 'auto',
    exact: false,
  },
  {
    hash: '#/my-stuff/applications',
    title: 'My Applications',
    component: MyApplications,
    height: isMobile ? '100vh' : 'auto',
    exact: false,
  },
  {
    hash: '#/event/create',
    title: 'Create new event post',
    component: (props: any) => {
      page('Add Event');
      return <EventCreate {...props} />;
    },
  },
  {
    hash: '#/event/:id/edit',
    title: 'Edit Event',
    component: (props: any) => {
      const { id: eventId } = props;
      page('Edit Event', { eventId });
      return <EventEdit {...props} />;
    },
  },
  {
    hash: '#/portfolio/:id',
    title: 'Portfolio',
    component: PortfolioView,
    reloadOnDismiss: true,
  },
  {
    hash: '#/company/create',
    title: 'Create a company',
    component: (props: any) => {
      page('Add Company');
      return <CreateCompanyForm {...props} />;
    },
  },
];

export const AppModalRoutes = () => (
  <>
    {modalRoutes.map(
      ({
        hash,
        title,
        component: Component,
        backdropDismiss,
        reloadOnDismiss = false,
        exact = true,
        ...rest
      }: any) => (
        <ModalRoute
          key={hash}
          hash={hash}
          title={title || null}
          component={Component}
          backdropDismiss={backdropDismiss}
          reloadOnDismiss={reloadOnDismiss}
          exact={exact}
          {...rest}
        />
      )
    )}
  </>
);
