import { parse } from 'querystringify';
import React, { useContext, useMemo } from 'react';
import { useLocation } from 'react-router';
import useAnalytics from '../../hooks/useAnalytics';
import usePageViews from '../../hooks/usePageViews';
import useWork from '../../hooks/useWork';
import useWorkApplications from '../../hooks/useWorkApplications';
import { timeAgo } from '../../utilities/dateTime';
import { isDesktop } from '../../utilities/MatchMedia';
import { LoadingBalls } from '../core/Loading';
import { Link } from '../core/router';
import UserContext from '../user/Context';
import ListWorkApplications from './ListWorkApplications';
import UnreadCandidateMessages from './UnreadCandidateMessages';

type Props = {
  workId: string;
  status: ApplicationStatus;
};

type WorkInfo = {
  work: WorkEntity | null;
  pageViews: PageView | null;
  applications: KeyedApplications;
};

type Params = {
  applicationId?: string;
  status?: string;
};

const WorkInfo = ({ work, pageViews, applications }: WorkInfo) => {
  const { isRoot } = useContext(UserContext);

  if (!work) return <LoadingBalls isActive fullscreen />;

  return (
    <div className="flex mt-8 mb-6">
      <div className="md:w-1/2">
        <div className="flex mb-2">
          <h2 className="text-xl font-extralight uppercase mr-2">Candidates</h2>
          <h5 className="text-xl">
            <Link to={`/work/${work.id}`}>{work.title}</Link>
          </h5>
        </div>
        <div className="flex">
          <div className="mr-8 flex">
            <div className="uppercase font-extralight mr-2">Posted</div>
            <div className="font-medium">{timeAgo(work.createdAt)}</div>
          </div>
          <div className="mr-8 flex">
            <div className="uppercase font-extralight mr-2">Views</div>
            <div className="font-medium">{pageViews?.total || 0}</div>
          </div>
          {isRoot && (
            <div className="flex">
              <div className="uppercase font-extralight mr-2">Unique Views</div>
              <div className="font-medium">{pageViews?.unique || 0}</div>
            </div>
          )}
        </div>
      </div>
      <div className="md:w-1/2">
        <div className="flex flex-col flex-1 items-end">
          <UnreadCandidateMessages applications={applications} />
        </div>
      </div>
    </div>
  );
};

const CandidatesPublic = ({ workId, status }: Props) => {
  const { search } = useLocation();
  const work = useWork(workId);
  const [state, , , patch, , , hire] = useWorkApplications(workId);
  const applications = state.data;
  const pageViews = usePageViews('work', workId);
  const { applicationId } = parse(search) as Params;
  useAnalytics('Work Candidates', { workId });

  const selected = useMemo(() => state.data[applicationId || ''] || null, [
    applicationId,
    state.data,
  ]);

  return (
    <div className="h-full max-h-full">
      <LoadingBalls isActive={state.loading || work.loading} fullscreen />
      <div className="h-full flex flex-col">
        {isDesktop && (
          <WorkInfo
            work={work.data}
            pageViews={pageViews.data}
            applications={applications}
          />
        )}
        {!state.loading && work.data && (
          <ListWorkApplications
            patchApplications={patch}
            applications={applications}
            hire={hire}
            selected={selected}
            work={work.data}
            status={status}
          />
        )}
      </div>
      {/* Display Selected (modal / preview pane) */}
    </div>
  );
};

export default React.memo(CandidatesPublic);
