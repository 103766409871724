import React, { FunctionComponent, useContext } from 'react';
import Button from '../core/Button';
import UserContext from '../user/Context';
import schedulerApi from './Api';
import { createSlug } from './Util';

type MeetingData = {
  editToken: string | null;
  slug: string | null;
};

type SchedulerProps = {
  application: ApplicationEntity;
  work: WorkEntity;
  meetingData: MeetingData | null;
  onSetMeetingData: (md: MeetingData) => void;
};

const Scheduler: FunctionComponent<SchedulerProps> = ({
  application,
  work,
  meetingData,
  onSetMeetingData,
}) => {
  const { user } = useContext(UserContext);

  const handleClick = (): void => {
    (window as any).nylas.scheduler.show({
      auth: {
        pageEditToken: meetingData?.editToken,
      },
      style: {
        tintColor: '#32325d',
        backgroundColor: 'white',
      },
      defaults: {
        event: {
          title: `${user!.firstName} & ${application.user.firstName} meeting`,
          duration: 30,
        },
      },
    });
  };

  const createPage = async () => {
    const slug = createSlug(work.id, application.id);
    const response = await schedulerApi.newPage({ slug });
    onSetMeetingData(response);
  };

  if (!meetingData?.editToken) {
    return (
      <Button
        id="schedule-meeting-event"
        type="button"
        onClick={createPage}
        color="primary"
        className="flex-1"
      >
        <i className="i-calendar btn__icon" />
        <span className="btn__text">Create Meeting Event</span>
      </Button>
    );
  }

  return (
    <>
      <Button
        id="schedule-meeting-event"
        type="button"
        onClick={handleClick}
        color="primary"
        className="flex-1"
      >
        <i className="i-calendar btn__icon" />
        <span className="btn__text">EDIT Availability Meeting Event</span>
      </Button>
      {meetingData?.slug && (
        <>
          <h4>Meeting Link</h4>
          <a href={`${'https://schedule.nylas.com'}/${meetingData?.slug}`}>
            {`${'https://schedule.nylas.com'}/${meetingData?.slug}`}
          </a>
        </>
      )}
    </>
  );
};

export default Scheduler;
