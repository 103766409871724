import React from 'react';
import ResetPassword from '../components/user/views/ResetPassword';
import Page from '../components/core/Page';
import SignupLayout from '../components/user/signup/SignupLayout';

export default ({ match }: any) => {
  const { resetCode } = match.params;

  return (
    <Page
      layout="basic"
      className="page-bg--white"
      pageTitle="Reset Password"
      mobileHeader={() => null}
    >
      <SignupLayout headerImg="https://res.cloudinary.com/communo/image/upload/v1589576439/onboarding/onboarding-user-password.jpg">
        <>
          <ResetPassword resetCode={resetCode} />
        </>
      </SignupLayout>
    </Page>
  );
};
