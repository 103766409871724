import React, { useState } from 'react';
import { IonAlert } from '@ionic/react';
import { useHistory } from 'react-router';
import useChatUser from '../hooks/useChatUser';
import { LoadingBalls } from '../../core/Loading';
import { ChatUserProfile } from '../components/ChannelProfile';
import { IChannel } from '../../../@types/chat.d';
import useCurrentUser from '../../../hooks/useCurrentUser';
import useAnalytics from '../../../hooks/useAnalytics';
import useChatChannelMember from '../hooks/useChatChannelMember';
import channelMemberRole from '../utilities/channelMemberRole';
import {
  ListActions,
  ListActionsItemLink,
  ListActionsItemButton,
} from '../../listActions';
import useChatChannelInstance from '../hooks/useChatChannelInstance';
import MemberMuteChannel from '../components/MemberMuteChannel';
import MemberLeaveChannel from '../components/MemberLeaveChannel';

const RemoveIcon = () => <i className="i-alert-remove text-grey-600 text-lg" />;

const MemberSettings = ({
  channel,
  identity,
}: {
  channel: IChannel;
  identity: string;
}) => {
  const { sid, attributes } = channel;

  useAnalytics('Conversation Member', {
    conversationId: sid,
    userId: identity,
  });

  const history = useHistory();
  const { channelInstance } = useChatChannelInstance(sid);
  const { user: currentUser } = useCurrentUser();

  const { user, loaded } = useChatUser(identity);
  // Member who is interacting
  const { member: userMember } = useChatChannelMember(
    sid,
    currentUser?.id || ''
  );

  // Member whos settings are being viewed
  const { member } = useChatChannelMember(sid, identity);

  const [showRemovePrompt, setShowRemovePrompt] = useState(false);

  if (!currentUser || !user || !member || !userMember || !loaded)
    return <LoadingBalls isActive fullscreen />;

  const isCurrentUser = currentUser.id === identity;
  const { isChannelAdmin } = channelMemberRole(userMember.roleSid);
  const isGenerosityGroup =
    attributes.type === 'generosity' &&
    attributes.favor?.visibility === 'public';
  const isGroupChannel = attributes.type === 'group' || isGenerosityGroup;

  const { isDeactivated } = user.attributes;

  const handleRemoveFromGroup = async () => {
    if (!isChannelAdmin) return;

    await channelInstance.removeParticipant(identity);
    history.goBack();
  };

  return (
    <>
      <ChatUserProfile channelSid={sid} identity={identity} />

      <ListActions>
        {!isDeactivated ? (
          <ListActionsItemLink linkTo={`/members/user/${identity}`}>
            View profile
          </ListActionsItemLink>
        ) : null}

        {isChannelAdmin && !isCurrentUser && isGroupChannel ? (
          <ListActionsItemButton
            end={<RemoveIcon />}
            onClick={() => setShowRemovePrompt(true)}
          >
            Remove from group
          </ListActionsItemButton>
        ) : null}

        {isCurrentUser && isGroupChannel ? (
          <MemberLeaveChannel channel={channel} />
        ) : null}

        {isCurrentUser ? <MemberMuteChannel member={member} /> : null}
      </ListActions>

      <IonAlert
        isOpen={showRemovePrompt}
        onDidDismiss={() => {
          setShowRemovePrompt(false);
        }}
        header="Remove participant?"
        message="Are you sure you want to remove this participant from the conversation?"
        buttons={[
          {
            text: 'Cancel',
            role: 'cancel',
            handler: () => {},
          },
          {
            text: 'Remove',
            handler: handleRemoveFromGroup,
          },
        ]}
      />
    </>
  );
};

export default MemberSettings;
