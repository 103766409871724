import React, { useCallback, useEffect, useRef, useState } from 'react';
import { ToastProvider, useToasts } from 'react-toast-notifications';
import Alert from './components/alert/Alert';

// context variables and functions
export interface AppInterface {
  name: string;
  refreshing: boolean;
  updateAvailable: boolean;
  ionContentRef: any;
  pageRef: any;
  setName(name: string): void;
  setDocumentTitle(pageTitle?: string): void;
  refresh(): void;
}

const appInterface: AppInterface = {
  name: '',
  refreshing: false,
  updateAvailable: false,
  ionContentRef: null,
  pageRef: null,
  setName() {},
  setDocumentTitle() {},
  refresh() {},
};

const AppContext = React.createContext<AppInterface>(appInterface);

export const AppProvider = ({ children }: { children: any }) => {
  const [name, setName] = useState<string>('Communo');
  const [updateAvailable, setUpdateAvailable] = useState<boolean>(false);
  const [alerts, setAlerts] = useState<Message[]>([]);
  const [refreshing, setRefreshing] = useState(false);
  const ionContentRef = useRef(null);
  const pageRef = useRef(null);
  const { addToast, removeToast } = useToasts();

  const setMessage = useCallback(
    (
      message: string,
      type: MessageType,
      autoDismiss: boolean = true,
      callback?: (id: string) => void
    ) => {
      addToast(
        message,
        { appearance: type, autoDismiss },
        callback || undefined
      );
    },
    [addToast]
  );

  const setAlert = useCallback((message: string, type: MessageType) => {
    setAlerts(stateMessages => [
      ...stateMessages,
      {
        id: Math.random()
          .toString(36)
          .substring(2, 15),
        message,
        type,
      } as Message,
    ]);
  }, []);

  const setDocumentTitle = useCallback(
    (title?: string) => {
      document.title = title ? `${title} | ${name}` : name;
    },
    [name]
  );

  const handleRefresh = useCallback(() => {
    setRefreshing(true);
    setTimeout(() => {
      setRefreshing(false);
    }, 10);
  }, []);

  useEffect(() => {
    document.addEventListener('toast', (e: any) => {
      setMessage(
        e.detail.message,
        e.detail.type || 'success',
        e.detail.data?.autoDismiss,
        e.detail.data?.callback
      );
    });
  }, [setMessage]);

  useEffect(() => {
    document.addEventListener('removeToast', (e: any) => {
      removeToast(e.detail.id);
    });
  }, [removeToast]);

  useEffect(() => {
    document.addEventListener('alert', (e: any) => {
      setAlert(e.detail.message, e.detail.type || 'success');
    });
  }, [setAlert]);

  useEffect(() => {
    document.addEventListener('updateAvailable', () => {
      setUpdateAvailable(true);
    });
  }, []);

  return (
    <AppContext.Provider
      value={{
        name,
        refreshing,
        updateAvailable,
        setName,
        setDocumentTitle,
        ionContentRef,
        pageRef,
        refresh: handleRefresh,
      }}
    >
      {children}
      {alerts.map(item => (
        <Alert
          key={item.id}
          isOpen={!!item.message}
          header="Oops!"
          message={item.message}
          buttons={[
            {
              text: 'Close',
              // cssClass: 'alert-primary-btn',
              handler: () => {},
            },
          ]}
          onDidDismiss={() => {}}
        />
      ))}
    </AppContext.Provider>
  );
};

export const AppContextConsumer = AppContext.Consumer;

export const AppContextProvider = ({ children }: any) => (
  <ToastProvider autoDismiss autoDismissTimeout={6000} placement="bottom-right">
    <AppProvider>{children}</AppProvider>
  </ToastProvider>
);

export default AppContext;
