import jwtDecode from 'jwt-decode';
import cn from 'classnames';
import { parse } from 'querystringify';
import React, { useCallback, useContext, useMemo } from 'react';
import { useLocation } from 'react-router';
import useAnalytics from '../../hooks/useAnalytics';
import usePageViews from '../../hooks/usePageViews';
import useWork from '../../hooks/useWork';
import useWorkApplications from '../../hooks/useWorkApplications';
import { addSuccess } from '../../services/Messaging';
import { timeAgo } from '../../utilities/dateTime';
import { isDesktop } from '../../utilities/MatchMedia';
import { appUrl } from '../../utilities/Url';
import { LoadingBalls } from '../core/Loading';
import { Link } from '../core/router';
import UserContext from '../user/Context';
import ListWorkApplications from './ListWorkApplications';
import UnreadCandidateMessages from './UnreadCandidateMessages';
import { ENABLE_PUBLIC_CANDIDATE_PAGE } from '../../config';
import { isMobile } from '../../utilities/Device';
import CandidateBreadcrumbs, { Breadcrumb } from './CandidateBreadcrumbs';

type Props = {
  workId: string;
  status: ApplicationStatus;
};

type WorkInfo = {
  work: WorkEntity | null;
  pageViews: PageView | null;
  applications: KeyedApplications;
  publicUrl: string | null;
  isPublicUrlExpired: boolean;
  doCreatePublicUrl: () => void;
  doDeletePublicUrl: () => void;
  workId: string;
};

type Params = {
  applicationId?: string;
  status?: string;
};

const WorkInfo = ({
  work,
  pageViews,
  applications,
  publicUrl,
  isPublicUrlExpired,
  doCreatePublicUrl,
  doDeletePublicUrl,
  workId,
}: WorkInfo) => {
  const { isRoot } = useContext(UserContext);

  if (!work) return <LoadingBalls isActive fullscreen />;

  const copyToClipboard = (textToCopy: string) => {
    navigator.clipboard.writeText(textToCopy || '');
    addSuccess('URL copied to clipboard!');
  };

  const createPublicUrl = () => {
    doCreatePublicUrl();
    addSuccess('Public URL generated!');
  };

  const deletePublicUrl = () => {
    doDeletePublicUrl();
    addSuccess('Public URL deleted!');
  };

  const breadcrumbs: Breadcrumb[] = [
    {
      title: 'Homepage',
      linkTo: '',
    },
    {
      title: 'Work',
      linkTo: '/work',
    },
    {
      title: work.title || 'Details',
      linkTo: `/work/${workId}`,
    },
    {
      title: 'Candidate Dashboard',
      linkTo: `/work/${workId}/candidates/list`,
    },
  ];

  return (
    <div className="flex justify-between mt-8 mb-6">
      <div>
        <div className="flex flex-col mb-2 gap-2">
          <CandidateBreadcrumbs crumbs={breadcrumbs} />
          <h5 className="text-3xl max-w-[50ch] whitespace-nowrap overflow-hidden overflow-ellipsis">
            {!!work.company && <span>{work.company?.name} - </span>}
            <Link to={`/work/${work.id}`}>{work.title}</Link>
          </h5>
        </div>
        <div className="flex">
          <div className="mr-8 flex">
            <div className="uppercase font-extralight mr-2">Posted</div>
            <div className="font-medium">{timeAgo(work.createdAt)}</div>
          </div>
          <div className="mr-8 flex">
            <div className="uppercase font-extralight mr-2">Views</div>
            <div className="font-medium">{pageViews?.total || 0}</div>
          </div>
          {isRoot && (
            <div className="flex">
              <div className="uppercase font-extralight mr-2">Unique Views</div>
              <div className="font-medium">{pageViews?.unique || 0}</div>
            </div>
          )}
        </div>
      </div>

      <div className="md:block ml-4">
        <div className="flex flex-col flex-1 items-end">
          <UnreadCandidateMessages applications={applications} />
        </div>
      </div>

      {ENABLE_PUBLIC_CANDIDATE_PAGE && (
        <div className="flex flex-col items-end justify-end ml-auto w-fit">
          {!!publicUrl && (
            <div className="flex flex-col items-end mb-2 w-fit h-12">
              <div className="font-bold">Access url</div>
              <div className="flex border rounded-md border-grey-400 h-8 max-h-8 text-sm truncate align-center">
                <div className="flex items-center px-2 text-grey-400">
                  {isPublicUrlExpired ? (
                    <div className="text-danger-900 font-bold w-full">
                      URL is Expired
                    </div>
                  ) : (
                    <a
                      href={publicUrl}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="w-full"
                    >
                      {publicUrl.slice(8, 27)}
                    </a>
                  )}
                </div>
              </div>
            </div>
          )}
          {!!publicUrl && (
            <button
              type="button"
              className="text-danger-900 hover:text-danger-1100 font-bold text-xs underline cursor-pointer ml-auto"
              onClick={e => {
                e.preventDefault();
                deletePublicUrl();
              }}
            >
              Delete Link
            </button>
          )}
          <button
            className="btn btn--tertiary btn--sm whitespace-nowrap"
            type="button"
            onClick={() => {
              return publicUrl && !isPublicUrlExpired
                ? copyToClipboard(publicUrl || '')
                : createPublicUrl();
            }}
          >
            <span className="btn__text">
              {publicUrl && !isPublicUrlExpired ? (
                <span>
                  <i className="i-link" /> Copy to clipboard
                </span>
              ) : (
                'Generate Presentation'
              )}
            </span>
          </button>
        </div>
      )}
    </div>
  );
};

const Candidates = ({ workId, status }: Props) => {
  const { search } = useLocation();
  const { data, loading, refresh } = useWork(workId);
  const [
    state,
    ,
    ,
    patch,
    createPublicUrl,
    deletePublicUrl,
    hire,
  ] = useWorkApplications(workId);
  const applications = state.data;
  const pageViews = usePageViews('work', workId);
  const { applicationId } = parse(search) as Params;
  useAnalytics('Work Candidates', { workId });

  const selected = useMemo(() => state.data[applicationId || ''] || null, [
    applicationId,
    state.data,
  ]);

  const isPublicUrlExpired = useMemo(() => {
    if (!data?.magicPublicJwt) {
      return false;
    }

    const decodedAccessToken: DecodedAccessToken = jwtDecode(
      data.magicPublicJwt
    );

    // Get current time in epoch seconds.
    const now = Math.floor(Date.now() / 1000);
    return decodedAccessToken.exp < now;
  }, [data]);

  const publicUrl = useMemo(() => {
    if (!data?.magicPublicJwt) return null;

    return appUrl(
      `/work/${workId}/candidates/public/list?=magicKey=${data.magicPublicJwt}`
    );
  }, [data, workId]);

  const doCreatePublicUrl = useCallback(async () => {
    await createPublicUrl();
    refresh();
  }, [createPublicUrl, refresh]);

  const doDeletePublicUrl = useCallback(async () => {
    await deletePublicUrl();
    refresh();
  }, [deletePublicUrl, refresh]);

  return (
    <div className={cn('h-full max-h-full', isMobile ? '' : 'pb-8')}>
      <LoadingBalls isActive={state.loading || loading} fullscreen />
      <div className="flex flex-col h-full">
        {isDesktop && (
          <WorkInfo
            work={data}
            pageViews={pageViews.data}
            applications={applications}
            publicUrl={publicUrl}
            isPublicUrlExpired={isPublicUrlExpired}
            doCreatePublicUrl={doCreatePublicUrl}
            doDeletePublicUrl={doDeletePublicUrl}
            workId={workId}
          />
        )}
        {!state.loading && data && (
          <ListWorkApplications
            patchApplications={patch}
            applications={applications}
            hire={hire}
            selected={selected}
            work={data}
            status={status}
          />
        )}
      </div>
      {/* Display Selected (modal / preview pane) */}
    </div>
  );
};

export default React.memo(Candidates);
