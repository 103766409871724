import { brandProdFeatureFlags } from './brandFeatureFlags';
import stage from './stage';
import { Environment } from '.';

// DO NOT UPDATE THE VALUE OF THE BRAND
// FEATURE FLAGS ON THIS REPO.
// UPDATE THEM THROUGH THE ASSOCIATED BRAND PACKAGE.
const brandEnvironment: Environment = {
  ...stage,
  appUrl: 'https://apex-prod.communo.com',
  ...brandProdFeatureFlags,
  doDeployUpdate: true,
};

export default brandEnvironment;
