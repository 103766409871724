import React, { useContext } from 'react';
import { useIonViewWillEnter } from '@ionic/react';
import querystringify from 'querystringify';
import useNavigate from '../hooks/useNavigate';
import Page from '../components/core/Page';
import UserContext from '../components/user/Context';
import Authenticate from '../components/user/views/Authenticate';

const AuthPage = ({ match: { params } }: any) => {
  const { flow } = params;
  const { redirectUrl } = querystringify.parse(window.location.search) as any;
  const { authDetails } = useContext(UserContext);
  const navigate = useNavigate();

  useIonViewWillEnter(() => {
    if (!authDetails.email) {
      const url = redirectUrl ? `/login?redirectUrl=${redirectUrl}` : '/login';
      navigate(url, 'none', 'replace');
    }
  }, [authDetails.email]);

  return (
    <Page
      layout="basic"
      className="page-bg--white"
      pageTitle="Login / Sign Up"
      mobileHeader={() => null}
      darkStatusBar
      hideHeader
    >
      {authDetails.email && <Authenticate flow={flow || ''} />}
    </Page>
  );
};

export default AuthPage;
