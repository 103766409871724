import React from 'react';
import Page from '../components/core/Page';
import VideoRecorder from '../components/videoRecorder/VideoRecorder';

const VideoChat = () => {
  return (
    <Page authGuard pageTitle="Testing">
      <div className="mx-auto my-10">
        <p className="text-lg">Normal video</p>
        <VideoRecorder
          onVideoChanged={video => {
            // eslint-disable-next-line no-console
            console.log('Video changed', video);
          }}
        />

        <p className="mt-4 text-lg">Transcribed video</p>
        <VideoRecorder
          transcribe
          modalTitle="Transcribed Video Uploader"
          onVideoChanged={video => {
            // eslint-disable-next-line no-console
            console.log('Transcribed video changed', video);
          }}
        />
      </div>
    </Page>
  );
};

export default React.memo(VideoChat);
