import React from 'react';
import {
  FREELANCER_MEMBER_SLUG,
  GUEST_AGENCY_PLAN_SLUG,
  GUEST_BUSINESS_PLAN_SLUG,
} from '../../../config';
import { Link } from '../../core/router';
import useClientBrandingStore from '../../../hooks/stores/useClientBrandingStore';
import ToolTip from '../../core/ToolTip';

const GetStarted = () => {
  const brandColor = useClientBrandingStore(
    state => state.clientBranding?.brandColor
  );

  return (
    <div>
      <div className="text-white">
        <h3 className="text-center">What do you want to do?</h3>
        <p className="text-center text-large my-8">
          Communo is home to thousands of experts who are looking to connect,
          collaborate and do great work together.
        </p>
      </div>
      <div className="max-w-xs mx-auto mt-6 text-center flex flex-col gap-8">
        <ToolTip
          message="Find expert talent for project, part or full-time work."
          positionClasses="-bottom-7"
        >
          <Link
            to={`/signup?redirectUrl=/plans/${GUEST_BUSINESS_PLAN_SLUG}`}
            className="btn btn--sm btn--primary btn--rev w-full flex-wrap flex-col relative"
            style={{
              color: brandColor ?? 'var(--com-color-red-900)',
            }}
          >
            <span className="font-bold text-base">I want to hire</span>
          </Link>
        </ToolTip>
        <ToolTip
          message="Find project, part, or full-time work opportunities."
          positionClasses="-bottom-7"
        >
          <Link
            to={`/signup?redirectUrl=/plans/${FREELANCER_MEMBER_SLUG}&type=individual`}
            className="btn btn--sm btn--primary btn--rev w-full flex-col"
            style={{
              color: brandColor ?? 'var(--com-color-red-900)',
            }}
          >
            <span className="font-bold text-base">I want to find work</span>
          </Link>
        </ToolTip>
        <div className="text-white">
          <h3 className="text-center font-normal">OR</h3>
        </div>
        <ToolTip
          message="Find talent and work opportunities."
          positionClasses="-bottom-7"
        >
          <Link
            to={`/signup?redirectUrl=/plans/${GUEST_AGENCY_PLAN_SLUG}`}
            className="btn btn--sm btn--primary btn--rev w-full flex-col"
            style={{
              color: brandColor ?? 'var(--com-color-red-900)',
            }}
          >
            <span className="font-bold text-base">I am an agency</span>
          </Link>
        </ToolTip>
      </div>
    </div>
  );
};
export default GetStarted;
