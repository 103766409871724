import React from 'react';
import useChatUser from '../hooks/useChatUser';
import splitName from '../../../utilities/splitName';
import {
  TwilioConversationFavorAttributes,
  TwilioConversationAttributes,
} from '../../../@types/chat.d';
import useCurrentUser from '../../../hooks/useCurrentUser';
import useChatOtherMember from '../hooks/useChatOtherMember';

const DirectChannelNamePresenter = ({ identity }: { identity: string }) => {
  const { user } = useChatUser(identity);

  if (!user) return null;

  const { friendlyName } = user;

  return <>{friendlyName}</>;
};

export const DirectChannelName = ({ channelSid }: { channelSid: string }) => {
  const { member } = useChatOtherMember(channelSid);

  if (!member) return null;

  return <DirectChannelNamePresenter identity={member.identity} />;
};

export const GroupChannelName = ({
  friendlyName,
}: {
  friendlyName: string;
}) => <>{friendlyName}</>;

const GenerosityPrivateName = ({
  favor,
  friendlyName,
}: {
  favor: TwilioConversationFavorAttributes;
  friendlyName: string;
}) => {
  const { user: currentUser } = useCurrentUser();
  const { user } = useChatUser(favor.senderUserId);

  if (!user || !currentUser) return null;

  if (favor.senderUserId === currentUser.id) return <>{friendlyName}</>;

  const firstName = `${splitName(user.friendlyName)?.first} - ` || '';

  return <>{firstName + friendlyName}</>;
};

export const GenerosityChannelName = ({
  favor,
  friendlyName,
}: {
  favor: TwilioConversationFavorAttributes;
  friendlyName: string;
}) => {
  if (favor.visibility === 'private')
    return <GenerosityPrivateName favor={favor} friendlyName={friendlyName} />;

  return <>{friendlyName}</>;
};

/**
 * ChannelName
 *
 * Automatically determines which format of name to display
 */
const ChannelName = ({
  channelSid,
  friendlyName,
  attributes,
}: {
  channelSid: string;
  friendlyName: string;
  attributes: TwilioConversationAttributes;
}) => {
  const { type } = attributes;

  // This component renders for channel messages
  if (type === 'generosity') {
    const { favor } = attributes;

    if (!favor) return null;
    return <GenerosityChannelName favor={favor} friendlyName={friendlyName} />;
  }

  // friendly names are assigned to group chats
  if (friendlyName) {
    return <GroupChannelName friendlyName={friendlyName} />;
  }

  // Direct messages fetch the users name through the channelSid
  return <DirectChannelName channelSid={channelSid} />;
};

export default React.memo(ChannelName);
