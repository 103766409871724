import { Capacitor } from '@capacitor/core';
import {
  IonBackButton,
  IonIcon,
  IonLabel,
  IonSegment,
  IonSegmentButton,
} from '@ionic/react';
import { useQueryClient } from '@tanstack/react-query';
import classnames from 'classnames';
import { addCircle } from 'ionicons/icons';
import React, { useEffect, useState } from 'react';
import { page } from '../../analytics';
import useCurrentMembership from '../../hooks/useCurrentMembership';
import useNavigate from '../../hooks/useNavigate';
import { addSuccess } from '../../services/Messaging';
import { isMobile } from '../../utilities/Device';
import uuid from '../../utilities/uuid';
import CreateChannelForm from './CreateChannelForm';
import JoinChannel from './JoinChannel';
import Pane from './Pane';
import ChannelQueries from './queries';
import UnjoinedChannelsCards from './UnjoinedChannelsCards';
import JoinedChannelsCards from './JoinedChannelsCards';

interface PaneProps {
  onCancel: Function;
  isActive: boolean;
  cardId: TCardId;
}

const defaultPaneState = {
  onCancel: () => {},
  isActive: false,
  cardId: '',
};

const View = () => {
  const navigate = useNavigate();
  const [helpPane, setHelpPane] = useState<PaneProps>(defaultPaneState);
  const [createPane, setCreatePane] = useState(false);
  const [, setMultiBtnActive] = useState(false);
  const [currentTab, setCurrentTab] = useState<'all' | 'mine'>('all');
  const { canCreateChannels } = useCurrentMembership().plan as PlanEntity;
  const interactWithChannel = ChannelQueries.useInteractWithChannel();

  // A bit of a hacky way to ensure the Mine tab is refeshed
  // when a new favor is created by the user
  const [refreshKey, setRefreshKey] = useState(uuid());

  const handleCreateComplete = () => {
    addSuccess('Card has been created.');
    setCreatePane(false);
    setMultiBtnActive(false);
    setRefreshKey(uuid());
  };

  const queryClient = useQueryClient();

  const handleChannelView = (id: TCardId) => {
    interactWithChannel.mutate(
      { cardId: id.toString() },
      {
        onSuccess: d => {
          queryClient.invalidateQueries(['channels']);
          navigate(`/chat/conversation/${d.conversationId}`);
        },
      }
    );
  };

  const handleTabChange = (e: any) => setCurrentTab(e.detail.value);

  useEffect(() => {
    page('Generosity', { view: currentTab });
  }, [currentTab]);

  return (
    <>
      <div className="lg:relative">
        <div className="flex generosity__header">
          {isMobile ? (
            <IonBackButton
              className="text-grey-900 text-base z-10"
              defaultHref="/feed"
            />
          ) : (
            <></>
          )}
          <div className="generosity__segment">
            <IonSegment
              value={currentTab}
              mode="ios"
              onIonChange={handleTabChange}
            >
              <IonSegmentButton value="all">
                <IonLabel>New</IonLabel>
              </IonSegmentButton>
              <IonSegmentButton value="mine">
                <IonLabel>Joined</IonLabel>
              </IonSegmentButton>
            </IonSegment>
          </div>
          {canCreateChannels && (
            <button
              type="button"
              className="self-center ml-4 mt-1"
              onClick={() => setCreatePane(true)}
            >
              <span id="add-channel-label" hidden>
                Add a channel
              </span>
              <IonIcon size="large" icon={addCircle} />
            </button>
          )}
        </div>

        <div className="w-fit items-center">
          <UnjoinedChannelsCards
            currentTab={currentTab}
            onView={handleChannelView}
          />

          <JoinedChannelsCards
            currentTab={currentTab}
            handleTabChange={setCurrentTab}
            onView={handleChannelView}
            key={refreshKey}
          />
        </div>

        <Pane
          name="offer help"
          isActive={helpPane.isActive}
          transition="slideFromLeft"
          className={classnames({
            'pt-1 px-0': Capacitor.isNativePlatform(),
            'pt-16': !Capacitor.isNativePlatform(),
          })}
        >
          <JoinChannel
            onClosePane={() => {
              setHelpPane(state => ({
                ...state,
                isActive: false,
              }));
            }}
            cardId={helpPane.cardId}
          />
        </Pane>

        <Pane
          name="create favor"
          isActive={createPane}
          transition="slideFromBottom"
          className={classnames({
            'pt-10 px-2': Capacitor.isNativePlatform(),
            'pt-28 md:pt-40': !Capacitor.isNativePlatform(),
          })}
        >
          <CreateChannelForm
            onCancel={() => setCreatePane(false)}
            onComplete={handleCreateComplete}
          />
        </Pane>
      </div>
    </>
  );
};

export default View;
