import React, { useMemo } from 'react';
// import LoginForm from '../forms/LoginForm';
import AuthForm from '../forms/AuthForm';
import useAnalytics from '../../../hooks/useAnalytics';
import Capitalize from '../../../utilities/Capitalize';
import SignupLayout from '../signup/SignupLayout';

type Props = {
  flow?: string;
};

const flows = {
  default: {
    heading: 'Enter your password.',
    copy: 'Just need to verify that you are indeed you.',
    imageSrc:
      'https://res.cloudinary.com/communo/image/upload/v1589576439/onboarding/onboarding-user-password.jpg',
  },
  welcomeBack: {
    heading: 'Welcome Back.',
    copy: 'You already have an account, just sign in.',
    imageSrc:
      'https://res.cloudinary.com/communo/image/upload/v1589576439/onboarding/onboarding-login-welcome-back.jpg',
  },
  wrongPassword: {
    heading: "Hmm, that's not right.",
    copy: 'You already have an account, just sign in.',
    imageSrc:
      'https://res.cloudinary.com/communo/image/upload/v1589576439/onboarding/onboarding-login-password-incorrect.jpg',
  },
};

const Auth = ({ flow }: Props) => {
  const { heading, copy, imageSrc } = useMemo(() => {
    if (flow === 'welcome-back') return flows.welcomeBack;
    if (flow === 'wrong-password') return flows.wrongPassword;
    return flows.default;
  }, [flow]);

  const pageName = flow
    ? flow
        .split('-')
        .map((str: string) => Capitalize(str))
        .join(' ')
    : 'Enter Your Password';

  useAnalytics(pageName);

  return (
    <SignupLayout headerImg={imageSrc}>
      <div>
        <div className="text-center mb-8">
          <h3 className="mb-2">{heading}</h3>
          <div className="text-lg">{copy}</div>
        </div>

        <AuthForm />
      </div>
    </SignupLayout>
  );
};

export default Auth;
