import React, { useEffect } from 'react';
import { useHistory } from 'react-router';
import Page from '../components/core/Page';
import useAnalytics from '../hooks/useAnalytics';
import GetStarted from '../components/user/signup/GetStarted';
import useCurrentUser from '../hooks/useCurrentUser';
import SignupLayout from '../components/user/signup/SignupLayout';

const GetStartedPage = () => {
  const { isAuthenticated } = useCurrentUser();
  const { replace } = useHistory();

  useAnalytics('Get Started');

  useEffect(() => {
    // Prevent authenticated users but only on first render. Causes a feed flash when their
    // auth state changes and page is still hydrated in background.
    if (isAuthenticated) {
      replace('/feed');
    }
    // eslint-disable-next-line
  }, []);

  // if (isAuthenticated) {
  //   return <Redirect to="/feed" />;
  // }

  return (
    <Page
      layout="basic"
      className="page-bg--red"
      mobileHeader={() => null}
      hideHeader
    >
      <SignupLayout theme="dark">
        <div>
          <GetStarted />
        </div>
      </SignupLayout>
    </Page>
  );
};

export default React.memo(GetStartedPage);
