import React from 'react';
import UserCard, { Badges } from './UserCard';

interface Props {
  user: UserEntity;
  onUserCollectionUpdate?: (collectionIdsUserIsIn: string[]) => void;
}

function determineBadge(user: UserEntity): Badges | undefined {
  const membership = user.primaryMembershipUser?.membership;

  if (membership?.plan.type === 'student') {
    return 'student';
  }
  // if (membership?.plan.type !== 'individual') {
  //   return 'employee';
  // }

  if (
    membership?.vettingStatus === 'pending' &&
    membership?.plan.type === 'individual'
  ) {
    return 'verified';
  }

  if (
    membership?.vettingStatus === 'vetted' &&
    membership?.plan.type === 'individual'
  ) {
    return 'vetted';
  }

  return undefined;
}

const UserEntityCard = ({ user, onUserCollectionUpdate }: Props) => {
  const id = user.id || user.objectID;
  const { avatarURL, firstName, roles } = user;
  const membership = user.primaryMembershipUser?.membership;
  const { role } = roles[0];
  const badge = determineBadge(user);
  const isEmployee = membership?.plan.type !== 'individual';
  const worksAt = isEmployee ? membership?.name : undefined;
  const logoUrl = isEmployee ? membership?.logoURL : undefined;

  if (!id) {
    return null;
  }

  return (
    <UserCard
      userId={id}
      avatarUrl={avatarURL}
      name={firstName}
      userRole={role.name}
      // location={location?.friendlyName}
      badge={badge}
      onUserCollectionUpdate={onUserCollectionUpdate}
      worksAt={worksAt}
      logoUrl={logoUrl}
    />
  );
};
export default UserEntityCard;
