import { IonLabel, IonSegment, IonSegmentButton } from '@ionic/react';
import cn from 'classnames';
import React, { useContext, useMemo, useState, useEffect } from 'react';
import ReactStars from 'react-stars';
import { SHOW_APPLICANT_NOTES, SHOW_SCHEDULER } from '../../config';
import { ForceMobileContextProvider } from '../../context/ForceMobileContext';
import useApplicationNotes from '../../hooks/useApplicationNotes';
import useUser from '../../hooks/useUser';
import useImportScript from '../../hooks/useImportScript';
import { formatDate } from '../../utilities/dateTime';
import ScoreAvatar from '../candidateScore/ScoreAvatar';
import Button from '../core/Button';
import RichText from '../core/RichText';
import UserContext from '../user/Context';
import ApplicationConversation from './ApplicationConversation';
import CandidatesProfile from './CandidatesProfile';
import CandidateStatusForm from './forms/CandidateStatusForm';
import UnreadIndicator from './UnreadIndicator';
import ApplicationNotes from './view/ApplicationNotes';
import { getNylasAuthUrl, createSlug } from '../scheduler/Util';
import Scheduler from '../scheduler/Scheduler';
import ButtonLink from '../core/ButtonLink';
import schedulerApi from '../scheduler/Api';
import CandidatesAgencyProfile from './CandidatesAgencyProfile';

type Props = {
  work: WorkEntity;
  application: ApplicationEntity;
  patchApplications: Function;
  hire: (applicationId: string, sendEmail: boolean) => void;
};

interface IPageToken {
  editToken: string | null;
}

const NavButton = ({
  value,
  text,
  className,
  children,
}: {
  value: string;
  text: string;
  className?: string;
  children?: React.ReactNode;
}) => (
  <IonSegmentButton
    value={value}
    className={cn('min-w-0 w-full flex flex-col items-center', className)}
  >
    {children}
    <IonLabel className="w-full min-w-fit">{text}</IonLabel>
  </IonSegmentButton>
);

const ApplicationPreview = ({
  work,
  application,
  patchApplications,
  hire,
}: Props) => {
  const [meetingData, setMeetingData] = useState<IPageToken | any>(null);
  const { isPublic, nylasAccessTokenExists } = useContext(UserContext);
  const [segment, setSegment] = useState('application');
  const { user: fetchedUser } = useUser(application.user.id);
  const { user, chatChannelSid } = application;
  const { firstName, lastName, avatarURL } = user;
  const name = `${firstName} ${lastName}`;
  const applicationDate = formatDate(application.createdAt);
  const isAwarded = application.status === 'awarded';
  const hiredButtonText = isAwarded ? 'Unaward' : 'Award';

  // imports nylas scheduler script so it can be used right away
  useImportScript(
    'https://schedule.nylas.com/schedule-editor/v1.0/schedule-editor.js'
  );

  useEffect(() => {
    const fetchPage = async () => {
      const slug = createSlug(work.id, application.id);
      const resultOfFindPage = await schedulerApi.fetchSchedulePage({ slug });
      setMeetingData(resultOfFindPage);
    };
    fetchPage();
  }, [application.id, application.user.id, work.id]);

  const { aggregateRating } = useApplicationNotes({
    workId: work.id,
    applicationId: application.id,
  });

  const showChat = !isPublic;

  const planType = useMemo(() => {
    return application.user.primaryMembershipUser?.membership.plan.type;
  }, [application]);

  const membership = useMemo(() => {
    return application.user.primaryMembershipUser?.membership;
  }, [application]);

  const location = useMemo(() => {
    return planType === 'agency' && membership
      ? membership.location
      : user.location;
  }, [planType, membership, user]);

  const showProfile = () => {
    setSegment('profile');
  };

  const handleSchedule = async () => {
    const authUrl = await getNylasAuthUrl(work.id, application.id);
    window.location.href = authUrl;
  };

  const handleHireButton = () => {
    hire(application.id, isPublic);
  };

  return (
    <div className="h-full flex flex-col md:pb-0">
      <div className="bg-white border-b border-grey-100 fixed md:sticky top-auto md:top-0 bottom-0 md:bottom-auto left-0 md:left-auto w-full md:w-auto md:h-auto z-10 flex shadow-top md:shadow-none pb-safe">
        <IonSegment
          mode="md"
          value={segment}
          className="application-preview h-16 md:h-8 w-full"
          onIonChange={(e: any) => {
            setSegment(e.detail.value);
          }}
        >
          <NavButton
            value="application"
            text="Application"
            className="md:px-4"
          />
          <NavButton value="profile" text="Profile" />
          {showChat && (
            <>
              <NavButton value="chat" text="Chat">
                <UnreadIndicator
                  className="mt-1"
                  channelSids={[chatChannelSid]}
                />
              </NavButton>
            </>
          )}
          {SHOW_APPLICANT_NOTES && <NavButton value="notes" text="Notes" />}
          <NavButton value="status" text="Status" className="md:hidden" />
        </IonSegment>
      </div>

      <div className="h-full bg-white grow overflow-y-auto disable-scrollbars">
        <div className={`${segment === 'application' ? '' : 'hidden'}`}>
          <div className="p-4 pb-48 md:pb-4 flex flex-col gap-4">
            <div className="flex">
              <div
                className="mr-4 cursor-pointer"
                onClick={showProfile}
                role="none"
              >
                <ScoreAvatar
                  name={name}
                  imageUrl={avatarURL}
                  percentage={application.score}
                  size={20}
                />
              </div>
              <div className="truncate">
                <div>Applied {applicationDate}</div>
                <div
                  className="font-bold text-lg cursor-pointer"
                  onClick={showProfile}
                  role="none"
                >
                  {name}
                </div>
                {planType === 'agency' && membership && (
                  <div>{membership.name}</div>
                )}
                <div className="font-light text-sm truncate">
                  {location?.friendlyName || ''}
                </div>
              </div>
            </div>
            <Button
              fill="outline"
              type="button"
              onClick={handleHireButton}
              color="primary"
              className="w-full"
            >
              <span className="btn__text">{hiredButtonText}</span>
            </Button>
            {SHOW_SCHEDULER ? (
              <>
                {nylasAccessTokenExists ? (
                  <Scheduler
                    application={application}
                    work={work}
                    onSetMeetingData={data => setMeetingData(data)}
                    meetingData={meetingData}
                  />
                ) : (
                  <Button
                    type="button"
                    onClick={handleSchedule}
                    color="primary"
                    className="flex-1"
                  >
                    <i className="i-calendar btn__icon" />
                    <span className="btn__text">Schedule Interview</span>
                  </Button>
                )}
              </>
            ) : null}
            {application.body ? (
              <div className="flex flex-col gap-0">
                <div className="text-lg font-bold mb-4">
                  {firstName}{' '}
                  {planType === 'agency' &&
                    membership &&
                    `@ ${membership.name}`}{' '}
                  says:
                </div>
                <div className="-mt-4">
                  <RichText html={application.body} classVariant="ql-editor" />
                </div>
              </div>
            ) : null}
            <ButtonLink
              color="primary"
              size="sm"
              href={`/members/user/${user.id}`}
              text="View Full Profile"
              className="w-full"
            />
          </div>
        </div>
        <div className={`${segment === 'profile' ? '' : 'hidden'}`}>
          <ForceMobileContextProvider value={{ showFullProfile: !isPublic }}>
            <div className="flex flex-col gap-2 pb-28">
              {planType === 'agency' && membership ? (
                <CandidatesAgencyProfile
                  membership={membership}
                  application={application}
                  work={work}
                />
              ) : (
                <CandidatesProfile
                  user={fetchedUser}
                  application={application}
                  showRelations={false}
                  work={work}
                />
              )}
            </div>
          </ForceMobileContextProvider>
        </div>
        {showChat && (
          <div className={`${segment === 'chat' ? 'h-full' : 'hidden'}`}>
            <div className="flex flex-col py-6 items-center w-full gap-6 h-full">
              <ScoreAvatar
                name={name}
                imageUrl={user.avatarURL}
                percentage={application.score}
                size={20}
              />

              <div className="flex flex-col gap-4">
                <div className="flex flex-col gap-px items-center">
                  <h4>{name}</h4>
                  <p>{user.title}</p>
                  {user.location?.friendlyName ? (
                    <p>{user.location?.friendlyName}</p>
                  ) : null}
                </div>
              </div>
              <div className="border-t-2 border-grey-300 h-full">
                <ApplicationConversation
                  key={application.id}
                  workId={work.id}
                  application={application}
                  patchApplications={patchApplications}
                  isActive={segment === 'chat'}
                />
              </div>
            </div>
          </div>
        )}
        {SHOW_APPLICANT_NOTES && (
          <div className={`${segment === 'notes' ? '' : 'hidden'}`}>
            <div className="flex flex-col p-4 pb-48 md:pb-4 gap-4">
              <div className="flex">
                <div
                  className="mr-4 cursor-pointer"
                  onClick={showProfile}
                  role="none"
                >
                  <ScoreAvatar
                    name={name}
                    imageUrl={avatarURL}
                    percentage={application.score}
                    size={20}
                  />
                </div>
                <div className="flex flex-col">
                  <div className="flex items-center">
                    <ReactStars
                      count={5}
                      value={aggregateRating ? aggregateRating.average : 0}
                      edit={false}
                      size={20}
                      color2="#E8A318"
                    />
                    <div className="text-sm ml-1 text-gold-900 font-bold">
                      ({aggregateRating.count})
                    </div>
                  </div>
                  <div className="truncate">
                    <div
                      className="font-bold text-lg cursor-pointer"
                      onClick={showProfile}
                      role="none"
                    >
                      {name}
                    </div>
                    {planType === 'agency' && membership && (
                      <div>{membership.name}</div>
                    )}
                    <div className="font-light text-sm truncate">
                      {location?.friendlyName || ''}
                    </div>
                  </div>
                </div>
              </div>

              <ButtonLink
                color="primary"
                fill="outline"
                size="sm"
                href={`/members/user/${user.id}`}
                text="View Full Profile"
                className="w-full"
              />

              <hr />

              <ApplicationNotes work={work} application={application} />
            </div>
          </div>
        )}
        {segment === 'status' && (
          <CandidateStatusForm
            application={application}
            patchApplications={patchApplications}
            onComplete={() => setSegment('application')}
          />
        )}
      </div>
    </div>
  );
};

export default ApplicationPreview;
