import { orderBy } from 'lodash';
import { ENABLE_INVITE_TO_APPLY } from '../config';

const invitedStatusDisplayed = ENABLE_INVITE_TO_APPLY;
const awardedStatusDisplayed = !ENABLE_INVITE_TO_APPLY;

export const applicationStatuses = {
  invited: {
    label: 'Invited',
    icon: 'user',
    color: '#FFD580',
    display: invitedStatusDisplayed,
    internalOnly: true,
  },
  interested: {
    label: 'Applied',
    icon: 'user',
    color: '#A096E6',
    display: true,
    internalOnly: true,
  },
  passed: {
    label: 'Rejected',
    icon: 'users',
    color: '#727B88',
    display: true,
    internalOnly: false,
  },
  shortlisted: {
    label: 'Shortlisted',
    icon: 'users',
    color: '#45C4F2',
    display: true,
    internalOnly: false,
  },
  awarded: {
    label: 'Awarded',
    icon: 'alert-positive',
    color: '#00A54F',
    display: awardedStatusDisplayed,
    internalOnly: true,
  },
};

export function statusFromApi(
  status: ApplicationStatus | null
): ApplicationStatus {
  if (status === null) {
    return 'interested';
  }
  return status;
}

export function statusToApi(
  status: ApplicationStatus | null
): ApplicationStatus | null {
  if (status === 'interested') {
    return null;
  }
  return status;
}

export function statusLabel(
  status: ApplicationStatus | undefined | null
): string {
  const statusObj = applicationStatuses[status || 'interested'];
  return statusObj.label;
}

export function statusIcon(status: ApplicationStatus): string {
  const statusObj = applicationStatuses[status || 'interested'];
  return statusObj.icon;
}

export function statusColor(status: ApplicationStatus): string {
  const statusObj = applicationStatuses[status || 'interested'];
  return statusObj.color;
}

export function sortApplications(applications: ApplicationEntity[]) {
  return orderBy(
    Object.values(applications),
    ['position', 'score', 'updatedAt'],
    ['asc', 'desc', 'asc']
  );
}

export function groupApplicationsByStatus(applications: ApplicationEntity[]) {
  return Object.keys(applicationStatuses).reduce(
    (carry: any, status: any) => {
      const statusToMatch = statusToApi(status);

      // Place 'awarded' applications in the 'shortlisted' column so the 'awarded'
      // column can be removed from the UI.
      const applicationsForStatus: ApplicationEntity[] = applications.filter(
        application => {
          return application.status === statusToMatch;
        }
      );

      if (status === 'awarded' && ENABLE_INVITE_TO_APPLY) {
        status = 'shortlisted';
        return {
          ...carry,
          [status]: [...applicationsForStatus, ...carry[status]],
        };
      }

      return {
        ...carry,
        [status]: [...carry[status], ...applicationsForStatus],
      };
    },
    {
      invited: [],
      interested: [],
      passed: [],
      shortlisted: [],
      awarded: [],
    }
  );
}
