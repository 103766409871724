import React, { MemoExoticComponent } from 'react';
import Avatar from '../avatar/Avatar';
import UserCollectionIcon from '../collections/components/UserCollectionIcon';
import { Link } from '../core/router';
import EmployeeBadge from '../profile/EmployeeBadge';
import StudentBadge from '../profile/StudentBadge';
import VerifiedBadge from '../profile/VerifiedBadge';
import VettedBadge from '../profile/VettedBadge';

export type Badges = 'employee' | 'vetted' | 'verified' | 'student';

interface Props {
  userId: string;
  name: string;
  userRole: string;
  location?: string | null;
  badge?: Badges;
  avatarUrl?: string | null;
  onUserCollectionUpdate?: (collectionIdsUserIsIn: string[]) => void;
  worksAt?: string;
  logoUrl?: string;
}

type BadgeComponent =
  | MemoExoticComponent<() => JSX.Element>
  | (() => JSX.Element);

const badgeTable: Record<Badges, BadgeComponent> = {
  employee: EmployeeBadge,
  verified: VerifiedBadge,
  vetted: VettedBadge,
  student: StudentBadge,
};

const badgeLookup = (requestedBadge?: Badges): BadgeComponent | null => {
  if (requestedBadge == null) {
    return null;
  }

  return badgeTable[requestedBadge];
};

const UserCard = ({
  userId,
  name,
  userRole,
  location,
  badge,
  avatarUrl,
  onUserCollectionUpdate,
  worksAt,
  logoUrl,
}: Props) => {
  const BadgeComponent = badgeLookup(badge);

  return (
    <div className="relative">
      <Link
        to={`/members/user/${userId}`}
        className="block aspect-3/4 relative"
      >
        <div className="flex flex-col justify-between w-full h-full rounded-md">
          <div className="relative flex-auto">
            <div className="w-full h-full">
              <Avatar
                avatarUrl={avatarUrl}
                avatarName={name}
                size="full"
                circle={false}
              />
            </div>

            <div className="absolute inset-0 p-6 flex flex-col justify-end bg-bottom-gradient--half">
              <div className="text-white text-left">
                <h5 className="w-full whitespace-nowrap truncate">{name}</h5>
                <div className="text-base w-full whitespace-nowrap truncate">
                  {userRole}
                </div>
                {location ? (
                  <div className="text-small w-full whitespace-nowrap truncate">
                    {location}
                  </div>
                ) : null}

                {worksAt ? (
                  <div className="flex justify-between items-end w-full">
                    <div className="mt-2 flex-auto min-w-1">
                      <div className="text-sm w-full">Works at</div>
                      <div className="text-lg font-semibold whitespace-nowrap truncate mr-4">
                        {worksAt}
                      </div>
                    </div>
                    <div className="shrink-0 grow-0">
                      <Avatar
                        size="md"
                        avatarUrl={logoUrl}
                        avatarName={worksAt}
                        borderWidth={2}
                      />
                    </div>
                  </div>
                ) : null}
              </div>
            </div>
          </div>

          {BadgeComponent ? <BadgeComponent /> : null}
        </div>
      </Link>
      <UserCollectionIcon
        userId={userId}
        className="absolute top-0 right-0 mt-4 mr-4"
        size="large"
        onUpdate={onUserCollectionUpdate}
      />
    </div>
  );
};
export default React.memo(UserCard);
