/* eslint-disable no-console */
import React, { useRef, useCallback } from 'react';
import { Textarea } from '../../core/form';
import uuid from '../../../utilities/uuid';
import { APP_DOMAIN } from '../../../config';
import useChatUser from '../hooks/useChatUser';
import MessagingDisabled from './MessagingDisabled';
import useChatOtherMember from '../hooks/useChatOtherMember';
import VideoRecorder from '../../videoRecorder/VideoRecorder';

type Identity = string;

const ComposeDirectPresenter = React.memo(
  ({
    identity,
    onSendMessage,
    onKeyDown,
  }: {
    identity: Identity;
    onSendMessage: (message: string) => Promise<void>;
    onKeyDown?: () => void;
  }) => {
    const { user } = useChatUser(identity);

    if (!user) return null;

    const {
      attributes: { isDeactivated, isOblivious, isNotContactable },
    } = user;

    if (isDeactivated || (isNotContactable && !isOblivious))
      return <MessagingDisabled />;

    return <Compose onSendMessage={onSendMessage} onKeyDown={onKeyDown} />;
  }
);

const ComposeToOtherMember = React.memo(
  ({
    channelSid,
    onSendMessage,
    onKeyDown,
  }: {
    channelSid: string;
    onSendMessage: (message: string) => Promise<void>;
    onKeyDown?: () => void;
  }) => {
    const { member } = useChatOtherMember(channelSid);

    if (!member) return null;

    return (
      <ComposeDirectPresenter
        identity={member.identity}
        onSendMessage={onSendMessage}
        onKeyDown={onKeyDown}
      />
    );
  }
);

export const ComposeDirect = React.memo(
  ({
    to,
    onSendMessage,
    onKeyDown,
  }: {
    to: string;
    onSendMessage: (message: string) => Promise<void>;
    onKeyDown?: () => void;
  }) => {
    // All Twilio channels start with CH as part of their uuid
    if (to.startsWith('CH'))
      return (
        <ComposeToOtherMember
          channelSid={to}
          onSendMessage={onSendMessage}
          onKeyDown={onKeyDown}
        />
      );

    return (
      <ComposeDirectPresenter
        identity={to}
        onSendMessage={onSendMessage}
        onKeyDown={onKeyDown}
      />
    );
  }
);

type ComposeProps = {
  onSendMessage: (message: string) => Promise<void>;
  onKeyDown?: () => void;
  isDisabledTextArea?: boolean;
};

/**
 * Renders a component that is in charge of the text block where the user will enter their message to send
 *
 * @param param0.onSendMessage - the callback handler triggered when a message is sent
 * @param param0.onKeyDown - the callback handler triggered when a key is pressed within the component
 * @param param0.isDisabledTextArea - a boolean that dictates whether the textbox is disabled or not
 */
const Compose = ({
  onSendMessage,
  onKeyDown,
  isDisabledTextArea = false,
}: ComposeProps) => {
  const textAreaRef = useRef(null as any);
  const defaultPlaceholder = isDisabledTextArea
    ? 'This is a one way channel'
    : 'Send a message';

  const sendMessage = useCallback(async () => {
    if (textAreaRef.current === null || textAreaRef.current.value === '')
      return;

    let message = '';

    // Create a new video chat room link if the user types `/video`
    if (textAreaRef.current.value === '/video') {
      message = `${APP_DOMAIN}/video-chat/private-${uuid()}`;
    } else {
      message = encodeURIComponent(textAreaRef.current.value);
    }

    onSendMessage(message);

    textAreaRef.current.value = '';
  }, [onSendMessage]);

  const handleKeyDown = useCallback(
    ({ ctrlKey, metaKey, key }: { ctrlKey: any; metaKey: any; key: any }) => {
      if ((ctrlKey || metaKey) && key === 'Enter') {
        sendMessage();
        return;
      }

      if (key === 'Escape') {
        textAreaRef.current.blur();
        return;
      }

      if (onKeyDown) onKeyDown();
    },
    [sendMessage, onKeyDown]
  );

  return (
    <div className="chat-compose">
      <div className="flex-1">
        <Textarea
          disabled={isDisabledTextArea}
          ref={textAreaRef}
          name="message"
          placeholder={defaultPlaceholder}
          rows={1}
          autoGrow
          autoCapitalize="sentences"
          onKeyDown={handleKeyDown}
        />
      </div>
      <div className="flex items-center">
        <VideoRecorder
          onVideoChanged={(video: { url: string }) => {
            textAreaRef.current.value = `${video.url.substr(
              0,
              video.url.lastIndexOf('.')
            )}.mp4`;
            sendMessage();
          }}
        />
        <button type="button" className="chat-submit" onClick={sendMessage}>
          <i className="i-arrow-right" />
        </button>
      </div>
    </div>
  );
};

export default React.memo(Compose);
